import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderBrand,
	CContainer,
	CCard,
	CCardHeader,
	CSubheader,
	CCardBody,
	CFooter,
	CRow,
	CCol,
	CForm,
	CFormInput,
	CFormSelect,
	CFormCheck,
	CLink,
	CTooltip,
	CInputGroup,
	CInputGroupText,
	CButton,
	CListGroup,
	CListGroupItem,
	CBadge,
	CImage,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyButton,
	MyDialoglookup,
	MyDialogform,
	MyPage500,
	MyProfileDetil,
	MySessionextender,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson     	= require('../../../package.json');
const Belibarangmain= (props) => {
	const uComponentname	= Belibarangmain.name;
	const {
		prosesExpired,setLoading,showToast,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate 		= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted) || true;

	//--DIALOG--/
	const [uDialogHeader,setDialogHeader] 	= React.useState();
	const [uIsDlglookupshow,setDlglookupshow]= React.useState(false);
	const [uDatalookupArr,setDatalookupArr]	= React.useState([]);
	const [uDlglookupjenis,setDlglookupjenis]= React.useState("");
	const [uDlglookupinput,setDlglookupinput]	= React.useState("");
	//--END DIALOG--/

	const [uHTMLError500,setHTMLError500]	= React.useState(null);
	const [uDataformObj,setDataformObj]		= React.useState({})
	const [uFormitemObj,setFormitemObj]		= React.useState({})
	const [uIsSuppliernew,setSuppliernew]	= React.useState(false);
	const uDataitemArr						= (uDataformObj.items_arr || [])
	
	//--MASTERDATA--/
	const [uDatabarangArr,setDatabarangArr]	= React.useState([]);
	const [uKasGLArr,setKasGLArr]			= React.useState(JSON.parse(localStorage.getItem("kasgllist")||"[]"))
	const [uDatasupplierArr,setDatasupplierArr]= React.useState([]);
	const [uBarangjenisArr,setBarangjenisArr]= React.useState([]);
	//--END MASTERDATA--/

	//--TIDAK_DIPAKAI_LAGI--/
	//--END TIDAK_DIPAKAI_LAGI--/

	const [uIsDataUpdate,setDataUpdate]		= React.useState(false);

	//--FORM_ITEM--/
	const [uInpItemEditQty,setInpItemEditQty]		= React.useState(0);
	const [uInpItemEditBatch,setInpItemEditBatch]	= React.useState("");
	const [uInpItemEditExpdate,setInpItemEditExpdate]= React.useState(null);
	const [uInpItemEditHarga,setInpItemEditHarga]	= React.useState(0);

	const [uInitEditObj,setInitEditObj]		= React.useState({editid:"0",editfield:""});
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	//--END FORM_ITEM--/

	//---FORM_DIALOG--/
	const [uISDlgformShow,setDlgformShow]	= React.useState(false);
	const [uDlgformjenis,setDlgformjenis]		= React.useState("");
	const [uDlgforminputObj,setDlgforminputObj] = React.useState({});
	//---END FORM_DIALOG--/

	const [uIsFormupdate,setFormupdate]		= React.useState(false);
	const [uIsFormprocess,setIsFormprocess]	= React.useState(false);

	/*const uElCardnota			= document.getElementById("idcardnota");
	const uElCardsupplier		= document.getElementById("idcardsupplier");
	const uElCardpotongan		= document.getElementById("idcardpotongan");
	const uElCardbayar			= document.getElementById("idcardbayar");//-*/

	//---HANDEL--/
	const hdlToggleDlglookup=()=>{ setDlglookupshow(false); }
	const hdlToggleDlgform=()=>{ setDlgformShow(false); }
	const hdlKlikDlglookuppilih=(_IDITEM)=>{
		//const vCursor = UFunc.getObjectIndeks(uMasterSearchArr,_IDITEM,"id");
		//setCursorPos(vCursor);

		const vIdx = UFunc.getObjectIndeks(uDatalookupArr,_IDITEM,"id");
		if(vIdx < 0) return;

		let vElsfocusnext;
		if(uDlglookupjenis==="ITEM") {
			vElsfocusnext = document.getElementById("inpitemqty");

			uFormitemObj.item_id	= (uDatalookupArr[vIdx].id);
			uFormitemObj.item_nama	= (uDatalookupArr[vIdx].caption);
			uFormitemObj.satuan 	= (uDatalookupArr[vIdx].satuan);
			uFormitemObj.harga_satuan	= (uDatalookupArr[vIdx].harga_satuan || 0);

		} else if(uDlglookupjenis==="KAS") {
			vElsfocusnext = document.getElementById("inpnilaibayar");
			uDataformObj.id_gl_kas = (uDatalookupArr[vIdx].id)
			uDataformObj.kas_caption = (uDatalookupArr[vIdx].caption)
		} else if(uDlglookupjenis==="SUPPLIER") {
			vElsfocusnext = document.getElementById("inpitemcaption");
			uDataformObj.id_supplier	= (uDatalookupArr[vIdx].id)
			uDataformObj.nama_supplier	= (uDatalookupArr[vIdx].caption)
		} else if(uDlglookupjenis === "DLGFORMITEM") {
			vElsfocusnext = document.getElementById("inpformbatchno");
			uDlgforminputObj.barang_id		= (uDatalookupArr[vIdx].id);
			uDlgforminputObj.barang_caption	= (uDatalookupArr[vIdx].caption);
			uDlgforminputObj.barang_satuan 	= (uDatalookupArr[vIdx].satuan);
			uDlgforminputObj.harga_satuan	= (uDatalookupArr[vIdx].harga_satuan || 0);
			uDlgforminputObj.qty 			= 1;
		}

		hdlToggleDlglookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);

			if(!isMobile) vElsfocusnext && vElsfocusnext.focus()
		},350);
	}
	const hdlKlikDlgformsimpan = () =>{
		if(uDlgformjenis==="BARANG") {
			if((uDlgforminputObj.barang_nama||"").trim()==="" 
				|| (uDlgforminputObj.jenis_id||"0").toString()==="0"
				) { 
				return; 
			}

			initDlgformregister();
			return;
		} 
		if(uDlgformjenis==="DLGFORMITEM") {
			if((uDlgforminputObj.barang_caption||"").trim()===""
				|| parseInt(uDlgforminputObj.barang_caption||"0") <= 0
				|| parseFloat(uDlgforminputObj.qty||"0") <= 0
				|| parseFloat(uDlgforminputObj.harga_satuan||"0") < 0
				) return;

			initDlgformitemmobile();
			return;
		}
	}
	const hdlKlikBack=async()=>{
		const vProsesBack=()=>{ uNavigate(-1); }

		if(uIsFormupdate) {
			if(await(Konfirm(
				"<strong>"+(uBahasaObj.pesan_formisupdate||"pesan_formisupdate")+"..</strong>"+
				"<BR><BR>"+(uBahasaObj.confirm_kembali||"confirm_kembali")+
				""))) vProsesBack();

			return;
		}
		vProsesBack();
	}
	const hdlKlikSimpanMain = async() => {
		if(!uIsFormupdate) return;

		//---VALIDASI--/
		let vElsfocus = document.getElementById("inptanggal");
		if((uDataformObj.tgl_beli||"") === "") {
			vElsfocus && vElsfocus.focus();
			showToast((uBahasaObj.word_tanggal||"word_tanggal")+" "+uBahasaObj.caption_invalid+"..");
			return;
		}
		const vTanggalDt 	= new Date(uDataformObj.tgl_beli);

		const vDateBeyond = UFunc.DateIsBeyond(vTanggalDt);
		if(vDateBeyond) {
			vElsfocus && vElsfocus.focus();
			showToast((uBahasaObj.pesan_tglisbeyond||"pesan_tglisbeyond")+"..");
			return;
		}
		const vDateExceededBl = UFunc.DateIsExceededYear(vTanggalDt);
		if(vDateExceededBl) {
			vElsfocus && vElsfocus.focus();
			showToast((uBahasaObj.pesan_tglisexpiredyear||"pesan_tglisexpiredyear")+"..");
			return;
		}

		if(!uIsSuppliernew) {
			vElsfocus = document.getElementById("inpsuppliercaption");
			if(parseInt(uDataformObj.id_supplier||0) <= 0) {
				vElsfocus && vElsfocus.focus();
				showToast((uBahasaObj.word_supplier||"word_supplier")+
					" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
				return;
			}
		} else {
			vElsfocus = document.getElementById("inpsupplierbaru");
			if((uDataformObj.nama_supplier||"").trim() === "") {
				vElsfocus && vElsfocus.focus();
				showToast((uBahasaObj.caption_supplierbaru||"Supplier Baru")+
					" "+(uBahasaObj.caption_mustfilled||"caption_mustfilled")+"..");
				return;
			}
		}
		//alert("uDataformObj.id_supplier = "+uDataformObj.id_supplier); return

		vElsfocus = document.getElementById("inpkascaption");
		if(parseInt(uDataformObj.id_gl_kas||0) === 0) {
			vElsfocus && vElsfocus.focus();
			showToast((uBahasaObj.caption_asalkas||"caption_asalkas")+
				" "+(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		vElsfocus = document.getElementById("inpitemcaption");
		if(uDataitemArr.length <= 0){
			vElsfocus && vElsfocus.focus();
			showToast((uBahasaObj.pesan_itemstillempty||"pesan_itemstillempty"));
			return;
		}
		//---CEK_BATCHNO--/
		let vIsBatchNoInvalid	= false;
		let vIsExpDateInvalid	= false;
		uDataitemArr.forEach((vItem,vKey)=>{
			//console.log("vItem.is_batchno = "+vItem.is_batchno);
			if(vItem.is_batchno==="YA" && (vItem.batchno||"").trim() === "") {
				vIsBatchNoInvalid = true;
				return false;
			}

			if(vItem.expdate) {
				const vExpdateDt 		= new Date(vItem.expdate);
				const vIsExpDateBeyond 	= UFunc.DateIsBeyond(vExpdateDt);
				if(vIsExpDateBeyond) {
					if((vItem.batchno||"") == "") {
						vIsExpDateInvalid = true;
						return false;
					}
				}
			}
		})
		if(vIsBatchNoInvalid){
			showToast((uBahasaObj.pesan_itembatcnoinvalid||"pesan_itembatcnoinvalid"));
			return;
		}
		if(vIsExpDateInvalid) {
			showToast((uBahasaObj.pesan_itemexpdateinvalid||"pesan_itemexpdateinvalid"));
			return;
		}
		//---END CEK_BATCHNO--/
		//---END VALIDASI--/

		const vDateisMundur = UFunc.DateIsMundur(vTanggalDt);
		const vSuppliernama	= uIsSuppliernew ? uDataformObj.nama_supplier : uDataformObj.nama_supplier;
		const vKasAsal		= uDataformObj.kas_caption;

		const vFuncnext	= ()=>{
			/*if(uIsSuppliernew) {
				apiSimpanregister("SUPPLIER"); 

				return;
			} //-*/ 
			apiSimpanmain()
		}

		const vHTMLKonfirm	=
		"<div class='row'>"+
		"<div class='col-sm-12 text-center'><strong>"+(uBahasaObj["menus_belibarang"]||"").toUpperCase()+"</strong></div>"+
		"<div class='col-sm-12'><HR class='my-2' style='border:none;border-bottom:1px dotted gray;'/></div>"+
		(uDataformObj.no_nota!==""?
		"<div class='col-sm-5'>"+(uBahasaObj.word_nonota||"word_nonota")+"</div>"+
		"<div class='col-sm-7 fw-bolder' align='right'>"+(uDataformObj.no_nota||"").toUpperCase()+"</div>"+
		""
		:""
		)+
		"<div class='col-sm-5 '>"+(uBahasaObj.caption_tgltransaksi||"caption_tgltransaksi")+"</div>"+
		"<div class='col-sm-7  fw-bolder' align='right'>"+UFunc.TglAngka(uDataformObj.tgl_beli)+"</div>"+
		"<div class='col-sm-3'>"+(uBahasaObj.word_supplier||"word_supplier")+"</div>"+
		"<div class='col-sm-9 fw-bolder' align='right'>"+vSuppliernama+"</div>"+
		"<div class='col-sm-5'>"+(uBahasaObj.caption_asalkas||"caption_asalkas")+"</div>"+
		"<div class='col-sm-7 fw-bolder' align='right'>"+vKasAsal+"</div>"+

		"<div class='col-sm-12'><HR class='my-2' style='border:none;border-bottom:1px dotted gray;'/></div>"+
		"<div class='col-sm-5'>"+(uBahasaObj.caption_jmlitem||"caption_jmlitem")+"</div>"+
		"<div class='col-sm-7 fw-bolder text-end'>"+
			UFunc.formatAngka(uDataitemArr.length)+" "+(uBahasaObj.word_item||"word_item")+"</div>"+
		"<div class='col-sm-5'>"+(uBahasaObj.caption_totalnetto||"caption_totalnetto")+"</div>"+
		"<div class='col-sm-7 fw-bolder text-end'>Rp"+UFunc.formatAngka(uDataformObj.nilai_netto)+"</div>"+

		"<div class='col-sm-12'><HR class='my-2' style='border:none;border-bottom:1px dotted gray;'/></div>"+
		"<div class='col-sm-5'>"+(uBahasaObj.caption_nilaibayar||"caption_nilaibayar")+"</div>"+
		"<div class='col-sm-7 fw-bolder text-primary text-end'>Rp"+UFunc.formatAngka(uDataformObj.nilai_bayar)+"</div>"+
		"<div class='col-sm-5'>"+(uBahasaObj.caption_sisahutang||"caption_sisahutang")+"</div>"+
		"<div class='col-sm-7 fw-bolder text-end"+
			((parseInt(uDataformObj.nilai_hutang)||0)>0?" text-danger":"")+"'>Rp"+UFunc.formatAngka(uDataformObj.nilai_hutang)+"</div>"+

		(vDateisMundur?
		"<div class='col-sm-12'><HR class='my-2' style='border:none;border-bottom:1px dotted gray;'/></div>"+
		"<div class='col-sm-12 px-5 fst-italic text-warning'>"+
			(uBahasaObj.pesan_dateismundur||"pesan_dateismundur")+"</div>"+
		""
		:""
		)+
		"<div class='col-sm-12 p-3 px-4 fw-bolder text-center text-success'>"+(uBahasaObj.confirm_simpan||"confirm_simpan")+"</div>"+
		"</div>"+
		"";

		//alert("uDataformObj.id_gl_kas = "+uDataformObj.id_gl_kas); return;

		if(await(Konfirm(vHTMLKonfirm))) vFuncnext();
	}
	const hdlKDownDocument=(_EV)=>{
		if(_EV.which === 120) {
			if(uIsFormprocess) return;

			_EV.preventDefault();
			hdlKlikSimpanMain();
		}
	}
	const hdlKlikReset = async() => {
		if(!uIsFormupdate) return;

		const vKonfirm	=
			"<strong>"+(uBahasaObj.petunjuk_resetform||"petunjuk_resetform")+"</strong>.."+
			"<BR><BR>"+(uBahasaObj.confirm_reset||"confirm_reset")+
		"";
		if(await Konfirm(vKonfirm)) initResetForm();
	}
	const hdlKlikItemLookUp=()=>{
		setDlglookupinput(uFormitemObj.item_nama||"");
		uFormitemObj.item_nama	= "";
		initResetFormItem();

		setDlglookupjenis("ITEM");
		setDialogHeader(uBahasaObj.caption_lookupitem||"caption_lookupitem");
		setDatalookupArr(uDatabarangArr);
		setDlglookupshow(true);
	}
	const hdlKlikKaslookup = () => {
		setDlglookupjenis("KAS");
		const vTmpArr	= uKasGLArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setDatalookupArr(vTmpArr);
		setDialogHeader(uBahasaObj.word_pilih+": "+(uBahasaObj.caption_akunkas||"").toUpperCase());
		setDlglookupshow(true)
	}
	const hdlKeyDownSuppliercaption = (_EV) => {
		uDataformObj.id_supplier = "0"
		if(_EV.keyCode !== 13) return;
		if((uDataformObj.nama_supplier||"").trim()==="") {
			return;
		}

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uDatasupplierArr.filter((vItems)=>
			vItems.caption.toUpperCase().includes((uDataformObj.nama_supplier||"").toUpperCase()));
		
		if (vArrCariMaster.length === 1) {
			uDataformObj.id_supplier = (vArrCariMaster[0].id);
			uDataformObj.nama_supplier = (vArrCariMaster[0].caption);

			document.getElementById("inpitemcaption").focus();
		} else {
			hdlKlikSupplierlookup();
		}
	}
	const hdlKlikSupplierlookup = () => {
		setDlglookupinput(uDataformObj.nama_supplier||"");
		uDataformObj.nama_supplier	= "";

		setDlglookupjenis("SUPPLIER");
		const vTmpArr	= uDatasupplierArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		setDatalookupArr(vTmpArr);
		setDialogHeader(uBahasaObj.word_pilih+": "+(uBahasaObj.word_supplier||"").toUpperCase());
		setDlglookupshow(true)
	}
	const hdlKlikBarangRegister = () => {
		setDlgformjenis("BARANG");
		uDlgforminputObj.header = ((uBahasaObj.caption_tambahmaster||"caption_tambahmaster")+
			": "+(uBahasaObj.word_barang||"word_barang").toUpperCase());
		uDlgforminputObj.jenis_id  = (uBarangjenisArr[0].value);

		uFormitemObj.item_nama = ""
		initResetFormItem();

		setDlgformShow(true);
	}
	const hdlKlikItemtambahmobile = () => {
		setDlgformjenis("DLGFORMITEM");
		uDlgforminputObj.header = (uBahasaObj.caption_tambahdata||"caption_tambahdata")+
			" Item Pembelian";

		setDlgformShow(true);
	}
	const hdlKDownDlgformbarang = (_EV) => {
		uDlgforminputObj.barang_id = 0;
		uDlgforminputObj.barang_satuan = "";
		uDlgforminputObj.harga_satuan = 0;
		//console.log("(Belibarangmain - hdlKDownDlgformbarang) _EV.which => "+_EV.which);

		if(_EV.which !== 13) return;
		if((uDlgforminputObj.barang_caption||"").trim()==="") {
			return;
		}

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uDatabarangArr.filter((vItems)=>
			(vItems.caption||"").toUpperCase().
				includes((uDlgforminputObj.barang_caption||"").toUpperCase()));
		
		if (vArrCariMaster.length === 1) {
			uDlgforminputObj.barang_id = (vArrCariMaster[0].id);
			uDlgforminputObj.barang_caption = vArrCariMaster[0].caption;
			uDlgforminputObj.barang_satuan 	= vArrCariMaster[0].satuan;
			uDlgforminputObj.harga_satuan 	= vArrCariMaster[0].harga_satuan || 0;

			document.getElementById("inpformbatchno") &&
				document.getElementById("inpformbatchno").focus();
			return;
		}

		hdlKlikDlgformbaranglookup();
	}
	const hdlKlikDlgformbaranglookup = () => {
		setDlglookupinput(uDlgforminputObj.barang_caption||"");

		setDlglookupjenis("DLGFORMITEM");
		setDialogHeader(uBahasaObj.caption_lookupitem||"caption_lookupitem");
		setDatalookupArr(uDatabarangArr);
		setDlglookupshow(true);
	}
	const hdlKeyDownItemBarang = (_EV) => {
		if(_EV.keyCode !== 13) return;
		if((uFormitemObj.item_nama||"").trim()==="") return;

		_EV.preventDefault();
		_EV.stopPropagation();

		const vArrCariMaster 	= uDatalookupArr.filter((vItem)=>
			vItem.caption.toUpperCase().includes((uFormitemObj.item_nama||"").toUpperCase()));
		
		if (vArrCariMaster.length === 1) {
			uFormitemObj.item_id	= (vArrCariMaster[0].id);
			uFormitemObj.item_nama	= (vArrCariMaster[0].caption);
			uFormitemObj.satuan 	= (vArrCariMaster[0].satuan);
			uFormitemObj.harga_satuan	= (vArrCariMaster[0].harga_satuan || 0);

			document.getElementById("inpitemqty").focus();
		} else {
			hdlKlikItemLookUp();
		}
	}
	const hdlKlikItemTambah = () => {
		const vItemID	= parseInt(uFormitemObj.item_id)||0; if(vItemID <= 0) return;
		const vQty		= parseInt(uFormitemObj.qty)||0; if(vQty <= 0) return;

		//---CEK_ITEM_EXISTS--/
		let vIdx = UFunc.getObjectIndeks(uDataitemArr,vItemID,"itemid");
		if(vIdx >= 0) {
			showToast(uBahasaObj.pesan_itemisexists||"pesan_itemisexists");
			return;
		}
		//---END CEK_ITEM_EXISTS--/

		//---GET_ID_MASTER--/
		//console.log("(Belibarangmain - hdlKlikItemTambah) uDatabarangArr => "+JSON.stringify(uDatabarangArr))
		let vStatusBatch;
		vIdx = UFunc.getObjectIndeks(uDatabarangArr,vItemID,"id");
		if(vIdx>=0) {
			vStatusBatch = uDatabarangArr[vIdx].is_batchno;
		}
		//---END GET_ID_MASTER--/

		const vItemObj	= {
			itemid 	: vItemID||"0",
			nama 	: uFormitemObj.item_nama||"",
			is_batchno 	: vStatusBatch||"TIDAK",
			expdate:null,
			batchno:null,
			qty 	: vQty,
			hsatuan : parseInt(uFormitemObj.harga_satuan)||0
		}
		//vItemArr 	= [vItemObj,...vItemArr];
		uDataformObj.items_arr 	= [vItemObj,...uDataitemArr];

		uFormitemObj.item_nama 	= "";
		uFormitemObj.item_nama = ("");
		initResetFormItem();
		document.getElementById("inpitemcaption").focus();
	}
	const hdlKlikTabelhapus = async(_KEYITEM) => {
		_KEYITEM = parseInt(_KEYITEM); if(_KEYITEM < 0) return;

		const vObjdx	= _KEYITEM;//UFunc.getObjectIndeks(uDataitemArr,_IDITEM,"itemid");
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_KEYITEM);
		setTabelRowSelect(vRowIdx);

		const vConfirm	= 
			(uBahasaObj.caption_hapusitem||"caption_hapusitem")+": "+
			"<b>"+(uDataitemArr[vObjdx].nama||"").toUpperCase()+"</b>"+
			"<br/><br/><b>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+"</b>"+
		"";

		if(await Konfirm(vConfirm)) initTabelhapus(_KEYITEM);
	}
	const hdlKlikTabeledit = (_KEYITEM,_FIELDEDIT) => {
		initResetFormItem();
		setInitEditObj({editkey:_KEYITEM,editfield:_FIELDEDIT});

		const vIdx 		= _KEYITEM;//UFunc.getObjectIndeks(uDataitemArr,_IDITEM,"itemid");
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_KEYITEM);
		setTabelRowSelect(vRowIdx);

		let vElementID = "inptabelbatchno";
		if(_FIELDEDIT.toUpperCase()==="EXPDATE") {
			vElementID = "inptabelexpdate";
			if(vIdx >= 0)
				setInpItemEditExpdate(uDataitemArr[vIdx].expdate);
		} else if(_FIELDEDIT.toUpperCase()==="QTY") {
			vElementID = "inptabelqty";
			if(vIdx >= 0)
				setInpItemEditQty(uDataitemArr[vIdx].qty);
		} else if(_FIELDEDIT.toUpperCase()==="HARGA") {
			vElementID = "inptabelhsatuan";
			if(vIdx >= 0)
				setInpItemEditHarga(uDataitemArr[vIdx].hsatuan);
		} else {
			if(vIdx >= 0)
				setInpItemEditBatch(uDataitemArr[vIdx].batchno);
		}
		setEfekview(!uEfekview);

		let vTimeout = setTimeout(() => {
			clearTimeout(vTimeout);

			document.getElementById(vElementID) && 
				document.getElementById(vElementID).focus();
		},350);
	}
	const hdlKlikTabeleditmobile = (_KEYITEM) => {
		_KEYITEM = parseInt(_KEYITEM); if(_KEYITEM < 0) return;
		
		setDlgformjenis("DLGFORMITEM");

		const vObjdx	= _KEYITEM;//UFunc.getObjectIndeks(uDataitemArr,_IDITEM,"itemid");
		uDlgforminputObj.editkey 	= vObjdx;
		uDlgforminputObj.header 	= "Edit: "+(uDataitemArr[vObjdx].nama||"").toUpperCase();
		uDlgforminputObj.barang_caption = uDataitemArr[vObjdx].nama || "";
		uDlgforminputObj.barang_id 	= uDataitemArr[vObjdx].itemid || 0;
		//uDlgforminputObj.is_batchno = uDataitemArr[vObjdx].is_batchno || "TIDAK";
		uDlgforminputObj.batchno 	= uDataitemArr[vObjdx].batchno || "";
		uDlgforminputObj.expdate 	= uDataitemArr[vObjdx].expdate || "";
		uDlgforminputObj.qty 		= uDataitemArr[vObjdx].qty || 0;
		uDlgforminputObj.harga_satuan = uDataitemArr[vObjdx].hsatuan || 0;

		setDlgformShow(true);
	}
	const hdlKlikTabelproses = (_KEYITEM,_FIELDEDIT) => {
		//--PROSES_EDITNYA--/
		const vIdx 		= _KEYITEM;//UFunc.getObjectIndeks(uDataitemArr,_IDITEM,"itemid");
		if(vIdx < 0) 
			{ initTabelreset(); return; }

		if(_FIELDEDIT.toUpperCase()==="EXPDATE") {
			uDataitemArr[vIdx].expdate 	= uInpItemEditExpdate || null;
		} else if(_FIELDEDIT.toUpperCase()==="QTY") {
			const vValueQTY = parseInt(uInpItemEditQty) || 0;
			if(vValueQTY <= 0) {
				showToast((uBahasaObj.word_qty||"word_qty")+" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
				return;
			}

			uDataitemArr[vIdx].qty 	= vValueQTY;
		} else if(_FIELDEDIT.toUpperCase()==="HARGA") {
			const vValueHarga = parseInt(uInpItemEditHarga) || 0;
			if(vValueHarga < 0) {
				showToast((uBahasaObj.caption_hargasatuan)+" "+(uBahasaObj.caption_invalid||"caption_invalid")+"..");
				return;
			}

			uDataitemArr[vIdx].hsatuan 	= vValueHarga;
		} else {
			const vValueHarga = uInpItemEditBatch || null;
			uDataitemArr[vIdx].batchno 	= vValueHarga ? vValueHarga.toUpperCase() : "";
		}
		//--END PROSES_EDITNYA--/
		initTabelreset();
	}
	const hdlKDownTabeledit=(_EV,_IDITEM,_FIELDEDIT)=>{
		if(_EV.which !== 27 && _EV.which !== 13) return;

		_EV.preventDefault();
		if(_EV.which === 27) initTabelreset();
		else if(_EV.which === 13) hdlKlikTabelproses(_IDITEM,_FIELDEDIT);
	}
	//---END HANDEL--/

	//--INIT--/
	const initResetForm=()=>{
		if(uHTMLError500) {
			apiLoadinit();
			return;
		}

		setDataformObj({});
		setFormitemObj({});
		//setInitEditObj({editid:"0",editfield:""});
		setInitEditObj({});
		setTabelRowSelect(-1);
		setSuppliernew(false);

		uDataformObj.tgl_beli = UFunc.DbDate();
		setEfekview(!uEfekview);
	}
	const initTabelhapus = (_KEYITEM) => {
		_KEYITEM = parseInt(_KEYITEM); if(_KEYITEM < 0) return;

		const vObjdx	= _KEYITEM;//UFunc.getObjectIndeks(uDataitemArr,_IDITEM,"itemid");
		if(vObjdx >= 0) uDataitemArr.splice(vObjdx,1);

		if(uDataitemArr.length <= 0) 
			uDataformObj.items_arr 	= []
		else uDataformObj.items_arr = uDataitemArr;

		setTabelRowSelect(-1);
	}
	const initTabelreset = () => {
		//setInitEditObj({editid:"0",editfield:""});
		setInitEditObj({});
		setInpItemEditHarga(0);
		setInpItemEditExpdate(null);
		setInpItemEditBatch("");
		setInpItemEditQty(0);
	}
	const initResetFormItem=()=>{
		setTabelRowSelect(-1);

		uFormitemObj.item_id	= 0;
		uFormitemObj.qty		= 0;
		uFormitemObj.harga_satuan= 0;
		uFormitemObj.satuan		= "";

		setEfekview(!uEfekview)
	}
	const setInitvalue = (_NAMAFIELD,_VALUE) => {
		uDataformObj[_NAMAFIELD] = _VALUE;
		setEfekview(!uEfekview);
	}
	const setInititem = (_NAMAFIELD,_VALUE) => {
		uFormitemObj[_NAMAFIELD] = _VALUE;
		setEfekview(!uEfekview);
	}
	const initDlgformregister = () => {
		const vBarangcaption	=
		(uDlgforminputObj.barang_nama||"").trim()+
		((uDlgforminputObj.barang_kode||"").trim()!==""
			?" - "+(uDlgforminputObj.barang_kode||"").trim().toUpperCase()
			:"")+
		"";

		const vItemObj	= {
			itemid : 0,
			nama : vBarangcaption,
			nama_tabel: (uDlgforminputObj.barang_nama||"").trim(),
			kode: uDlgforminputObj.barang_kode,
			jenis_id: uDlgforminputObj.jenis_id,
			is_batchno : "TIDAK",
			batchno : "",
			expdate : "",
			qty : 1,
			hsatuan : 0
		}
		uDataformObj.items_arr 	= [vItemObj,...uDataitemArr];
		hdlToggleDlgform();
	}
	const initDlgformitemmobile = () => {
		const vItemID	= parseInt(uDlgforminputObj.barang_id)||0; if(vItemID < 0) return;
		const vQty		= parseInt(uDlgforminputObj.qty)||0; if(vQty <= 0) return;

		//---CEK_ITEM_EXISTS--/
		const vEditKeys	= parseInt(uDlgforminputObj.editkey);
		const vIdxitem 	= UFunc.getObjectIndeks(uDataitemArr,vItemID,"itemid");
		if(vIdxitem >= 0 && vIdxitem !== vEditKeys) {
			showToast(uBahasaObj.pesan_itemisexists||"pesan_itemisexists");
			return;
		}
		//---END CEK_ITEM_EXISTS--/

		//---GET_ID_MASTER--/
		//console.log("(Belibarangmain - hdlKlikItemTambah) uDatabarangArr => "+JSON.stringify(uDatabarangArr))
		const vIdxbarang 	= UFunc.getObjectIndeks(uDatabarangArr,vItemID,"id");
		const vStatusBatch 	= vIdxbarang >= 0 ? uDatabarangArr[vIdxbarang].is_batchno : "TIDAK";
		//---END GET_ID_MASTER--/

		if(vStatusBatch === "YA") {
			if((uDlgforminputObj.batchno||"").trim() === "") {
				showToast((uBahasaObj.pesan_itembatcnoinvalid||"pesan_itembatcnoinvalid"));
				return;
			}
			if((uDlgforminputObj.expdate||"").trim() === "") {
				showToast((uBahasaObj.pesan_itemexpdateinvalid||"pesan_itemexpdateinvalid"));
				return;
			}
		}

		const vItemObj	= {
			itemid : vItemID||"0",
			nama : uDlgforminputObj.barang_caption||"",
			is_batchno : vStatusBatch,
			batchno : uDlgforminputObj.batchno||"",
			expdate : uDlgforminputObj.expdate||"",
			qty : vQty,
			hsatuan : parseFloat(uDlgforminputObj.harga_satuan)||0
		}
		//vItemArr 	= [vItemObj,...vItemArr];
		if(vEditKeys >= 0) {
			(uDataformObj.items_arr||[])[vEditKeys] = vItemObj;
			hdlToggleDlgform();
			return;
		}

		uDataformObj.items_arr 	= [vItemObj,...uDataitemArr];

		const vHeaderstmp = uDlgforminputObj.header||"";
		setDlgforminputObj({header: vHeaderstmp}); 
		setEfekview(!uEfekview);
		/*uDlgforminputObj.header = vHeaderstmp;
		document.getElementById("inpformbarangnama") && 
			document.getElementById("inpformbarangnama").focus();//-*/

		showToast(uBahasaObj.pesan_sukses,"SUKSES");
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>

		return (
			<>
			<CRow className="align-item-stretch">
			<CCol md="6" className="mb-3">
				<CCard className="classcardbg h-100">
				<CCardHeader className="py-1">
					<small>Nota</small>
				</CCardHeader>
				<CCardBody className="">{contentNota()}</CCardBody>
				</CCard>
			</CCol>
			<CCol md="6" className="mb-3">
				<CCard className="classcardbg h-100">
				<CCardHeader className="py-1">
					<small>Supplier</small>
				</CCardHeader>
				<CCardBody className="">{contentSupplier()}</CCardBody>
				</CCard>
			</CCol>
			</CRow>
			
			<CCard className="classcardbg mb-3" id="idcardtabel">
				{contentTabel()}
			</CCard>

			<CRow className="align-item-stretch">
			<CCol md="6" className="mb-3">
			<CCard className="classcardbg h-100" id="idcardpotongan">
				<CCardBody className="">{contentPotongan()}</CCardBody>
			</CCard>
			</CCol>

			<CCol md="6" className="mb-3">
			<CCard className="classcardbg h-100" id="idcardbayar">
				<CCardBody className="py-1 px-3">{contentBayar()}</CCardBody>
			</CCard>
			</CCol>
			</CRow>
			</>
		)//>
	}
	const contentNota = () => {
		if(uHTMLError500) return "";

		return (
		<small>
		<CRow className="my-2">
			<CCol className=" text-info">{uBahasaObj.caption_tgltransaksi||"caption_tgltransaksi"}</CCol>
			<CCol xs="7">
				<CFormInput size="sm"
					type="date"
					value={uDataformObj.tgl_beli||""}
					onChange={(e)=>setInitvalue("tgl_beli",e.target.value)}
				id="inptanggal"/>
			</CCol>
		</CRow>
		<CRow className="my-2">
			<CCol className="">{uBahasaObj.word_nonota||"word_nonota"}</CCol>
			<CCol xs="7">					
				<CFormInput size="sm"
					maxLength={15}
					className="text-uppercase"
					value={uDataformObj.no_nota||""}
					onChange={(e)=>setInitvalue("no_nota",e.target.value)}
				id="inpnonota"/>
			</CCol>
		</CRow>
		</small>
		)//>
	}
	const contentSupplier = () => {
		if(uHTMLError500) return "";

		return (
		<small>
		<CRow className="my-2">
		<CCol md="3" className="">
			{(!uIsSuppliernew) ? (
			<span className="text-info">{uBahasaObj.word_supplier||"word_supplier"}</span>
			) : (
			<span className="text-danger">{uBahasaObj.word_supplier||"word_supplier"} {uBahasaObj.word_baru||"word_baru"}</span>
			)}
		</CCol>

		<CCol>
			{(!uIsSuppliernew) ? (
			<>
				<CInputGroup size="sm">
					<CFormInput size="sm"
						placeholder="--Masukkan Keyword SUPPLIER.."
						value={uDataformObj.nama_supplier||""}
						onChange={(e)=>setInitvalue("nama_supplier",e.target.value)}
						onKeyDown={(e)=>hdlKeyDownSuppliercaption(e)}
						id="inpsuppliercaption"/>
					<CButton size="sm" color="dark"
						onClick={()=>hdlKlikSupplierlookup()}
						id="btnsupplierlookup">
					<CIcon icon="cilMagnifyingGlass"/>
					</CButton>
					<CTooltip content="Toggle Supplier Baru">
					<CButton size="sm" color="dark"
						className="border-start border-start-white"
						onClick={()=>{setSuppliernew(true);}}
						id="btnsupplierlamatoggle">
					<CIcon icon="cibAddthis"/>
					</CButton>
					</CTooltip>
				</CInputGroup>
			</>
			) : (
			<>
				<CInputGroup size="sm">
					<CFormInput size="sm" maxLength={100}
						value={uDataformObj.nama_supplier||""}
						onChange={(e)=>setInitvalue("nama_supplier",e.target.value)}
						id="inpsupplierbaru"/>
					<CTooltip content="Toggle Supplier LAMA">
					<CButton size="sm" color="danger"
						className="text-white"
						onClick={()=>setSuppliernew(false)}
						id="btnsupplierbarutoggle">
					<CIcon icon="cilTouchApp"/>
					</CButton>
					</CTooltip>
				</CInputGroup>
			</>
			)}
		</CCol>
		</CRow>
		</small>
		)//>
	}
	const contentTabel = () => {
		if(uHTMLError500) return "";

		const vItemArr	= uDataitemArr || []
		return (
			<>
			<CCardHeader className="py-1 d-flex justify-content-between">
				<div className="d-none d-md-flex" style={{width:"85%"}}>
					<div style={{minWidth:300,width:"40%"}} 
						className="pe-1">
					<CInputGroup size="sm">
					<CFormInput size="sm"
						value={uFormitemObj.item_nama}
						onChange={(e)=>{setInititem("item_nama",e.target.value);initResetFormItem()}}
						onKeyDown={(e)=>hdlKeyDownItemBarang(e)}
						id="inpitemcaption"/>
						<CButton size="sm"
							color="dark"
							onClick={()=>hdlKlikItemLookUp()} 
							id="btnitemlookup">
							<CIcon icon="cilMagnifyingGlass"/>
						</CButton>
						<CTooltip content={(uBahasaObj.caption_tambahmaster||"caption_tambahmaster")+": "+(uBahasaObj.word_barang||"word_barang").toUpperCase()}>
						<CButton size="sm" color="dark"
							className="border-start border-start-white"
							onClick={()=>hdlKlikBarangRegister()} 
							id="btnitemmaster">
							<CIcon icon="cibAddthis"/>
						</CButton>
						</CTooltip>
					</CInputGroup>

					<div className="classpetunjuk d-md-none d-lg-block">
						<small>{uBahasaObj.caption_namabarang||"caption_namabarang"}</small>
					</div>
					</div>

					<div style={{minWidth:100,width:"10%"}} 
						className="pe-1">
						<CInputGroup size="sm">
							<CFormInput type="number"
								value={uFormitemObj.qty}
								size="sm"
								min={0}
								className="text-end"
								onChange={(e)=>setInititem("qty",e.target.value)}
								onFocus={(e)=>e.target.select()}
							id="inpitemqty"/>
						<CInputGroupText className="px-2">
						<b>{(uFormitemObj.satuan||"PCS").toUpperCase()}</b>
						</CInputGroupText>
						</CInputGroup>

						<div className="classpetunjuk d-md-none d-lg-block">
							<small>{uBahasaObj.word_qty||"QTY"}</small>
						</div>
					</div>

					<div style={{minWidth:125,width:"18%"}} 
						className="pe-1">
						<NumberFormat 
							displayType={'text'} 
							value={uFormitemObj.harga_satuan}
							thousandSeparator={"."} 
							decimalSeparator={","}
							onValueChange={(values) => setInititem("harga_satuan",values.value)}
							renderText={values => (
								<CFormInput 
									value={values}
									size="sm"
									className="text-end"
									onChange={(e)=>setInititem("harga_satuan",e.target.value)}
									onFocus={(e)=>e.target.select()}
								id="inpitemhsatuan"/>
							)} 
							prefix={isMobile?"":"Rp"}/>

						<div className="classpetunjuk d-md-none d-lg-block">
							<small>{uBahasaObj.caption_hargasatuan||"caption_hargasatuan"}</small>
						</div>
					</div>

					<div className="pe-1">
						<CButton 
							size="sm" color="secondary" 
							onClick={hdlKlikItemTambah}
							id="btnitemtambah">
							<CIcon icon="cibAddthis"/> 
						</CButton>
					</div>
				</div>

				<div className="d-md-none">
					<CButton size="sm" color="secondary"
						onClick={()=>hdlKlikItemtambahmobile()}
						id="btnitemtambahmobile">
					<CIcon icon="cibAddthis" className="me-2"/>Tambah Item
					</CButton>
				</div>

				<div className="classfontsmaller text-muted">
				<small>{uBahasaObj.caption_itembarang||"caption_itembarang"}</small>
				</div>
			</CCardHeader>

			<CCardBody className="px-0">
				<table className="table table-borderless table-striped table-hover" id="idtabeldata">
				<thead>
					<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
					<th width="5%" className="px-1">No</th>
					<th className="px-1">
					<CRow className="mx-1">
					<CCol md="3" className="text-start">{uBahasaObj.caption_namabarang||"caption_namabarang"}</CCol>
					<CCol md="2" className="">{uBahasaObj.caption_batchno||"caption_batchno"}</CCol>
					<CCol md="2" className="">{uBahasaObj.caption_expdate||"caption_expdate"}</CCol>
					<CCol md="1" className="">{uBahasaObj.word_qty||"QTY"}</CCol>
					<CCol md="2" className="">{uBahasaObj.caption_hargasatuan||"caption_hargasatuan"} (Rp)</CCol>
					<CCol md="2" className="">{uBahasaObj.word_total||"word_total"}(Rp)</CCol>
					</CRow>
					</th>
					</tr>
				</thead>
				<tbody>
				{vItemArr.map((vItems,vKeys)=>{
					const {
						itemid,nama,expdate,batchno,qty,hsatuan,is_batchno
					} = vItems;

					const vCaptionUpper		= (nama||"").toUpperCase();
					const vCaptionExpDate 	= (expdate||"")==="" ? 
						(<CIcon icon="cilEthernet" height={25}/>)
						: UFunc.renderHTML("<small>"+UFunc.TglAngka(expdate||"")+"</small>");
					const vCaptionBatchNo 	= (batchno||"")==="" ? 
						(<CIcon icon="cilEthernet" height={25}/>)
						: UFunc.renderHTML("<small className='text-uppercase'>"+(batchno||"")+"</small>");

					const vEditKeys		= uInitEditObj.editkey;
					const vEditField	= uInitEditObj.editfield || "";

					return (
					<tr id={"idtr"+vKeys} className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}
						key={vKeys}>
						<td className="px-1 pe-0">{vKeys+1}.</td>
						
						<td className="px-1">
						<CRow className="mx-1">
						<CCol md="3" className="px-1 pe-0 text-start d-flex justify-content-between">
							<div className="text-capitalize">
							<small className="d-none d-md-inline d-lg-none">{(nama||"")}</small>
							<span className="d-md-none d-lg-inline">{(nama||"")}</span>

							{(is_batchno||"TIDAK")==="YA" && (
							<CBadge className="ms-1"
								shape="rounded-pill"
								color="danger">
							<small>
								{uBahasaObj.caption_batchno||"caption_batchno"}
							</small>
							</CBadge>
							)}
							</div>

							<CTooltip content={(uBahasaObj.caption_hapusitem||"caption_hapusitem")+": "+vCaptionUpper}>
								<CLink onClick={()=>hdlKlikTabelhapus(vKeys)}
									className="classcursorpointer d-none d-md-block"> 
									<CIcon className="classikontabel classikonhapus" height={25}/>
								</CLink>
							</CTooltip>

							<CDropdown className="d-md-none">
							<CDropdownToggle caret={false} size="sm" className="p-0"
								color="transparent">
								<CIcon icon="cilOptions" className="classikoncoreui" height={23}/>
							</CDropdownToggle>
							<CDropdownMenu>
							<CDropdownItem onClick={()=>hdlKlikTabeleditmobile(vKeys)}>
								<CIcon className="classikonedit ms-0 me-2 align-middle" height={25}/>
								{(uBahasaObj.caption_edititem || "Edit Item")}
							</CDropdownItem>
							<CDropdownItem onClick={()=>hdlKlikTabelhapus(vKeys)}>
								<CIcon className="classikonhapus ms-0 me-2 align-middle" height={25}/>
								{uBahasaObj.caption_hapusitem||"Hapus Item"}
							</CDropdownItem>
							</CDropdownMenu>
							</CDropdown>
						</CCol>

						<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

						<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_batchno}</CCol>
						<CCol xs="8" md="2" className="px-1 text-md-center">
							{(vEditKeys === vKeys && vEditField==="BATCH") ? (
							<CInputGroup size="sm">
								<CFormInput size="sm"
									maxLength={15}
									value={uInpItemEditBatch}
									onChange={(e)=>setInpItemEditBatch(e.target.value)}
									onKeyDown={(e)=>hdlKDownTabeledit(e,vKeys,"BATCH")}
									className="text-uppercase"
									autoFocus={true}
									id="inptabelbatchno"/>
								<CInputGroupText className="bg-dark p-0">
								<CButton size="sm"
									onClick={()=>hdlKlikTabelproses(vKeys,"BATCH")} 
									color="dark"
									id="btntabelbatchno">
									<CIcon icon="cilCheckAlt"/>
								</CButton>
								</CInputGroupText>
							</CInputGroup>
							) : (
							<>
							<CTooltip content="Klik Untuk Edit..">
							<CLink 
								onClick={()=>hdlKlikTabeledit(vKeys,"BATCH")}
								className="classcursorpointer text-dark d-none d-md-block">
							{vCaptionBatchNo}
							</CLink>
							</CTooltip>

							<span className="d-md-none">{batchno||"-"}</span>
							</>
							)}
						</CCol>

						<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_expdate}</CCol>
						<CCol xs="8" md="2" className="px-1 text-md-center">
							{(vEditKeys === vKeys && vEditField==="EXPDATE") ? (
							<CInputGroup size="sm">
							<CFormInput size="sm"
								type="date"
								value={uInpItemEditExpdate}
								onChange={(e)=>setInpItemEditExpdate(e.target.value)}
								onKeyDown={(e)=>hdlKDownTabeledit(e,vKeys,"EXPDATE")}
								id="inptabelexpdate"/>
								<CButton size="sm"
									onClick={()=>hdlKlikTabelproses(vKeys,"EXPDATE")} 
									color="dark"
									id="btntabelexpdate">
									<CIcon icon="cilCheckAlt"/>
								</CButton>
							</CInputGroup>
							) : (
							<>
							<CTooltip content="Klik Untuk Edit..">
							<CLink onClick={()=>hdlKlikTabeledit(vKeys,"EXPDATE")} 
								className="d-none d-md-block classcursorpointer text-dark">
							{vCaptionExpDate}
							</CLink>
							</CTooltip>
							
							<span className="d-md-none">{(expdate||"")!==""?UFunc.TglAngka(expdate):"-"}</span>
							</>
							)}
						</CCol>

						<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

						<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_qty}</CCol>
						<CCol xs="7" md="1" className="px-1 px-md-0 text-md-center fw-bolder">
							{(vEditKeys === vKeys && vEditField==="QTY") ? (
							<CInputGroup size="sm">
								<CFormInput size="sm"
									type="number"
									step="1" min="1"
									value={uInpItemEditQty}
									onFocus={(e)=>e.target.select()}
									onKeyDown={(e)=>hdlKDownTabeledit(e,vKeys,"QTY")}
									onChange={(e)=>setInpItemEditQty(e.target.value)}
									id="inptabelqty"/>
								<CButton size="sm"
									onClick={()=>hdlKlikTabelproses(vKeys,"QTY")} 
									color="dark"
									className="px-1"
									id="btntabelqty">
									<CIcon icon="cilCheckAlt"/>
								</CButton>
							</CInputGroup>
							) : (
							<>
							<CTooltip content="Klik Untuk Edit..">
							<CLink 
								onClick={()=>hdlKlikTabeledit(vKeys,"QTY")} 
								className="d-none d-md-block classcursorpointer text-decoration-none">
								<big className="text-black">{UFunc.formatAngka(qty||0)}</big>
							</CLink>
							</CTooltip>

							<span className="d-md-none">{UFunc.formatAngka(qty)}</span>
							</>
							)}
						</CCol>
						
						<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_hargasatuan} (Rp)</CCol>
						<CCol xs="7" md="2" className="px-1">
							{(vEditKeys === vKeys && vEditField==="HARGA") ? (
							<CInputGroup size="sm">
								<NumberFormat 
									value={uInpItemEditHarga}
									displayType={"text"} 
									thousandSeparator={"."} 
									decimalSeparator={","}
									onValueChange={(values) => setInpItemEditHarga(values.value)}
									renderText={values => (
										<CFormInput
											value={values}
											onChange={(e)=>setInpItemEditHarga(e.target.value)}
											onKeyDown={(e)=>hdlKDownTabeledit(e,vKeys,"HARGA")}
											size="sm"
											className="text-end"
											onFocus={(e)=>e.target.select()}
										id={"inptabelhsatuan"}/>
									)}
									prefix={""}/>
								<CButton size="sm"
									onClick={()=>hdlKlikTabelproses(vKeys,"HARGA")} 
									color="dark"
									id="btntabelhsatuan">
									<CIcon icon="cilCheckAlt"/>
								</CButton>
							</CInputGroup>
							) : (
							<>
							<CTooltip content="Klik Untuk Edit..">
							<CLink
								className="classcursorpointer d-none d-md-block text-decoration-none text-success" onClick={()=>{hdlKlikTabeledit(vKeys,"HARGA")}}>
								{UFunc.formatAngka(hsatuan||0)}
							</CLink>
							</CTooltip>

							<span className="d-md-none">{UFunc.formatAngka(hsatuan)}</span>
							</>
							)}
						</CCol>

						<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>
						<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_total} (Rp)</CCol>
						<CCol xs="7" md="2" className="px-1 text-end text-primary">{UFunc.formatAngka(
							(parseInt(hsatuan)||0) * (parseInt(qty)||0)
						)}
						</CCol>
						</CRow>
						</td>
					</tr>
					)
				})}
				</tbody>
				{uDataitemArr.length > 0 && (
				<tfoot>
				<tr className="text-center fw-bolder bg-dark text-white">
				<td className="px-1">&nbsp;</td>
				<td className="px-1">
					<CRow className="mx-1">
					<CCol xs="8">
					{(uBahasaObj.caption_jmlbruto||"caption_jmlbruto").toUpperCase()} (Rp)
					</CCol>

					<CCol xs="4" className="text-end px-1">
						<big>{UFunc.formatAngka(uDataformObj.nilai_bruto)}</big>
					</CCol>
					</CRow>
				</td>
				</tr>
				</tfoot>
				)}
				</table>
			</CCardBody>
			</>
		)//>
	}
	const contentPotongan = () => {
		if(uHTMLError500) return "";

		return (
			<small>
			<CRow className="my-2 mb-3">
			<CCol xs="6" className="pe-0">
				{uBahasaObj.caption_depositsisaretur||"caption_depositsisaretur"}{" "}
				<span className="text-primary">(Rp{UFunc.formatAngka(uDataformObj.deposit_supplier)})</span>
			</CCol>
			<CCol className={"pe-3 fw-bolder text-end"+(parseInt(uFormitemObj.nilai_retur_terpakai||0)>0?" text-success":"")}>
				Rp{UFunc.formatAngka(uFormitemObj.nilai_retur_terpakai)}
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="5" className="">{uBahasaObj.word_diskon||"word_diskon"}</CCol>
			<CCol className="text-end">
				<NumberFormat 
					value={uDataformObj.diskon||"0"}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => setInitvalue("diskon",values.value)}
					renderText={values => (
						<CFormInput 
							value={values}
							size="sm"
							className="text-end"
							onChange={(e)=>setInitvalue("diskon",e.target.value)}
							onFocus={(e)=>e.target.select()}
						id="inpdiskon"/>
					)} 
					prefix={isMobile?"":"Rp"}/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="5" className="pe-0">{uBahasaObj.caption_biayatransport||"caption_biayatransport"} ({uBahasaObj.word_dll||"word_dll"})</CCol>
			<CCol className="text-end">
				<NumberFormat 
					value={uDataformObj.b_transport||"0"}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => setInitvalue("b_transport",values.value)}
					renderText={values => (
						<CFormInput 
							value={values}
							size="sm"
							className="text-end"
							onChange={(e)=>setInitvalue("b_transport",e.target.value)}
							onFocus={(e)=>e.target.select()}
						id="inptransport"/>
					)} 
					prefix={isMobile?"":"Rp"}/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="5" className="fw-bolder">{uBahasaObj.caption_asalkas}</CCol>
			<CCol className="text-end">
				<CInputGroup size="sm">
				<CFormInput size="sm"
					value={uDataformObj.kas_caption||""}
					placeholder={"--"+uBahasaObj.word_pilih+" "+uBahasaObj.caption_asalkas+".."}
					readOnly={true}
					onClick={()=>hdlKlikKaslookup()}
					className="classbgdisabled"
					id="inpkascaption"/>
				<CButton size="sm"
					color="dark"
					onClick={()=>hdlKlikKaslookup()}
					id="btnkaslookup">
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				</CInputGroup>
			</CCol>
			</CRow>
			</small>
		)//>
	}
	const contentBayar = () => {
		if(uHTMLError500) return "";

		return (
			<small>
			<CRow className="my-3">
			<CCol xs="5">{uBahasaObj.caption_totalnetto||"caption_totalnetto"}</CCol>
			<CCol className="text-end fw-bolder text-primary">
				Rp{UFunc.formatAngka(uDataformObj.nilai_netto||0)}
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="5" className="fw-bolder">{uBahasaObj.caption_nilaibayar||"caption_nilaibayar"}</CCol>
			<CCol className="text-end">
				<NumberFormat 
					value={uDataformObj.nilai_bayar||"0"}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => setInitvalue("nilai_bayar",values.value)}
					renderText={values => (
						<CFormInput 
							value={values}
							size="sm"
							className="text-end"
							onChange={(e)=>setInitvalue("nilai_bayar",e.target.value)}
							onFocus={(e)=>e.target.select()}
						onKeyDown={(e)=>{
							if(parseInt(uDataformObj.nilai_hutang||0) <= 0)
								UFunc.hdlKeyDownInput(e,"btnSimpan")
						}}
						id="inpnilaibayar"/>
					)} 
					prefix={isMobile?"":"Rp"}/>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-3">
			<CCol xs="5">{uBahasaObj.caption_sisahutang||"caption_sisahutang"}</CCol>
			<CCol className={"text-end fw-bolder"+(parseInt(uDataformObj.nilai_hutang||0)>0?" text-danger":"")}>
				Rp{UFunc.formatAngka(uDataformObj.nilai_hutang||"0")}
			</CCol>
			</CRow>

			<CRow className="my-3">
			<CCol xs="5">{uBahasaObj.caption_tgljt||"caption_tgljt"}</CCol>
			<CCol className="text-end">
				{parseInt(uDataformObj.nilai_hutang||0) > 0 ? (
					<CFormInput size="sm"
						type="date"
						value={uDataformObj.tgl_jt||""}
						onChange={(e)=>setInitvalue("tgl_jt",e.target.value)}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
						id="inptgljt"/>
				) : (
					<CIcon icon="cilInfinity" height={20}/>
				)}
			</CCol>
			</CRow>
			</small>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformShow) return (<></>)//>

		if(uDlgformjenis === "BARANG") return contentDlgformregister();
		if(uDlgformjenis === "DLGFORMITEM") return contentDlgformitem();

		return (<></>)//>
	}
	const contentDlgformregister = () => {
		if(uDlgformjenis !== "BARANG") return (<></>)//>

		return (
			<CForm>
			<CRow className="my-2">
				<CCol md="12">{uBahasaObj.caption_namabarang||"caption_namabarang"}</CCol>
				<CCol>
					<CFormInput maxLength={50}
						size="sm"
						className="text-capitalize"
						value={uDlgforminputObj.barang_nama||""}
						onChange={(e)=>{uDlgforminputObj.barang_nama=(e.target.value);setEfekview(!uEfekview)}}
						id="inpformbarangnama"/>
				</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>
			<div className="mb-3 pb-3 classborderbottom classpetunjuk">
			*) Register Barang Baru, Akan Disimpan setelah Input Transaksi Pembelian Barang TERSIMPAN
			</div>  

			<CRow className="my-2">
				<CCol xs="4">{uBahasaObj.caption_kodebarang||"caption_kodebarang"}</CCol>
				<CCol>
					<CFormInput maxLength="15"
						size="sm"
						className="text-uppercase"
						value={uDlgforminputObj.barang_kode||""}
						onChange={(e)=>{uDlgforminputObj.barang_kode = (e.target.value);setEfekview(!uEfekview)}}
						id="inpformbarangkode"/>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol xs="4">{uBahasaObj.caption_jenisbarang||"caption_jenisbarang"}</CCol>
				<CCol>
					<CFormSelect
						size="sm"
						value={uDlgforminputObj.jenis_id}
						onChange={(e)=>{uDlgforminputObj.jenis_id=(e.target.value);setEfekview(!uEfekview)}}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
						id="inpformbarangjenis">
					{uBarangjenisArr.map((vItems,vKeys)=>{
						return (
						<option value={vItems.value} key={vKeys}>
							{vItems.caption}</option>
						)
					})}
					</CFormSelect>
				</CCol>
			</CRow>
			<div className="d-none">uDlgforminputObj.jenis_id = {uDlgforminputObj.jenis_id}</div>
			</CForm>
		)//>
	}
	const contentDlgformitem = () => {
		if(uDlgformjenis !== "DLGFORMITEM") return (<></>)//>

		return (
		<small>
		<CForm>
		<CRow className="my-2">
		<CCol xs="4">Nama Barang</CCol>
		<CCol>
			<CInputGroup size="sm">
			<CFormInput size="sm"
				value={uDlgforminputObj.barang_caption||""}
				onChange={(e)=>{uDlgforminputObj.barang_caption=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>hdlKDownDlgformbarang(e)}
				id="inpformbarangnama"/>
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikDlgformbaranglookup()}
				id="btnformbaranglookup">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="4">Batch No</CCol>
		<CCol>
			<CFormInput size="sm"
				value={uDlgforminputObj.batchno||""}
				onChange={(e)=>{uDlgforminputObj.batchno=e.target.value;setEfekview(!uEfekview)}}
				id="inpformbatchno"/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4">Exp.Date</CCol>
		<CCol>
			<CFormInput size="sm" type="date"
				value={uDlgforminputObj.expdate||""}
				onChange={(e)=>{uDlgforminputObj.expdate=e.target.value;setEfekview(!uEfekview)}}
				id="inpformexpdate"/>
		</CCol>
		</CRow>	

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="8">QTY(s)</CCol>
		<CCol>
			<CFormInput size="sm" type="number" min={0}
				className="text-end"
				value={uDlgforminputObj.qty||0}
				onChange={(e)=>{uDlgforminputObj.qty=e.target.value;setEfekview(!uEfekview)}}
				onFocus={(e)=>e.target.select()}
				id="inpformqty"/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4" className="pe-0">Harga Satuan (Rp)</CCol>
		<CCol>
			<NumberFormat 
				value={uDlgforminputObj.harga_satuan||0}
				displayType={"text"} 
				thousandSeparator={"."} 
				decimalSeparator={","}
				onValueChange={(values) =>{uDlgforminputObj.harga_satuan = (values.value);}}
				renderText={values => (
					<CFormInput size="sm"
						value={values}
						onChange={(e)=>{uDlgforminputObj.harga_satuan=(e.target.value);setEfekview(!uEfekview);}}
						className="text-end"
						onFocus={(e)=>e.target.select()}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
						id="inpformhsatuan"/>
				)}
				prefix={""}/>
		</CCol>
		</CRow>

		</CForm>
		</small>
		)//>
	}
	//--END INIT--/

	//--API--/
	const apiLoadinit = () => {
		uFuncname = apiLoadinit.name;
		setHTMLError500();

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vKasGLDefaultArr	= JSON.parse(localStorage.getItem("kasgllist"));

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_belibarang/ld_init";

		setLoading(true);
		setIsFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setIsFormprocess(false);
					
				setDatasupplierArr(JSON.parse(output_string.supplierobject||"[]"));
				setDatabarangArr(JSON.parse(output_string.itemobject||"[]"));
				setBarangjenisArr(JSON.parse(output_string.barangjenisobject||"[]"));
				
				const vTambahanKasArr = JSON.parse(output_string.kastambahanobject||"[]");
				setKasGLArr([...vKasGLDefaultArr,...vTambahanKasArr]);
			} else if(output_string.info) {
				setIsFormprocess(false);
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setIsFormprocess(true);
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiSimpanmain = () => {
		uFuncname = apiSimpanmain.name;

		setLoading(true);
		setIsFormprocess(true);

		/*//--TESTING_FRONTEND--//
		//--END TESTING_FRONTEND--//*/

		const vDataSendStr	= JSON.stringify(uDataformObj);
		const vDATAS	= JSON.stringify({
			send_datalist : vDataSendStr,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_belibarang/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			setIsFormprocess(false);

			if(output_string.sukses) {
				if(uIsSuppliernew) {
					const vIDtmp	= output_string.supplierid||0;
					const vSupplierobj	= {
						id: vIDtmp,
						caption: uDataformObj.nama_supplier+" - "+UFunc.leadZero(vIDtmp,5),
					}
					setDatasupplierArr([...uDatasupplierArr,vSupplierobj]);
				}

				let vPesan = (uBahasaObj.pesan_sukses || "pesan_sukses");
				if(output_string.pesankhusus) vPesan = output_string.pesankhusus;
				showToast(vPesan,"SUKSES");

				initResetForm();
				document.getElementById("inptanggal").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setIsFormprocess(true);
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false); setIsFormprocess(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	/*const apiSimpanregister = (_JENISFORM) => {
		uFuncname = apiSimpanregister.name;

		const vElstombol	= document.getElementById("btnDialogSimpan")

		let vApiURL = "";
		let vDATAS	= "";
		if(_JENISFORM==="SUPPLIER") {
			vApiURL = "api_supplier/pr_simpan";
			vDATAS	= JSON.stringify({
				send_suppliernama : uDataformObj.nama_supplier,
				send_tokenauth : uTokenObj.userinit
			});
		} else {
			vApiURL = "api_barang/pr_simpan";
			vDATAS	= JSON.stringify({
				send_barangkode : uDlgforminputObj.barang_kode,
				send_barangnama : uDlgforminputObj.barang_nama,
				send_jenisid : uDlgforminputObj.jenis_id,
				send_satuan : "UNIT",
				send_tokenauth : uTokenObj.userinit
			});

		}
		const vURLs	= pjson.homepage+"api/"+vApiURL;
		//alert(vURLs); return;

		setLoading(true);
		vElstombol && (vElstombol.disabled = true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vElstombol && (vElstombol.disabled = false);

			if(output_string.sukses) {
				let vNewCaption;
				let vNewID 	= "255";
				let vPesan	= (uBahasaObj.pesan_sukses || "pesan_sukses");
				if(output_string.pesankhusus) vPesan = output_string.pesankhusus;
				showToast(vPesan,"SUKSES");

				vNewID		= output_string.tabelid || "255";
				if(_JENISFORM==="SUPPLIER") {
					vNewCaption = UFunc.capitalize(uDataformObj.nama_supplier)+
						" - "+UFunc.leadZero(vNewID,5).toUpperCase();
					setDatasupplierArr([
						{value:vNewID,caption:vNewCaption,sisa_deposit:0},
						...uDatasupplierArr]);

					uDataformObj.id_supplier = (vNewID);
					uDataformObj.nama_supplier = (uDataformObj.nama_supplier);

					apiSimpanmain();
				} else {
					vNewCaption = UFunc.capitalize(uDlgforminputObj.barang_nama)+
						" ("+(uDlgforminputObj.jenis_caption||"").toUpperCase()+")"+
						""+((uDlgforminputObj.barang_kode||"")!==""?" - "+uDlgforminputObj.barang_kode:"").toUpperCase();

					setDatalookupArr([
						{id:vNewID,caption:vNewCaption,harga_satuan:"0",satuan:"UNIT",is_batchno:"TIDAK"},
						...uDatalookupArr]);
					hdlToggleDlgform();

					uFormitemObj.item_id = (vNewID);
					uFormitemObj.item_nama = (vNewCaption);
					uFormitemObj.satuan = ("PCS");

					let vTimeout = setTimeout(function(){
						clearTimeout(vTimeout);
						if(isMobile) document.getElementById("inpitemqty").focus();
					},300);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development") 
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired();
			}
		})
		.catch((error) =>{
			vElstombol && (vElstombol.disabled = false);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}//-*/
	//--END API--/

	//---EFFECT---/
	React.useEffect(() => {
		document.addEventListener("keydown",hdlKDownDocument);
		return() => {
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	});
	/*React.useEffect(()=>{
		//console.log("uElCardnota = "+(uElCardnota));
		const vHeightNota 		= uElCardnota && (uElCardnota.offsetHeight || 0);
		const vHeightSupplier	= uElCardsupplier && (uElCardsupplier.offsetHeight || 0);

		if(vHeightNota > vHeightSupplier) {
			uElCardsupplier && (uElCardsupplier.style.height = vHeightNota+"px");
		} else {
			uElCardnota && (uElCardnota.style.height = vHeightSupplier+"px");
		}
	},[uElCardnota,uElCardsupplier]);
	React.useEffect(()=>{
		const vHeightBayar 		= uElCardbayar && (uElCardbayar.offsetHeight || 0);
		const vHeightPotongan	= uElCardpotongan && (uElCardpotongan.offsetHeight || 0);

		if(vHeightPotongan > vHeightBayar) {
			uElCardbayar && (uElCardbayar.style.height = vHeightPotongan+"px");
		} else {
			uElCardpotongan && (uElCardpotongan.style.height = vHeightBayar+"px");
		}
	},[uElCardbayar,uElCardpotongan]);//-*/
	React.useEffect(()=>{
		hdlToggleDlgform(); hdlToggleDlglookup();

		apiLoadinit();

		return() => {
			initResetFormItem();
			setDataformObj({});
			setFormitemObj({});
			setDatalookupArr([]);
			setDatabarangArr([]);
			setDatasupplierArr([]);
			setBarangjenisArr([]);
			setDatabarangArr([]);
			setKasGLArr([]);//-*/
		}
	},[]);
	React.useEffect(()=>{
		if(!uISDlgformShow) {
			setDlgformjenis(""); setDlgforminputObj({})
			setIsFormprocess(false);
			return;
		}

		let vElsID;
		if(uDlgformjenis==="BARANG") vElsID = "inpformbarangnama";
		else vElsID = "inpformbarangnama";
		setIsFormprocess(true);

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			if(!isMobile) 
				document.getElementById(vElsID) && document.getElementById(vElsID).focus();
		},300);
	},[uISDlgformShow]);
	React.useEffect(()=>{ 
		if(!uIsDlglookupshow) {
			setDlglookupinput("") 
			setDatalookupArr([]) 
		}
	},[uIsDlglookupshow]);
	React.useEffect(()=>{ 
		if(uBarangjenisArr.length <= 0) return;
		uDlgforminputObj.jenis_id  = (uBarangjenisArr[0].value);
	},[uBarangjenisArr]);
	React.useEffect(()=>{
		const vIdx = UFunc.getObjectIndeks(uBarangjenisArr,uDlgforminputObj.jenis_id,"value");
		if(vIdx < 0) { return; }
		uDlgforminputObj.jenis_caption = (uBarangjenisArr[vIdx].caption||"");
	},[uDlgforminputObj.jenis_id]);
	React.useEffect(()=>{
		uDataformObj.nama_supplier 	= "";
		if(uIsSuppliernew) {
			uDataformObj.id_supplier 	= 0;
			document.getElementById("inpsupplierbaru") &&
				document.getElementById("inpsupplierbaru").focus()
		}
		setEfekview(!uEfekview)
	},[uIsSuppliernew]);
	React.useEffect(()=>{
		let vNilaiBruto = 0;
		uDataitemArr.forEach((vItems,vKeys)=>{
			vNilaiBruto = vNilaiBruto + 
				((parseInt(vItems.qty)||0) * (parseInt(vItems.hsatuan)||0));
		})
		uDataformObj.nilai_bruto	= vNilaiBruto;
		setEfekview(!uEfekview);
	},[uDataitemArr.length,uInitEditObj]);
	React.useEffect(()=>{
		let vNettoDeposit = (parseInt(uDataformObj.nilai_bruto)||0)  
			- (parseInt(uDataformObj.deposit_supplier)||0);
			//-> 70000 - 100.000 => -30000
			//-> 70000 - 50.000 => 20000

		let vDepositPakai = (parseInt(uDataformObj.deposit_supplier)||0)
		if(vNettoDeposit < 0) {
			vDepositPakai = vDepositPakai + vNettoDeposit;
			vNettoDeposit = 0;
		}
		uDataformObj.nilai_retur_terpakai	= vDepositPakai
		//console.log("vNettoDeposit = "+vNettoDeposit);return;

		const vNetto 	= vNettoDeposit
			- (parseInt(uDataformObj.diskon)||0) 
			+ (parseInt(uDataformObj.b_transport)||0);

		uDataformObj.nilai_netto	= vNetto < 0 ? 0 : vNetto;
		setEfekview(!uEfekview)
	},[uDataformObj.nilai_bruto,
		uDataformObj.deposit_supplier,
		uDataformObj.diskon,
		uDataformObj.b_transport]);
	React.useEffect(()=>{
		if((parseInt(uDataformObj.nilai_bayar)||0) > 0 && (parseInt(uDataformObj.nilai_netto)||0) > 0) 
			if((parseInt(uDataformObj.nilai_bayar)||0) > (parseInt(uDataformObj.nilai_netto)||0) ) {
				uDataformObj.nilai_bayar = (uDataformObj.nilai_netto);return;
			}//-*/

		const vHutang	= (parseInt(uDataformObj.nilai_netto)||0) - (parseInt(uDataformObj.nilai_bayar)||0);
		uDataformObj.nilai_hutang	= (vHutang>0 ? vHutang : 0);
		setEfekview(!uEfekview)
	},[uDataformObj.nilai_bayar,
		uDataformObj.nilai_netto]);
	React.useEffect(()=>{
		if(uDataitemArr.length > 0
			|| (uDataformObj.no_nota||"").trim() !== ""
			|| parseInt(uDataformObj.id_supplier||"0") > 0
			|| (uDataformObj.nama_supplier||"").trim() !== ""
			|| parseInt(uDataformObj.diskon||"0") > 0
			|| parseInt(uDataformObj.b_transport||"0") > 0
			|| parseInt(uDataformObj.nilai_bayar||"0") > 0
			|| parseInt(uDataformObj.id_gl_kas||"0") > 0
			|| (uDataformObj.tgl_beli||UFunc.DbDate()) !== UFunc.DbDate()
			|| (uDataformObj.tgl_jt||"").trim() !== ""
			) setFormupdate(true);
		else setFormupdate(false);
	},[uDataformObj.no_nota,
	uDataformObj.id_supplier,
	uDataformObj.nama_supplier,
	uDataformObj.diskon,
	uDataformObj.b_transport,
	uDataformObj.id_gl_kas,
	uDataformObj.tgl_beli,
	uDataformObj.tgl_jt,
	uDataformObj.nilai_bayar,
	uDataitemArr
	]);
	React.useEffect(()=>{
		if(uDataformObj.tgl_beli === undefined) 
			uDataformObj.tgl_beli = UFunc.DbDate();
	},[uDataformObj.tgl_beli]);
	//---END EFFECT---/

	//console.log("("+uComponentname+") Checked..");
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-3">
		<CContainer fluid className="px-3 classheadermain border-bottom"
			style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={() => hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="me-2 align-middle" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.menus_belibarang||"menus_belibarang").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav onClick={() => hdlKlikReset()} className="me-1 classcursorpointer">
				<CIcon className="classikon classikonreset align-middle" height={30}/>
			</CHeaderNav>

			<CHeaderNav>
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-0 px-lg-2">
			<CContainer fluid className="mb-3">
			<CForm>{contentMain()}</CForm>
			</CContainer>
		</div>

		<CFooter position="sticky" className="bg-light">
			<MyButton
				style={{paddingTop: 3,paddingBottom: 3}}
				centered={true}
				iconname="cilCheckAlt"
				disabled={uIsFormprocess}
				onClick={()=>hdlKlikSimpanMain()}
				id="btnSimpan">
				Simpan [F9]
			</MyButton>
		</CFooter>
		</div>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleDlglookup()}
			dialogHeader={uDialogHeader||"dialogHeader"}
			inputValue={uDlglookupinput}
			dataItemArr={uDatalookupArr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>

		<MyDialogform
			options={{centered:false}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uISDlgformShow} 
			dialogHeader={uDlgforminputObj.header||"uDialogHeader"}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>
		
		<MySessionextender active={true} {...props}/>
		</>
	)//>
}

export default Belibarangmain;
