import React from 'react'
//import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,CInputGroup,CInputGroupText,CFormSelect,CFormInput,CButton,CFormCheck,
	//CImage,
	CCard,CCardHeader,CCardFooter,CCardBody,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialoglookupapi,
	MyDialogform,
	MyDialogview,
	MyPagination,
	MyPage500,
} from '../components/index'
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { UFunc,cBulanPanjang,cBulanPendek } from '../helpers/functions'
//import { Konfirm } from '../helpers/onConfirm';

const pjson 	= require('../../package.json')
const Lapcustomer 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	//const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uSettinguserObj		= useSelector(state => state.gListUserSetting);
	const uSettingsystemObj		= useSelector(state => state.gSystemsetting);
	const uHTMLstyleTabel		= useSelector(state => state.gHTMLstyleLaporan);
	const [uEfekview,setEfekview]= React.useState(false);

	const uMaxData				= isMobile ? (uSettinguserObj.jml_mobile || 20) : (uSettinguserObj.jml_laporan || 100);

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderActionObj.isHeaderView || false;
	const uHandelReset	 		= uHeaderActionObj.isHeaderReset || false;

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uDatabiayaArr,setDatabiayaArr]	= React.useState([]);
	const [uDatavieworiArr,setDatavieworiArr]= React.useState([]);
	const [uDataviewArr,setDataviewArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uDataloadedObj,setDataloadedObj]	= React.useState();
	const [uTitelObj,setTitelObj]			= React.useState({});
	const [uDataidentitasObj,setDataidentitasObj]	= React.useState({});
	const [uSortedObj,setSortedObj]			= React.useState({});

	//---DLGFORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	//---END DLGFORM--/

	//--DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]	= React.useState({});
	//--END DLG_LOOKUP--/

	//--DLG_VIEW--/
	const [uISDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uDlgviewObj,setDlgviewObj]		= React.useState({});
	//--END DLG_VIEW--/

	//--DOM_ELEMENTS--/
	const uElJmldata	= document.getElementById("idjmldata");
	const uFrameExport 	= document.getElementById("ifmcontentstoprint");
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_ELEMENTS--/

	//---HANDLE--/
	const hdlKDownCustomerlookup = (_EV) => {
		uDlgformObj.customer_id 	= undefined;

		if(_EV.keyCode !== 13) { setEfekview(!uEfekview); return }
		if((uDlgformObj.customer_caption||"").trim() === "") 
			{ setEfekview(!uEfekview); return }

		_EV.preventDefault(); _EV.stopPropagation();

		uDlglookupObj.is_triggered = true; setEfekview(!uEfekview);
		hdlKlikCustomerlookup();
	}
	const hdlKlikCustomerlookup = () => {
		uDlglookupObj.inputvalue= uDlgformObj.customer_caption||"";
		uDlglookupObj.header 	= (uBahasaObj.word_lookup||"word_lookup")+": "+(uBahasaObj.word_customer||"word_customer");
		setDlglookupshow(true);
	}
	const hdlKlikDlglookuppilih = (_SELECTEDSTR) => {
		_SELECTEDSTR = _SELECTEDSTR||"{}";
		//if(_SELECTEDSTR==="" || _SELECTEDSTR === "{}") return;
		const vLookupObj	= JSON.parse(_SELECTEDSTR);
		if(vLookupObj==={}) return;

		uDlgformObj.customer_id 		= parseInt(vLookupObj.id || "0");
		uDlgformObj.customer_caption	= vLookupObj.caption || "";

		setDlglookupshow(false);

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			const vElsFocus	= document.getElementById("chbJenissemua");
			if(!isMobile) vElsFocus && vElsFocus.focus();
		},350);
	}
	const hdlKlikDlgformsimpan = () => {
		let vElfocus	= document.getElementById("inpbulanid");
		if((parseInt(uDlgformObj.bln_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Periode Bulan Tidak Valid !"); return
		} 
		vElfocus	= document.getElementById("inptahun");
		if((parseInt(uDlgformObj.tahun)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Periode Tahun Tidak Valid !"); return
		} 
		vElfocus	= document.getElementById("inpbulanid");
		const vTanggalpilihDT = UFunc.toDate(uDlgformObj.tahun+"-"+UFunc.leadZero(uDlgformObj.bln_id)+"-01");
		if(UFunc.DateIsBeyond(vTanggalpilihDT)) {
			vElfocus && vElfocus.focus();
			showToast("Periode Bulan/Tahun Tidak Valid !"); return
		}
		vElfocus	= document.getElementById("inpcustomercaption");
		if((parseInt(uDlgformObj.customer_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Nama Customer TIDAK Dipilih Dengan Benar..<br/>Mohon Diperbaiki !"); return
		}//-*/

		vElfocus	= document.getElementById("chbJenissemua");
		if((uDlgformObj.jenis_laporan!=="SEMUA" && uDlgformObj.jenis_laporan!=="PIUTANG")) {
			vElfocus && vElfocus.focus();
			showToast("Jenis Laporan Harus Dipilih !"); return
		}//-*/

		apiLoadview();
	}
	const hdlKlikDetilitem = (_IDTABEL) => {//--TIDAK_DIPAKAI--/
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		setTabelIDPilih(_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vArridx	= UFunc.getObjectIndeks(uDataviewArr,_IDTABEL);
		if((uDataviewArr[vArridx].item_arr||[]).length > 0) {
			uDlgviewObj.header = "Detil Item: "+uDataviewArr[vArridx].no_nota;
			setDlgviewshow(true);
			return;
		}

		apiLoaditem(_IDTABEL);
	}
	const hdlKlikCetak = () => {
		if(uJmlData <= 0) return;

		let vContent 	= contentHTMLcetak();
		let pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	const hdlKlikExcel = () => {
		if(uJmlData <= 0) return;
		contentExcel();
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		uElJmldata && 
			(uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		if(uJmlData <= 0) return (
		<div className="text-center my-2 my-lg-5">
			<big className="fst-italic text-primary">
			{uBahasaObj.pesan_dataempty||"Data dengan Filter yang Dipilih Tidak Ditemukan.."}
			</big>
		</div>
		)//>

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;

		const vCustomerID	= parseInt(uDataloadedObj.customer_id)||0;
		const vColspan		= 7;
		
		const vDataviewArr	= uDataviewArr.slice(vMin,(vMax));

		const vStylekolfixed	= {
			position: "sticky",
			left: 0,
			backgroundColor:"#f0f0f0",
			zIndex: 1,
		}

		return (
		<div style={{overflowX: "auto"}}>
			<table style={{minWidth:800,width:"100%"}} 
				className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead>
			<tr className="text-center align-top classfontsmaller">
			<th width={35} className="border" style={vStylekolfixed}>No</th>
			<th width="11%" className="text-start border" style={{...vStylekolfixed,left:35}}>{uBahasaObj.word_tanggal||"Tanggal"}</th>
			<th width="12%" className="text-start border">{uBahasaObj.caption_nonota||"No.Nota"}</th>
			<th className="text-start border">{uBahasaObj.caption_detilitem||"Item Transaksi"}</th>
			<th width="13%" className=" border">
				{uBahasaObj.caption_ntagihan||"N.Tagihan"} (Rp)

				<CLink onClick={()=>{
						if((uSortedObj.field||"")!=="nilai_netto") 
							setSortedObj({ field: "nilai_netto",mode:"ASC" })
						else {
							if(uSortedObj.mode===undefined) uSortedObj.mode = "ASC";
							else if(uSortedObj.mode==="ASC") uSortedObj.mode = "DESC";
							else setSortedObj({});
						}
						setEfekview(!uEfekview);
					}}
					className={"ms-1 classcursorpointer "+((uSortedObj.field||"")==="nilai_netto"?"text-danger":"text-primary")}>
					<CIcon icon={(((uSortedObj.mode||"")==="DESC" && (uSortedObj.field||"")==="nilai_netto")?"cilArrowBottom":"cilArrowTop")} className="align-middle" height={20}/>
				</CLink>
			</th>

			<th width="13%" className=" border">
				{uBahasaObj.caption_nbayar||"N.Bayar"} (Rp)

				<CLink onClick={()=>{
						if((uSortedObj.field||"")!=="nilai_bayar") 
							setSortedObj({ field: "nilai_bayar",mode:"ASC" })
						else {
							if(uSortedObj.mode===undefined) uSortedObj.mode = "ASC";
							else if(uSortedObj.mode==="ASC") uSortedObj.mode = "DESC";
							else setSortedObj({});
						}
						setEfekview(!uEfekview);
					}}
					className={"ms-1 classcursorpointer "+((uSortedObj.field||"")==="nilai_bayar"?"text-danger":"text-primary")}>
					<CIcon icon={(((uSortedObj.mode||"")==="DESC" && (uSortedObj.field||"")==="nilai_bayar")?"cilArrowBottom":"cilArrowTop")} className="align-middle" height={20}/>
				</CLink>
			</th>

			<th width="13%" className=" border">
				{uBahasaObj.word_sisa||"Sisa"} (Rp)

				<CLink onClick={()=>{
						if((uSortedObj.field||"")!=="nilai_sisa") 
							setSortedObj({ field: "nilai_sisa",mode:"ASC" })
						else {
							if(uSortedObj.mode===undefined) uSortedObj.mode = "ASC";
							else if(uSortedObj.mode==="ASC") uSortedObj.mode = "DESC";
							else setSortedObj({});
						}
						setEfekview(!uEfekview);
					}}
					className={"ms-1 classcursorpointer "+((uSortedObj.field||"")==="nilai_sisa"?"text-danger":"text-primary")}>
					<CIcon icon={(((uSortedObj.mode||"")==="DESC" && (uSortedObj.field||"")==="nilai_netto")?"cilArrowBottom":"cilArrowTop")} className="align-middle" height={20}/>
				</CLink>
			</th>
			</tr>

			<tr className="d-none"><th colSpan={vColspan}>{JSON.stringify(uDataviewArr)}</th></tr>
			</thead>

			<tbody>
			{vDataviewArr.map((vItems,vKeys) => {
				const {
					id,tgl_pendapatan,no_nota,customer_caption,nilai_netto,
					nilai_bayar,nilai_sisa,item_arr,diskon,
					is_processing
				} = vItems;

				vNumber++;

				return (
				<tr id={"idtr"+id} className={"align-top"+(uTabelRowSelect===vKeys?" classrowselect":"")} key={vKeys}>
				<td className="px-1 text-end" style={vStylekolfixed}>{vNumber}.</td>
				<td className="px-1 text-start" style={{...vStylekolfixed,left:35}}>
					{UFunc.TglAngka(tgl_pendapatan)}
				</td>
				<td className="px-1 text-start">
					<div className="d-flex justify-content-between">
						<div>{(no_nota)}</div>
					</div>
				</td>

				<td className="text-start px-1">
					<ol className="ps-3 classfontsmaller">
					{(item_arr||[]).map((vItemterapi,vKeyterapi)=>{
						const {
							harga_satuan,qty,item_satuan,item_caption,
							pasien_caption,item_idx
						} = vItemterapi;

						return (
						<li key={vKeyterapi}>
						<div className="d-flex justify-content-between">
							<div>
							<div>
								{(pasien_caption||"")!==""
									? (<><span className="text-primary">{pasien_caption}</span> &middot; </>)
									: ("")
								}
								<small>{item_caption}</small>
							</div>

							<small className="fst-italic d-block ms-2">
								&#8702; <b>{qty}</b>{item_satuan} x Rp<b>{UFunc.formatAngka(harga_satuan)}</b>
							</small>
							</div>

							<span className="text-success ms-2">{UFunc.formatAngka(parseInt(qty||"1") * parseFloat(harga_satuan))}</span>
						</div>

						{(vKeyterapi < (item_arr||[]).length - 1) && (
						<div className="my-1 classborderbottom"/>
						)}
						</li>
						)
					})}
					{(parseFloat(diskon)||0) > 0 && (
					<li>
						<div className="my-1 border-top"/>
						<div className="d-flex justify-content-between">
							<span className="fw-bolder text-danger">Diskon</span>
							<span className="text-danger ms-2">{UFunc.formatAngka(parseFloat(diskon))}</span>
						</div>
					</li>
					)}
					</ol>
				</td>

				<td className="text-end px-1 fw-bolder text-primary">{UFunc.formatAngka(nilai_netto)}</td>
				<td className="text-end px-1 text-success">{UFunc.formatAngka(nilai_bayar)}</td>
				<td className={"text-end px-1"+(parseFloat(nilai_sisa) > 0 ? " fw-bolder text-danger" : "")}>{UFunc.formatAngka(nilai_sisa)}</td>
				</tr>
				)
			})}
			</tbody>

			<tfoot className="border-top border-top-primary">
			<tr className="fw-bolder text-white bg-dark">
			<td colSpan={vColspan-3} className="text-center">JUMLAH</td>
			<td className="text-end">{UFunc.formatAngka(uDataloadedObj.sum_nilai_netto)}</td>
			<td className="text-end">{UFunc.formatAngka(uDataloadedObj.sum_nilai_bayar)}</td>
			<td className="text-end">{UFunc.formatAngka(uDataloadedObj.sum_nilai_sisa)}</td>
			</tr>
			</tfoot>
			</table>
		</div>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		const vJmlTahunlaporan	= parseInt(uSettingsystemObj.jml_tahun_laporan)||5;

		return (
		<CForm>
		<CRow className="my-2">
		<CCol xs="3" md="4">Periode</CCol>
		<CCol>
			<CInputGroup size="sm">
			<CFormSelect size="sm"
				className="w-auto"
				value={uDlgformObj.bln_id}
				onChange={(e)=>{uDlgformObj.bln_id=e.target.value; setEfekview(!uEfekview)}}
				id="inpbulanid">
			{cBulanPanjang.map((vItems,vKeys)=>{
				return (
				<option value={vKeys+1} key={vKeys}>{vItems.toUpperCase()}</option>
				)
			})}
			</CFormSelect>
			
			<span className="mx-1">&nbsp;</span>

			<CFormSelect size="sm"
				className="w-auto"
				value={uDlgformObj.tahun}
				onChange={(e)=>{uDlgformObj.tahun=e.target.value; setEfekview(!uEfekview)}}
				id="inptahun">
				{(() => {
					let vTmpArr 	= [];
					const vTahunnow	= (new Date()).getFullYear();
					for(var vIdx=0;vIdx < vJmlTahunlaporan;vIdx++) {
						vTmpArr.push(
						<option value={(vTahunnow - vIdx)} key={vIdx}>{(vTahunnow - vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			</CInputGroup>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="3" md="4">Pilih Customer</CCol>
		<CCol>
			<CInputGroup size="sm">
			<CFormInput size="sm"
				placeholder="--Pilih CUSTOMER/KLIEN.."
				value={uDlgformObj.customer_caption}
				onChange={(e)=>{uDlgformObj.customer_caption=e.target.value;setEfekview(!uEfekview);}}
				onKeyDown={(e)=>hdlKDownCustomerlookup(e)}
				id="inpcustomercaption"/>
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikCustomerlookup()}
				id="btncustomerlookup">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="3" md="4">&nbsp;</CCol>
		<CCol>
			<CInputGroup size="sm" className="">
			<CInputGroupText>
				<input size="sm" type="radio"
					name="chbJenis"
					value="SEMUA"
					onChange={(e)=>{uDlgformObj.jenis_laporan=e.target.value;setEfekview(!uEfekview)}}
					checked={uDlgformObj.jenis_laporan === "SEMUA"}
					id="chbJenissemua"/>
			</CInputGroupText>
			<CInputGroupText className="bg-white">
				<label htmlFor="chbJenissemua" className="classcursorpointer">Semua Data Transaksi</label>
			</CInputGroupText>
			</CInputGroup>

			<CInputGroup size="sm" className="w-100 mt-1">
			<CInputGroupText>
				<input size="sm" type="radio"
					name="chbJenis"
					value="PIUTANG"
					checked={uDlgformObj.jenis_laporan === "PIUTANG"}
					onChange={(e)=>{uDlgformObj.jenis_laporan=e.target.value;setEfekview(!uEfekview)}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="chbJenispiutang"/>
			</CInputGroupText>
			<CInputGroupText className="bg-white">
				<label htmlFor="chbJenispiutang" className="classcursorpointer">Transaksi Belum Lunas</label>
			</CInputGroupText>
			</CInputGroup>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentDlgview = () => {//--TIDAK_DIPAKAI--/
		if(!uISDlgviewshow) return (<></>)//>
		const vArridx	= UFunc.getObjectIndeks(uDataviewArr,uTabelIDPilih);
		if(vArridx < 0) return (<></>)//>

		return (
		<small>
		<CRow>
		<CCol lg="6">
			<CCard className="mb-3">
			<CCardHeader className="py-0 pt-1 classfontsmaller">Data Nota</CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
					<div>Tgl Transaksi</div>
					<b>{UFunc.TglAngka(uDataviewArr[vArridx].tgl_pendapatan)}</b>
				</div>

				<div className="d-flex justify-content-between">
					<div>No.Nota</div>
					<div>{(uDataviewArr[vArridx].no_nota)}</div>
				</div>

				<div className="d-flex justify-content-between">
					<div>Customer</div>
					<b>{(uDataviewArr[vArridx].customer_caption||"-")}</b>
				</div>
			</CCardBody>
			</CCard>
		</CCol>

		<CCol lg="6">
			<CCard className="mb-3">
			<CCardHeader className="py-0 pt-1 classfontsmaller">Summary Transaksi</CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
					<div>Jumlah Bruto (Rp)</div>
					<b>{UFunc.formatAngka(uDataviewArr[vArridx].nilai_bruto)}</b>
				</div>
				<div className="d-flex justify-content-between">
					<div>Diskon (Rp)</div>
					<b className="text-danger">{UFunc.formatAngka(uDataviewArr[vArridx].diskon)}</b>
				</div>
				<div className="d-flex justify-content-between">
					<div>Total (Rp)</div>
					<b className="text-success">{UFunc.formatAngka(uDataviewArr[vArridx].nilai_netto)}</b>
				</div>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>

		<CRow>
		<CCol>
			<CCard className="mb-3">
			<CCardHeader className="py-0 pt-1 classfontsmaller">Data Item</CCardHeader>
			<CCardBody className="px-0">
			<table width="100%" className="table table-borderless table-striped table-hover" 
				id="idtabelitem">
			<thead className="align-top classfontsmaller text-center">
			<tr>
				<th width="5%">No</th>
				<th className="text-start">Item Transaksi</th>
				<th width="25%">Nilai (Rp)</th>
			</tr>
			</thead>
			
			<tbody>
			{(uDataviewArr[vArridx].item_arr||[]).map((vItems,vKeys)=>{
				const {
					harga_satuan,qty,item_satuan,item_caption,
					pasien_caption,item_idx
				} = vItems;

				return (
				<tr className="align-top" key={vKeys}>
				<td align="right">{vKeys+1}.</td>

				<td>
					<div>
						{(pasien_caption||"")!==""
							? (<><span className="text-primary">{pasien_caption}</span> &middot; </>)
							: ("")
						}
						<small>{item_caption}</small>
					</div>

					<small className="fst-italic d-block ms-2">
						&#8702; <b>{qty}</b> {item_satuan} x Rp<b>{UFunc.formatAngka(harga_satuan)}</b>
					</small>
				</td>

				<td align="right">
					<b className="text-success">{UFunc.formatAngka(parseInt(qty||"1") * parseFloat(harga_satuan))}</b>
				</td>
				</tr>
				)
			})}
			</tbody>
			</table>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</small>
		)//>
	}
	const contentHTMLcetak = () => {
		let vHTMLs	= uHTMLstyleTabel;

		//console.log("(Lapcustomer - contentHTMLcetak) uDataidentitasObj => "+(uDataidentitasObj));

		const vColspan 		= 7;
		const vDataviewArr	= uDataviewArr;

		vHTMLs	+= UFunc.RenderTabel(uDataidentitasObj,vColspan);
		
		const vJudullaporan = "Laporan Transaksi Customer";
		const vJudulsub		= `
			<div>Periode <b>`+uTitelObj.periode+`</b></div>
			<div>`+uTitelObj.subtitel+`</div>
		`;

		const vTabelheader	= `
			<th width="5%"><small>No</small></th>
			<th width="11%" align="left"><small>`+(uBahasaObj.word_tanggal||"Tanggal")+`</small></th>
			<th width="12%" align="left"><small>`+(uBahasaObj.caption_nonota||"No.Nota")+`</small></th>
			<th align="left"><small>Detil Items</small></th>
			<th width="13%"><small>N.Total (Rp)</small></th>
			<th width="12%"><small>Terbayar (Rp)</small></th>
			<th width="13%"><small>N.Sisa (Rp)</small></th>
			</tr>
		`;
		
		let vTabelbody	= "";
		let vNumber		= 0;
		vTabelbody 		+= vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_pendapatan,no_nota,nilai_netto,nilai_bayar,nilai_sisa,
				item_arr,diskon,
			} = vItems;
			vNumber++;

			 return (`
				<tr valign="top" key=`+vKeys+`>
				<td align="right"><small>`+vNumber+`.</small></td>
				<td align="left"><small>`+UFunc.TglAngka(tgl_pendapatan)+`</small></td>
				<td align="left"><small>`+(no_nota||"-")+`</small></td>
				<td align="left">
					<small>
					<ul style='padding-left:17px;'>
					`+(item_arr||[]).map((vItemterapi,vKeyterapi)=>{
						const {
							harga_satuan,qty,item_satuan,item_caption,item_nama,
							pasien_caption,item_idx
						} = vItemterapi;

						return (`
						<li key=`+vKeyterapi+`>
								`+((pasien_caption||"")!==""
									? `<b>`+pasien_caption+`</b> &middot;`
									: ``
								)+`
								<small>`+item_nama+`</small>

							<span style="font-style:italic;">
								(<b>`+qty+`</b>`+item_satuan+` x Rp<b>`+UFunc.formatAngka(harga_satuan)+`</b>)
							</span>
						</li>
						`)	
					}).join("")+

					((parseFloat(diskon)||0) > 0 
						? `<li>
						Diskon: Rp<b>`+UFunc.formatAngka(diskon)+`</b> 
						</li>`
						: ``)+`
					</small>
					</ul>
				</td>
				<td align="right">`+UFunc.formatAngka(nilai_netto)+`</td>
				<td align="right">`+UFunc.formatAngka(nilai_bayar)+`</td>
				<td align="right"><b>`+UFunc.formatAngka(nilai_sisa)+`</b></td>
				</tr>
			`);
		}).join("");
		
		const vTabelfooter 	= `
			<td colspan="`+(vColspan-3)+`">JUMLAH</td>
			<td align="right">`+UFunc.formatAngka(uDataloadedObj.sum_nilai_netto)+`</td>
			<td align="right">`+UFunc.formatAngka(uDataloadedObj.sum_nilai_bayar)+`</td>
			<td align="right">`+UFunc.formatAngka(uDataloadedObj.sum_nilai_sisa)+`</td>
		`;

		vHTMLs 		= vHTMLs.replace(/_TITELSUB_/g,vJudulsub);
		vHTMLs 		= vHTMLs.replace(/_JUDULLAPORAN_/g,vJudullaporan);
		vHTMLs 		= vHTMLs.replace(/_TABELHEADER_/g,vTabelheader);
		vHTMLs 		= vHTMLs.replace(/_TABELBODY_/g,vTabelbody);
		vHTMLs 		= vHTMLs.replace(/_TABELFOOTER_/g,vTabelfooter);

		return vHTMLs;
	}
	const contentExcel = () => {
		const vBulanID	= parseInt((uDataloadedObj||{}).bln_id)||"0";
		const vTahun	= parseInt((uDataloadedObj||{}).tahun)||"0";

		const vColspan 	= 8;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 50;

		let vTmpObj	= {
			no : "",
			tgl_pendapatan: "",
			no_nota: "",
			nama_item: "",
			h_item: "",
			nilai_netto: "",
			nilai_bayar: "",
			nilai_sisa: "",
		}
		vDatasetsArr.push(vTmpObj);
		vRowcount++;

		let wsrows	= [{hpt:47},{hpt:15.75}]
		uDataviewArr.forEach((vItems,vKeys)=>{
			const {
				id,tgl_pendapatan,no_nota,item_arr,diskon,
				nilai_netto,nilai_bayar,nilai_sisa
			} = vItems;

			const vRowheight	= 15.75 * 2;

			//--FIRST_ITEM--/
			const vItemstring = (item_arr||[]).
				filter((vItemterapi,vKeyterapi)=>vKeyterapi <= 0).
				map((vItemterapi,vKeyterapi)=>{
				const {
					harga_satuan,qty,item_satuan,item_caption,
					pasien_caption,item_idx
				} = vItemterapi;

				return (
					((pasien_caption||"")!==""
						? pasien_caption.toUpperCase()+" - "
						: ""
					)+item_caption+
					"\r\n"+(qty+" "+item_satuan)+" x "+UFunc.formatAngka(harga_satuan)+
					""
				)
			}).join("");

			const vItemharga = (item_arr||[]).
				filter((vItemterapi,vKeyterapi)=>vKeyterapi <= 0).
				map((vItemterapi,vKeyterapi)=>{
				const {
					harga_satuan,qty,item_satuan,item_caption,
					pasien_caption,item_idx
				} = vItemterapi;

				return (parseFloat(qty)||0) * (parseFloat(harga_satuan)||0);
			}).join("");
			//--END FIRST_ITEM--/

			vTmpObj	= {
				no : {v:vKeys+1,t:"n",s:{alignment:{vertical:"top"}},},
				tgl_pendapatan: {v: UFunc.TglAngka(tgl_pendapatan), s:{alignment:{vertical:"top"}}, },
				no_nota: {v:(no_nota||"-"),s:{alignment:{vertical:"top"}},},
				nama_item: {v:vItemstring,s:{alignment:{vertical:"top",wrapText:true}},},
				h_item: { v:vItemharga, t:"n", s:{numFmt: "#,##0",alignment:{vertical:"top"},} },
				nilai_netto: { v:parseFloat(nilai_netto || "0"), t:"n", s:{numFmt:"#,##0",alignment:{vertical:"top"},} },
				nilai_bayar: { v:parseFloat(nilai_bayar || "0"), t:"n", s:{numFmt:"#,##0",alignment:{vertical:"top"},} },
				nilai_sisa: { v:parseFloat(nilai_sisa || "0"), t:"n", s:{numFmt:"#,##0",alignment:{vertical:"top"},} },
			}

			vDatasetsArr.push(vTmpObj);
			wsrows.push({hpt:vRowheight});
			vRowcount++;

			//--NEXT_ITEM--/
			(item_arr||[]).
				filter((vItemterapi,vKeyterapi)=>vKeyterapi > 0).
				forEach((vItemterapi,vKeyterapi)=>{
					const {
						harga_satuan,qty,item_satuan,item_caption,
						pasien_caption,item_idx
					} = vItemterapi;

					const vItemnext = 
						((pasien_caption||"")!==""
							? pasien_caption.toUpperCase()+" - "
							: ""
						)+item_caption+
						"\r\n"+(qty+" "+item_satuan)+" x "+UFunc.formatAngka(harga_satuan)+
					"";

					const vHarganext =  (parseFloat(qty)||0) * (parseFloat(harga_satuan)||0);
					vTmpObj	= {
						no : "",
						tgl_pendapatan: "",
						no_nota: "",
						nama_item: {v:vItemnext,s:{alignment:{vertical:"top",wrapText:true}},},
						h_item: { v:vHarganext, t:"n", s:{numFmt: "#,##0",alignment:{vertical:"top"},} },
						nilai_netto: "",
						nilai_bayar: "",
						nilai_sisa: "",
					}

					vDatasetsArr.push(vTmpObj);
					wsrows.push({hpt:vRowheight});
					vRowcount++;
				});
			//--END NEXT_ITEM--/
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			tgl_pendapatan: "",
			no_nota: "",
			nama_item: "",
			h_item: "",
			nilai_netto: { v:parseFloat(uDataloadedObj.sum_nilai_netto)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_bayar: { v:parseFloat(uDataloadedObj.sum_nilai_bayar)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_sisa: { v:parseFloat(uDataloadedObj.sum_nilai_sisa)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};

		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wscols	= [
			{wpx:40},
			{wpx:75},
			{wpx:100},
			{wch:vColposwidth},
			{wpx:85},
			{wpx:85},
			{wpx:85},
			{wpx:85},
		];
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 4}}
		]

		const vTanggalnowDT	= new Date()
		const vNamafile = 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_Lapcustomer_"+
			(cBulanPendek[vBulanID-1]).toUpperCase()+"_"+vTahun+
		"";
		const vSheetlabel = (cBulanPendek[vBulanID-1])+vTahun;

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);
		//--CUSTOM_HEADER--/
		const vJudullaporan = "Laporan Transaksi Customer"+
			"\r\nPeriode "+uTitelObj.periode+
			"\r\n"+uTitelObj.subtitel+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Tanggal",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"No.Nota",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Detil Items",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"N.Item (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"N.Tagihan (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"N.Terbayar (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"N.Sisa (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",vertical:"top",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/
		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//---END CONTENT--/

	//---API--/
	const apiLoadinit = () => {
		setHTMLError500();
		setDatabiayaArr([]);
		setDataloadedObj();
		setSortedObj({});
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_laphtps/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Lapcustomer-apiLoadinit) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				//console.log("(Lapcustomer-apiLoadinit) output_string.databiaya : "+(output_string.databiaya));
				setDataidentitasObj(JSON.parse(output_string.dataidentitas || "{}"));
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapcustomer-apiLoadinit) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Lapcustomer-apiLoadinit) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadview = () => {
		const vElsimpan	= document.getElementById("btnDialogSimpan");
		setDataloadedObj();
		setDatavieworiArr([]);
		setSortedObj({});
		
		setPageActive(1);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);

		vElsimpan && (vElsimpan.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_parjson: JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapcustomer/ld_view";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			//console.log("(Lapcustomer-apiLoadview) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpArr = (JSON.parse(output_string.datalaporan || "[]"));
				setDatavieworiArr([{firstrow:1},...vTmpArr]);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);

				setDataloadedObj(uDlgformObj);
				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapcustomer-apiLoadview) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			console.log("(Lapcustomer - apiLoadview) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaditem = (_IDTABEL) => {//--TIDAK_DIPAKAI--/
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDataviewArr,_IDTABEL);
		uDataviewArr[vArridx].is_processing = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid: _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapomzet/ld_item";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			uDataviewArr[vArridx].is_processing = false;
			//console.log("(Lapcustomer-apiLoaditem) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vDetildataArr 			= JSON.parse(output_string.dataitem || "[]");
				uDataviewArr[vArridx].item_arr 	= vDetildataArr;

				uDlgviewObj.header = "Detil Item: "+uDataviewArr[vArridx].no_nota;
				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapcustomer-apiLoaditem) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDataviewArr[vArridx].is_processing = false;
			console.log("(Lapcustomer - apiLoaditem) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoadinit();

		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDlgformshow(false);
			setDlglookupshow(false);
			setDlgviewshow(false);
			setDataloadedObj(false);
			setDatavieworiArr([]);
			setDatabiayaArr([]);
			setSortedObj({});
			setDataidentitasObj({});
			setJmlData(0);setJmlHal(0);
		}
	},[])
	React.useEffect(()=>{ 
		setTabelIDPilih(0);
		setTabelRowSelect(-1); 
	},[uPageActive]);
	React.useEffect(()=>{
		const vDatadefaultArr = uDatavieworiArr.slice(1);
		setDataviewArr(vDatadefaultArr);
	},[uDatavieworiArr,uDatavieworiArr.length])
	React.useEffect(()=>{
		setJmlData(uDataviewArr.length);

		const vSumnilainetto = uDataviewArr.reduce((vNilaistored,vItems)=>{
			return (parseFloat(vNilaistored)||0) + (parseFloat(vItems.nilai_netto)||0)
		}, 0);
		const vSumnilaibayar = uDataviewArr.reduce((vNilaistored,vItems)=>{
			return (parseFloat(vNilaistored)||0) + (parseFloat(vItems.nilai_bayar)||0)
		}, 0);
		const vSumnilaisisa = uDataviewArr.reduce((vNilaistored,vItems)=>{
			return (parseFloat(vNilaistored)||0) + (parseFloat(vItems.nilai_sisa)||0)
		}, 0);

		uDataloadedObj && (uDataloadedObj.sum_nilai_netto = vSumnilainetto);
		uDataloadedObj && (uDataloadedObj.sum_nilai_bayar = vSumnilaibayar);
		uDataloadedObj && (uDataloadedObj.sum_nilai_sisa = vSumnilaisisa);
	},[uDataviewArr,uDataviewArr.length])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500) return;

		const vTanggalnowDT	= new Date();
		if(uDlgformObj.bln_id === undefined)
			uDlgformObj.bln_id = uDataloadedObj ? uDataloadedObj.bln_id : vTanggalnowDT.getMonth()+1;
		if(uDlgformObj.tahun === undefined)
			uDlgformObj.tahun = uDataloadedObj ? uDataloadedObj.tahun : vTanggalnowDT.getFullYear();
		if(uDlgformObj.customer_id === undefined)
			uDlgformObj.customer_id = uDataloadedObj ? uDataloadedObj.customer_id : 0;
		if(uDlgformObj.customer_caption === undefined)
			uDlgformObj.customer_caption = uDataloadedObj ? uDataloadedObj.customer_caption : "";
		if(uDlgformObj.jenis_laporan === undefined)
			uDlgformObj.jenis_laporan = uDataloadedObj ? uDataloadedObj.jenis_laporan : "SEMUA";

		uDlgformObj.header = "Pilih Filter Laporan";
		setDlgformshow(true);
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelReset) return;

		uHeaderActionObj.isHeaderReset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uDataloadedObj) apiLoadinit()
		else { setDatavieworiArr([]);setDataviewArr([]); setDataloadedObj(); }
	},[uHandelReset]);
	React.useEffect(()=>{
		const vElfilter	= document.getElementById("btnFilter");
		if(!uISDlgformshow) { 
			vElfilter && (vElfilter.disabled = false)
			setDlgformObj({}); return 
		}

		vElfilter && (vElfilter.disabled = true)
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(isMobile) return;

			const vElfocus = document.getElementById("inpbulanid");
			vElfocus && vElfocus.focus();
		},350);
	},[uISDlgformshow]);
	React.useEffect(()=>{
		if(!uISDlglookupshow)
			{ setDlglookupObj({}); return }
	},[uISDlglookupshow]);
	React.useEffect(()=>{
		if(!uDataloadedObj) { setTitelObj({}); return }

		uTitelObj.periode 	= cBulanPanjang[parseInt(uDataloadedObj.bln_id)-1]+" "+uDataloadedObj.tahun;
		if((parseInt(uDataloadedObj.customer_id)||0) < 0)  uTitelObj.subtitel = "SEMUA CUSTOMER";
		else (uTitelObj.subtitel = uDataloadedObj.customer_caption);
	},[uDataloadedObj]);
	React.useEffect(()=>{
		if(uDatavieworiArr.length <= 0) return;

		if(uPageActive > 1) setPageActive(1);
		//else { setTabelRowSelect(-1); setTabelIDPilih(0); }

		if(UFunc.isEmpty(uSortedObj)) { 
			const vDatadefaultArr = uDatavieworiArr.slice(1);
			setDataviewArr(vDatadefaultArr);
			return
		}

		if(uSortedObj.mode==="ASC") {
			uDataviewArr.sort((a,b)=> a[uSortedObj.field] - b[uSortedObj.field])
		} else if(uSortedObj.mode==="DESC") {
			uDataviewArr.sort((a,b)=> b[uSortedObj.field] - a[uSortedObj.field])
		}
	},[uSortedObj,
	uSortedObj.field,
	uSortedObj.mode
	]);
	
	//console.log("Lapcustomer - uActiveroute => "+(uActiveroute));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )//>

	return (
		<>
		{(!uDataloadedObj) ? (
		<CCard className="classcardbg">
			<CCardHeader className="">
			<small className="fw-bolder">Pilih Filter Laporan</small>
			</CCardHeader>

			<CCardBody className="d-flex justify-content-center align-items-center" style={{minHeight:200}}>
			<div className="classpetunjuk">
			*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
			</div>
			</CCardBody>
		</CCard>
		) : (
		<CCard className="classcardbg mb-3">
			<CCardHeader className="d-flex justify-content-between">
			<div>
			<div className="">Periode <b>{uTitelObj.periode}</b></div>
			<small className="text-primary">{uTitelObj.subtitel}</small>
			</div>

			<div>
				<CLink onClick={()=>hdlKlikCetak()} className="classcursorpointer classikon classikoncetak"/>
				<span className="mx-1">&middot;</span>
				<CLink onClick={()=>hdlKlikExcel()} className="classcursorpointer classikon classikonexcel"/>
				<span className="mx-1">&middot;</span>
				<span className="classfontsmaller">
					<small className="text-muted">
						{UFunc.formatAngka(uTimeElapsed)}ms
					</small>
				</span>
			</div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>
		)}

		<MyDialogform
			options={{centered:true}}
			onSimpan={hdlKlikDlgformsimpan}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.header}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>

		<MyDialoglookupapi
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.header||"dialogHeader"}
			inputValue={uDlglookupObj.inputvalue||""}
			onKlikPilihItem={hdlKlikDlglookuppilih}
			targetApi={pjson.homepage+"api/api_customer/ld_lookup"} 
			kdownTrigger={uDlglookupObj.is_triggered || false}
			id="iddialoglookupapi"
			{...props}/>

		<MyDialogview
			renderContent={contentDlgview()}
			dialogHeader={uDlgviewObj.header || "Detil Item"}
			toggle={()=>setDlgviewshow(false)}
			options={{centered:true}}
			show={uISDlgviewshow}
			{...props}/>

		<iframe id="ifmcontentstoprint" title="FrameCetak" className="d-none"/>
		</>
	)//>
}	

export default Lapcustomer