import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CRow,
	CCol,
	CCardBody,
	CCardFooter,
	CTooltip,
	CLink,
	CForm,
	CListGroup,
	CListGroupItem,
	CFormGroup,
	CSpinner,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {
	MyPage500,
	MyPagination,
	MyDialogview,
	MyFormHapus
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import { isMobile } from "react-device-detect";

const pjson     = require('../../package.json');
const Beliaset 	= (props) => {
	const uComponentname 	= Beliaset.name;
	const { 
		prosesExpired,setLoading,showToast,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  	= useNavigate();
	const uDispatch		= useDispatch();
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uActiveroute  = useSelector(state => state.activeRoute);
	const uHTMLstyleTabel=useSelector(state => state.gHTMLstyleLaporan);
	const uTokenObj		= JSON.parse(localStorage.getItem("token"));
	const uIsScrollBottom= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted= useSelector(state => state.gIsEOMExecuted);
	const uEfekapp		= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] = React.useState(false);

	//---VAR_GLOBALS--/
	const uSettingObj 		= useSelector(state => state.gListUserSetting);
	const uIsAllowedhapus	= (uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && uIsEOMExecuted
	const [uFirstload,setFirstload]			= React.useState("YA");

	const uMaxData							= isMobile ? (uSettingObj.jml_mobile || 20) : (uSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	const [uIsDlghapusshow,setDlghapusshow]	= React.useState(false);
	const [uDlghapusheader,setDlghapusheader]= React.useState("");
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uInpKethapus,setInpKethapus]		= React.useState("");

	//---DLGVIEW---/
	const [uIsDlgviewshow,setDlgviewshow]	= React.useState(false)
	const [uDlgviewheader,setDlgviewheader]	= React.useState("");
	//---END DLGVIEW---/

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	const [uElBtnDlgsimpan,setElBtnDlgsimpan]	= React.useState(document.getElementById("btnDialogSimpan"));
	const uElJmldata	= document.getElementById("idjmldata");
	//---END VAR_GLOBALS--/

	//---HANDLE--/
	const hdlToggleDlghapus=()=>{ setDlghapusshow(false); }
	const hdlToggleDlgview=()=>{ setDlgviewshow(false); }
	const hdlKlikLihatdetil = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setTabelIDPilih(_IDTABEL);

		const vCaptionUpper	= 
		((uDatamainArr[vObjdx].nama_supplier || "")+
			(uDatamainArr[vObjdx].nonota!==""?" ("+uDatamainArr[vObjdx].nonota+")" : "")+
			"").toUpperCase();
		setDlgviewheader(uBahasaObj.caption_detilitem+": "+vCaptionUpper);
		const vDataitemArr = uDatamainArr[vObjdx].items_arr || []
		if(vDataitemArr.length > 0) {
			setDlgviewshow(true); return
		}

		apiLoaditem(_IDTABEL);
	}
	const hdlKlikHapus=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0;
		if(_IDTABEL <= 0) return;

		const vObjdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);
		setTabelIDPilih(_IDTABEL);
		
		setDlghapusheader((uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+
			(UFunc.HariAngka(uDatamainArr[vObjdx].tanggal)+" "+(uDatamainArr[vObjdx].supplier || "")+
				(uDatamainArr[vObjdx].nonota!==""?" ("+uDatamainArr[vObjdx].nonota+")" : "")+
				"").toUpperCase());
		setDlghapusshow(true);
	}
	const hdlKlikSimpanHapus=()=>{
		if((uInpKethapus||"").trim() === "") {
			showToast((uBahasaObj.pesan_penghapusanharusdiisi||"pesan_penghapusanharusdiisi")); 
			document.getElementById("inpketerangan").focus();
			return;
		}

		apiProseshapus();
	}
	//---END HANDLE--/

	//---Init_LOAD--/
	const initJmldata=()=>{
		const vKeyword = uKeywordObj.kwd_beliaset || "";
		if (vKeyword.length > 2) 
			uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
		else
			uElJmldata && (uElJmldata.innerHTML = "&#8734;");
	}
	const initFormAwal=()=>{
		setTabelIDPilih(0);
		setInpKethapus("");
	}

	const contentMain = () => {
		if(uHTMLError500) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;//isMobile ? 0 : (vMin-0);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_beliaset || "");
		const vKeywordcolor = uSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		//const vDataviewArr	= isMobile ? uDatamainArr.slice(0,(vMax+1)) : uDatamainArr.slice(vMin,(vMax+1));
		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.pg||1)===parseInt(uPageActive||1))

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row align-top text-center classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="3" className="text-start">{uBahasaObj.word_tanggal||"word_tanggal"}</CCol>
				<CCol md="2" lg="3" className="text-start">{uBahasaObj.word_supplier||"word_supplier"}</CCol>
				<CCol md="2" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
				<CCol md="2" className="text-start">{uBahasaObj.caption_kasasal||"Asal Kas"}</CCol>
				<CCol md="2" lg="1" className="">{uBahasaObj.word_total||"word_total"} (Rp)</CCol>
				<CCol md="1" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>
			<tr><td colSpan={2} className="d-none">{JSON.stringify(vDataviewArr)}</td></tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,tanggal,tgl_input,nama_supplier,username,terbayar,kategori,
				init_adahutang,is_processing,kas_asal_caption,status_jurnal,
				init_sudahsusut,nonota,nilai_total,init_expired
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (UFunc.HariAngka(tanggal)+" "+nama_supplier+
				((nonota||"")!=""?" ("+nonota+")":"")
				).toUpperCase();

			const vTanggal	= vKeyword==="" ? UFunc.TglAngka(tanggal) 
				: (UFunc.TglAngka(tanggal)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vSupplier= vKeyword==="" ? (nama_supplier||"") 
				: (nama_supplier||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUsername= vKeyword==="" ? UFunc.Usercaptionfilter(username||"") 
				: UFunc.Usercaptionfilter(username||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vNonota= vKeyword==="" ? (nonota||"") 
				: (nonota||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKategori= vKeyword==="" ? (kategori||"") 
				: (kategori||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKascaption= vKeyword==="" ? (kas_asal_caption||"UNDF") 
				: (kas_asal_caption||"UNDF").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglInput	= vKeyword==="" ? UFunc.WaktuAngka(tgl_input) 
				: (UFunc.WaktuAngka(tgl_input)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");

			const vColorstatustembak= (status_jurnal||"")==="TEMBAK" ? "text-primary" : "";

			return (
			<tr id={"idtr"+id} 
				className={"align-top text-end"+(uTabelRowSelect===vKeys?" classrowselect":"")}
				key={vKeys}>
				<td className="px-1 pe-0">{(vNumber)}.</td>
				<td className="px-1">	
				<CRow className="mx-0">
				<CCol md="3" className="px-1 text-start	d-none d-md-block">
					{UFunc.renderHTML(vTanggal)}
					{(uTokenObj.userhak === "FREEROLE") && (
						<span className="classfontsmaller"><small> &middot; ({id})</small></span>
					)}
					<div className="text-danger classfontsmaller">
					<small>[
						{((tgl_input||"")!=="") && (
						<>{UFunc.renderHTML(vTglInput)} &middot; </>
						)}			
						<span>{UFunc.renderHTML(vUsername.toUpperCase())}</span>
					]</small>
					</div>
				</CCol>

				<CCol md="2" lg="3" className="px-1 text-start">
					<div className="d-flex justify-content-between">
					<div className="text-info">{UFunc.renderHTML(vSupplier)}</div>

					{(is_processing||false)===true ? (
						<CSpinner color="dark" className="d-md-none" size="sm"/>
					) : (
					<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} className="p-0"
							color="transparent">
							<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikLihatdetil(id)}>
							<CIcon className="classikondetil me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_lihatdetil)}
						</CDropdownItem>
						
						{(uIsAllowedhapus 
							&& uIsEOMExecuted
							&& (init_sudahsusut||"YA")==="TIDAK"
							&& (init_expired||"YA")==="TIDAK"
						) && (
						<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
							<CIcon className="classikonhapus me-2 align-middle" height={25}/>
							{(uBahasaObj.caption_hapusdata)}
						</CDropdownItem>
						)}
						</CDropdownMenu>
					</CDropdown>
					)}
					</div>

					{((nonota||"") !== "") && (
						<small className="d-none d-md-block">{uBahasaObj.word_nonota||"word_nonota"}: {UFunc.renderHTML(vNonota)}</small>
					)}

					{((status_jurnal||"")!=="" && uTokenObj.userhak==="FREEROLE") && (
					<div className={"d-none d-md-block classfontsmaller "+vColorstatustembak}>
						<small>&middot; S.Jurnal: <b>{status_jurnal}</b> &middot;</small>
					</div>
					)}
				</CCol>
					
				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

				<CCol xs="4" md="2" className="px-1 text-start d-md-none">{uBahasaObj.word_tanggal}</CCol>
				<CCol xs="8" md="2" className="px-1 d-md-none">
					{UFunc.renderHTML(vTanggal)}
					{(uTokenObj.userhak === "FREEROLE") && (
						<span className="classfontsmaller"><small> &middot; ({id})</small></span>
					)}
				</CCol>

				{(uTokenObj.userhak==="FREEROLE") && (
				<>
				<CCol xs="4" className="d-md-none px-1 text-start">{uBahasaObj.caption_statusjurnal||"Status Jurnal"}</CCol>
				<CCol xs="8" className={"px-1 d-md-none classfontsmaller "+vColorstatustembak}>
					<b>{status_jurnal||"-"}</b>
				</CCol>
				</>
				)}

				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>
				
				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_keterangan}</CCol>
				<CCol xs="8" md="2" className="px-1 text-md-start">
					<span className={"text-uppercase"+((kategori||"")==="BIAYA"?" text-warning fw-bolder":"")}>{UFunc.renderHTML(vKategori)}</span>
				</CCol>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_kasasal||"Asal Kas"}</CCol>
				<CCol xs="8" md="2" className="px-1 text-md-start">
					<b>{UFunc.renderHTML(vKascaption)}</b>
				</CCol>
				
				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_inputby || "Inputby"}</CCol>
				<CCol xs="8" md="2" className="px-1 d-md-none">
					<div className="text-danger classfontsmaller">
					<small>[
						{((tgl_input||"")!=="") && (
						<>{UFunc.renderHTML(vTglInput)} &middot; </>
						)}			
						<span>{UFunc.renderHTML(vUsername.toUpperCase())}</span>
					]</small>
					</div>
				</CCol>
					
				<CCol xs="12" className="px-1 d-md-none my-3 classborderbottom"/>

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_total} (Rp)</CCol>
				<CCol xs="8" md="2" lg="1" className="px-1 text-success">
					{UFunc.formatAngka(nilai_total)}
				</CCol>

				<CCol md="1" className="px-0 d-none d-md-block text-center">
				{(is_processing||false)===true ? (
					<CSpinner color="primary" size="sm"/>
				) : (
				<>
					<CTooltip content={"--"+(uBahasaObj.caption_lihatdetil||"caption_lihatdetil")+": "+vCaptionUpper}>
					<CLink 
						className="classcursorpointer classikontabel classikondetil"
						onClick={()=>hdlKlikLihatdetil(id)} />
					</CTooltip>

					{(uIsAllowedhapus 
						&& uIsEOMExecuted
						&& (init_sudahsusut||"YA")==="TIDAK"
						&& (init_expired||"YA")!=="YA"
					)  ? (
						<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"caption_hapusdata")+": "+vCaptionUpper}>
						<CLink 
							className="classcursorpointer classikontabel classikonhapus"
							onClick={()=>hdlKlikHapus(id)} />
						</CTooltip>
					): (<>&nbsp;</>)}
				</>
				)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgview = () => {
		if(!uIsDlgviewshow) return (<></>)//>
		if(parseInt(uTabelIDPilih||"0") <= 0) return (<></>)//>

		const vObjdx		= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);
		const vDataitemArr  = uDatamainArr[vObjdx].items_arr || []
		if(vDataitemArr.length <= 0) return (<></>)//>

		return (
		<small>
		<CRow>
			<CCol lg="6">
			<CCard className="mb-3">
			<CCardHeader className="py-1">Data Nota</CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
				<div className="px-1">Tgl Pembelian</div>
				<div className="px-1 text-end">{UFunc.HariAngka(uDatamainArr[vObjdx].tanggal)}</div>
				</div>

				<div className="d-flex justify-content-between">
				<div className="px-1">Supplier</div>
				<div className="px-1 text-end text-info">{(uDatamainArr[vObjdx].nama_supplier)}</div>
				</div>

				<div className="d-flex justify-content-between">
				<div className="px-1">No.nota</div>
				<div className="px-1 text-end">{(uDatamainArr[vObjdx].nonota)}</div>
				</div>

				<div>&nbsp;</div>
			</CCardBody>
			</CCard>
			</CCol>

			<CCol>
			<CCard className="mb-3">
			<CCardHeader className="py-1">Nilai Nota</CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
				<div className="px-1">Kategori Aset</div>
				<div className="px-1 text-end fw-bolder">{(uDatamainArr[vObjdx].kategori)}</div>
				</div>

				<div className="d-flex justify-content-between">
				<div className="px-1">Kas Asal</div>
				<div className="px-1 text-end">{(uDatamainArr[vObjdx].kas_asal_caption||"UNDF")}</div>
				</div>

				<div className="d-flex justify-content-between">
				<div className="px-1">Nilai Bruto</div>
				<div className="px-1 text-end text-success">{UFunc.formatAngka(uDatamainArr[vObjdx].nilai_total)}</div>
				</div>

				<div className="d-flex justify-content-between">
				<div className="px-1">Terbayar</div>
				<div className="px-1 text-end">{UFunc.formatAngka(uDatamainArr[vObjdx].terbayar)}</div>
				</div>
			</CCardBody>
			</CCard>
			</CCol>
		</CRow>

		<CRow>
			<CCol>
			<CCard className="mb-3">
			<CCardHeader className="py-1">Data Item Aset</CCardHeader>
			<CCardBody className="px-0">
			<table className="table table-border">
			<thead className="d-none">
				<tr>
				<th width="5%"/>
				<th/>
				</tr>
			</thead>

			<tbody>
			{vDataitemArr.map((vItems,vKeys)=>{
				const {
					id,nama_aset,jenis_caption,harga,qty
				} = vItems

				const vTotal = parseInt(qty||"0") * parseInt(harga||"0")

				return (
				<tr className="align-top text-end" key={vKeys}>
				<td className="px-1 pe-0">{vKeys+1}.</td>
				<td className="px-1">
				<CRow className="mx-1">
					<CCol md="7" className="px-1 text-start">
						<div className="text-info">{nama_aset||"UNDF"}</div>
						<div className="fst-italic classfontsmaller"><small>{jenis_caption||""}</small></div>
					</CCol>

					<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

					<CCol xs="4" className="px-1 text-start d-md-none">Qty(s)</CCol>
					<CCol xs="8" md="1" className="px-1 px-md-0 text-md-center"><b>{UFunc.formatAngka(qty)}</b> UNIT</CCol>
					
					<CCol xs="5" className="px-1 text-start d-md-none">Harga Satuan (Rp)</CCol>
					<CCol xs="7" md="2" className="px-1">
						<span className="text-success d-md-none d-lg-block">{UFunc.formatAngka(harga)}</span>
						<small className="text-success d-none d-md-block d-lg-none">{UFunc.formatAngka(harga)}</small>
					</CCol>

					<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>
					<CCol xs="5" className="px-1 text-start d-md-none">Subtotal (Rp)</CCol>
					<CCol xs="7" md="2" className="px-1 text-end">
						<span className="d-md-none d-lg-block">{UFunc.formatAngka(vTotal)}</span>
						<small className="d-none d-md-block d-lg-none">{UFunc.formatAngka(vTotal)}</small>
					</CCol>
				</CRow>
				</td>
				</tr>
				)
			})}
			</tbody>
			</table>
			</CCardBody>
			</CCard>
			</CCol>
		</CRow>
		</small>
		)//>
	}
	//---END Init_LOAD--/

	//---API_FUNC--/
	const apiLoaddata = (_PAGE) => {
		uFuncname = apiLoaddata.name;
		_PAGE		= parseInt(_PAGE) || 1;
		setHTMLError500();

		const vKeyword = uKeywordObj.kwd_beliaset || "";
		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}
		setLoading(true);

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pg: _PAGE,
			send_maxdata	: uMaxData,
			send_loadjml	: vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_beliaset/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				let vTmpObj = JSON.parse(output_string.dataobject||"[]");
				vTmpObj.map(vItems=>vItems.pg = _PAGE);

				setDatamainArr([...vDatamainArr,...vTmpObj]);
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpObj.length);

				setFirstload("TIDAK");
				//console.log("("+uComponentname+"-"+uFuncname+") dataobject : "+JSON.stringify(output_string.dataobject));

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {

				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaditem = (_IDTABEL) => {
		uFuncname 	= apiProseshapus.name;
		_IDTABEL	= parseInt(_IDTABEL)||0;if(_IDTABEL <= 0) return
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vObjdx].is_processing = true
		setEfekview(!uEfekview);

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_beliaset/ld_item";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vObjdx].is_processing = false
			if(output_string.tampil) {
				uDatamainArr[vObjdx].items_arr = JSON.parse(output_string.dataitem||"[]")
				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vObjdx].is_processing = false
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus=()=>{
		uFuncname 	= apiProseshapus.name;
		if(uTabelIDPilih <= 0) {showToast("[0] ERROR !"); return;}
		const vObjdx= UFunc.getObjectIndeks(uDatamainArr,uTabelIDPilih);

		const vElbtnsimpan	= document.getElementById("btnDialogSimpan");
		vElbtnsimpan && (vElbtnsimpan.disabled = true);
		setLoading(true);
		
		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelIDPilih,
			send_kethapus : uInpKethapus,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_beliaset/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vElbtnsimpan && (vElbtnsimpan.disabled = false);

			if(output_string.sukses) {
				uDatamainArr.splice(vObjdx,1);
				setTabelRowSelect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
				setEfekview(!uEfekview);

				hdlToggleDlghapus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				uElBtnDlgsimpan.disabled = true;
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbtnsimpan && (vElbtnsimpan.disabled = false);
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

		return()=>{ 
			hdlToggleDlghapus();hdlToggleDlgview();
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDatamainArr([]); 
		}
	},[]);
	/*React.useEffect(()=>{
		//console.log("("+uComponentname+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;

		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom]);//-*/
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		
		setJmlData(0);
		setDatamainArr([]);
		setTabelRowSelect(-1);
		setPageActive(1);

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uIsEOMExecuted) return;

		uNavigate("/subkeuangan/beliaset/formmain");
	},[uHandelTambah]);
	React.useEffect(()=>{
		if(!uIsDlghapusshow) { initFormAwal(); return; }

		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			setElBtnDlgsimpan(document.getElementById("btnDialogSimpan"));

			const vElFocus = document.getElementById("inpkethapus");
			vElFocus && vElFocus.focus();
		},350);
	},[uIsDlghapusshow]);
	React.useEffect(()=>{
		if(!uIsDlgviewshow) {setDlgviewheader(""); return}
	},[uIsDlgviewshow]);
	React.useEffect(()=>{
		if(!uElJmldata) return;
		initJmldata()
	},[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData]);
	React.useEffect(()=>{
		if(uFirstload==="YA") return;

		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive]);
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_uComponentname
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHTMLError500) return (<MyPage500 content={uHTMLError500} {...props}/>)//>

	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<small className="text-primary">
				<span className="d-md-none fw-bolder">
				{(()=>{
					const vKeyword = uKeywordObj.kwd_beliaset || "";
					if(vKeyword.length < 3) {
						return (<>&#8734;</>)
					}

					return "("+UFunc.formatAngka(uJmlData)+")"
				})()}
				</span>
				<span className="d-none d-lg-block">{uBahasaObj.caption_rekapinput||"Rekap Input"}</span>
			</small>

			<div className="d-lg-none"/>
			<div className="text-muted classfontsmaller"><small>{UFunc.formatAngka(uTimeElapsed)}ms</small></div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>

		<MyFormHapus
			value={uInpKethapus}
			onCHvalue={(e)=>setInpKethapus(e.target.value)}
			onSimpan={()=>hdlKlikSimpanHapus()}
			show={uIsDlghapusshow} 
			dialogHeader={uDlghapusheader}
			toggle={hdlToggleDlghapus}/>
		<MyDialogview
			options={{size:"lg"}}
			show={uIsDlgviewshow} 
			dialogHeader={uDlgviewheader}
			toggle={hdlToggleDlgview}
			renderContent={contentDlgview()}/>
		</>
	)//>
}
	
export default Beliaset;
