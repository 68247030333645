import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CForm,CFormInput,CInputGroup,CInputGroupText,CFormSelect,
	CButton,
	CImage,
	CCard,CCardHeader,CCardFooter,CCardBody,
	CLink,
} from '@coreui/react';
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogform,
	MyPagination,
	MyPage500,
} from '../components/index'
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { UFunc,cBulanPanjang,cBulanPendek } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 		= require('../../package.json')
const Lapbukubesar 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uSettinguserObj		= useSelector(state => state.gListUserSetting);
	const uSettingsystemObj		= useSelector(state => state.gSystemsetting);
	const uHTMLstyleTabel		= useSelector(state => state.gHTMLstyleLaporan);
	const [uEfekview,setEfekview]= React.useState(false);

	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uSettinguserObj.jml_mobile || 20) : (uSettinguserObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderActionObj.isHeaderView || false;
	const uHandelReset	 		= uHeaderActionObj.isHeaderReset || false;

	const [uHTMLError500,setHTMLError500]	= React.useState();
	const [uDataGLArr,setDataGLArr]			= React.useState([]);
	const [uDatavieworiArr,setDatavieworiArr]= React.useState([]);
	const [uDataviewArr,setDataviewArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uDataloadedObj,setDataloadedObj]	= React.useState();
	const [uTitelObj,setTitelObj]			= React.useState({});
	const [uDataidentitasObj,setDataidentitasObj]	= React.useState({});
	const [uSortedObj,setSortedObj]			= React.useState({});

	//---DLGFORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformObj,setDlgformObj]		= React.useState({});
	//---END DLGFORM--/

	//--DOM_ELEMENTS--/
	const uElJmldata	= document.getElementById("idjmldata");
	const uFrameExport 	= document.getElementById("ifmcontentstoprint");
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_ELEMENTS--/

	//---HANDLE--/
	const hdlDlgformToggle = () => { setDlgformshow(false) }
	const hdlKlikDlgformsimpan = () => {
		let vElfocus	= document.getElementById("inpbulanid");
		if((parseInt(uDlgformObj.bln_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Periode Bulan Tidak Valid !"); return
		} 
		vElfocus	= document.getElementById("inptahun");
		if((parseInt(uDlgformObj.tahun)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Periode Tahun Tidak Valid !"); return
		} 
		vElfocus	= document.getElementById("inpbulanid");
		const vTanggalpilihDT = UFunc.toDate(uDlgformObj.tahun+"-"+UFunc.leadZero(uDlgformObj.bln_id)+"-01");
		if(UFunc.DateIsBeyond(vTanggalpilihDT)) {
			vElfocus && vElfocus.focus();
			showToast("Periode Bulan/Tahun Tidak Valid !"); return
		}
		vElfocus	= document.getElementById("inpglid");
		if((parseInt(uDlgformObj.gl_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Nama Akun GL yang Dipilih Tidak Valid !"); return
		}//-*/

		apiLoadview();
	}
	const hdlKlikCetak = () => {
		if(uJmlData <= 0) return;
		if(UFunc.isEmpty(uDataloadedObj)) return;

		let vContent 	= contentHTMLcetak();
		let pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	const hdlKlikExcel = () => {
		if(uJmlData <= 0) return;
		if(UFunc.isEmpty(uDataloadedObj)) return;

		const vGLid		= parseInt((uDataloadedObj||{}).gl_id)||"0";
		const vBulanID	= parseInt((uDataloadedObj||{}).bln_id)||"0";
		const vTahun	= parseInt((uDataloadedObj||{}).tahun)||"0";

		const vColspan 	= 8;

		let vDatasetsArr= [];
		let vRowcount	= 0;
		let vColposwidth= 10;

		let vTmpObj	= {
			no : "",
			tgl_transaksi: "",
			tgl_input: "",
			noref: "",
			ket: "",
			debet: "",
			kredit: "",
			saldo: "",
		}
		vDatasetsArr.push(vTmpObj);

		vRowcount++;
		uDataviewArr.map((vItems,vKeys)=>{
			const {
				id,tgl_transaksi,nilai_debet,nilai_kredit,nilai_saldo,ket,
				noref,tgl_input
			} = vItems;

			vColposwidth = (ket||"").length > vColposwidth 
				? (ket||"").length : vColposwidth; 

			const vTglinputDB= vKeys > 0 ? UFunc.DbDate(UFunc.toDatetime(tgl_input)) : tgl_transaksi;

			const vIStglinput	= vTglinputDB !== tgl_transaksi;

			vTmpObj	= {
				no : {v:vKeys+1,t:"n"},
				tgl_transaksi: tgl_transaksi ? { v:tgl_transaksi, t:"d", } : "-",
				tgl_input: vIStglinput ? { v:tgl_input, t:"d", } : "-",
				noref: (noref||"-"),
				ket: (ket||"-"),
				debet: { v:nilai_debet?parseFloat(nilai_debet):0, t:"n", s:{numFmt: "#,##0"} },
				kredit: { v:nilai_debet?parseFloat(nilai_kredit):0, t:"n", s:{numFmt: "#,##0"} },
				saldo: { v:parseFloat(nilai_saldo), t:"n", s:{numFmt: "#,##0"} },
			} 
			vDatasetsArr.push(vTmpObj);
			vRowcount++;
		});

		/*//--SUM_NILAI--/
		vTmpObj	= {};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--*/

		const wsrows	= [{hpt:47}]
		const wscols	= [
			{wpx:40},
			{wpx:75},
			{wpx:75},
			{wpx:75},
			{wch:vColposwidth},
			{wpx:90},
			{wpx:90},
			{wpx:100},
		];
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			//{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 2}}
		]

		const vTanggalnowDT	= new Date()
		const vNamafile = 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_Lapbukubesar_"+
			(cBulanPendek[vBulanID-1]).toUpperCase()+"_"+vTahun+
		"";
		const vSheetlabel = (cBulanPendek[vBulanID-1])+vTahun;

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);
		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode "+uTitelObj.periode+
			"\r\n"+uTitelObj.subtitel+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Tanggal",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Tgl Input",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"No.Ref",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Keterangan",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Debet (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Kredit (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Saldo (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/
		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//---END HANDLE--/

	//---PROSES--/
	const initJmldata = () => {
		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	}
	//---END PROSES--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		if(uJmlData <= 0) return (
		<div className="text-center my-2 my-lg-5">
			<big className="fst-italic text-primary">
			{uBahasaObj.pesan_dataempty||"Data dengan Filter yang Dipilih Tidak Ditemukan.."}
			</big>
		</div>
		)//>

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;

		const vGLid 		= parseInt(uDataloadedObj.gl_id)||0;
		const vColspan		= vGLid > 0 ? 5 : 6;
		
		const vDataviewArr	= uDataviewArr.slice(vMin,(vMax));

		const vStylekolfixed	= {
			position: "sticky",
			left: 0,
			backgroundColor:"#f0f0f0",
			zIndex: 1,
		}

		return (
		<div style={{overflowX: "auto"}}>
			<table style={{minWidth:800,width:"100%"}} 
				className="table table-borderless table-striped table-hover" id="idtabeldata">
			<thead>
				<tr className="text-center align-top classfontsmaller">
				<th width={35} className="border" style={vStylekolfixed}>No</th>
				<th width="11%" className="text-start border" style={{...vStylekolfixed,left:35}}>{uBahasaObj.word_tanggal||"Tanggal"}</th>
				<th width="11%" className="border">{uBahasaObj.caption_noref||"No.Ref"}</th>
				<th className="text-start border">{uBahasaObj.word_keterangan||"Keterangan"}</th>
				<th width="13%" className="border">{uBahasaObj.word_debet||"Debet"} (Rp)</th>
				<th width="13%" className="border">{uBahasaObj.word_kredit||"Kredit"} (Rp)</th>
				<th width="15%" className="border">{uBahasaObj.word_saldo||"Saldo"} (Rp)</th>
				</tr>
			</thead>

			<tbody>
			{vDataviewArr.map((vItems,vKeys) => {
				const {
					id,tgl_transaksi,nilai_debet,nilai_kredit,nilai_saldo,ket,
					noref,tgl_input
				} = vItems;

				vNumber++;

				const vTanggal  = tgl_transaksi ? UFunc.TglAngka(tgl_transaksi) : "-";
				const vTglinputDB= vKeys > 0 ? UFunc.DbDate(UFunc.toDatetime(tgl_input)) : tgl_transaksi;

				return (
				<tr className="align-top" key={vKeys}>
				<td className="px-1 text-end" style={vStylekolfixed}>{vNumber}.</td>
				<td className="px-1 text-start" style={{...vStylekolfixed,left:35}}>
					{UFunc.renderHTML(vTanggal)}
					{vTglinputDB !== tgl_transaksi && (
					<small className="d-block text-primary fst-italic">
						(<span className="d-none d-lg-inline">Input: </span>{UFunc.TglAngka(vTglinputDB)})
					</small>
					)}
				</td>
				<td className="text-center px-1">{(noref||"-")}</td>
				<td className="text-start px-1"><i>{UFunc.renderHTML(ket||"-")}</i></td>
				<td className="text-end px-1">{UFunc.formatAngka(nilai_debet)}</td>
				<td className="text-end px-1">{UFunc.formatAngka(nilai_kredit)}</td>
				<td className="text-end px-1 fw-bolder text-success">{UFunc.formatAngka(nilai_saldo)}</td>
				</tr>
				)
			})}
			</tbody>
			</table>
		</div>
		)
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		const vJmlTahunlaporan	= parseInt(uSettingsystemObj.jml_tahun_laporan)||5;
		const vDataGLviewArr	= uDlgformObj.datagl_arr || [];

		return (
		<CForm>
		<CRow className="my-2">
		<CCol>Periode</CCol>
		<CCol md="8">
			<CInputGroup size="sm">
			<CFormSelect size="sm"
				className="w-auto me-1"
				value={uDlgformObj.bln_id}
				onChange={(e)=>{uDlgformObj.bln_id=e.target.value; setEfekview(!uEfekview)}}
				id="inpbulanid">
			{cBulanPanjang.map((vItems,vKeys)=>{
				return (
				<option value={vKeys+1} key={vKeys}>{vItems.toUpperCase()}</option>
				)
			})}
			</CFormSelect>
			
			<CFormSelect size="sm"
				className="w-auto"
				value={uDlgformObj.tahun}
				onChange={(e)=>{uDlgformObj.tahun=e.target.value; setEfekview(!uEfekview)}}
				id="inptahun">
				{(() => {
					let vTmpArr 	= [];
					const vTahunnow	= (new Date()).getFullYear();
					for(var vIdx=0;vIdx < vJmlTahunlaporan;vIdx++) {
						vTmpArr.push(
						<option value={(vTahunnow - vIdx)} key={vIdx}>{(vTahunnow - vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			</CInputGroup>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol>Nama Akun GL</CCol>
		<CCol md="8">
			<CFormSelect size="sm"
				value={uDlgformObj.gl_id}
				onChange={(e)=>{uDlgformObj.gl_id=e.target.value; setEfekview(!uEfekview)}}
				id="inpglid">
			{vDataGLviewArr.map((vItems,vKeys)=>{
				return (
				<option value={vItems.value} key={vKeys}>{(vItems.caption||"").toUpperCase()}</option>
				)
			})}
			</CFormSelect>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentHTMLcetak = () => {
		let vHTMLs	= uHTMLstyleTabel;

		//console.log("(Lapbukubesar - contentHTMLcetak) uDataidentitasObj => "+(uDataidentitasObj));

		const vColspan 		= 7;
		const vDataviewArr	= uDataviewArr;
		const vGLid			= parseInt(uDataloadedObj.gl_id)||0;

		vHTMLs	+= UFunc.RenderTabel(uDataidentitasObj,vColspan);
		
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
		`<div>`+uTitelObj.subtitel+`</div>`+
		``;

		const vJudulsub		= `
			<div>Periode <b>`+uTitelObj.periode+`</b></div>
		`;

		const vTabelheader	= `
			<th width="5%">No</th>
			<th width="11%" align="left">`+(uBahasaObj.word_tanggal||"Tanggal")+`</th>
			<th width="15%" align="left">No.Nota</th>
			<th align="left">Keterangan</th>
			<th width="13%">Debet (Rp)</th>
			<th width="13%">Kredit (Rp)</th>
			<th width="15%">Saldo (Rp)</th>
			</tr>
		`;
		
		let vTabelbody	= "";
		let vNumber		= 0;
		vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_transaksi,nilai_debet,nilai_kredit,nilai_saldo,ket,
				noref,tgl_input
			} = vItems;
			vNumber++;

			const vTanggal  = tgl_transaksi ? UFunc.TglAngka(tgl_transaksi) : "-";
			const vTglinputDB= vKeys > 0 ? UFunc.DbDate(UFunc.toDatetime(tgl_input)) : tgl_transaksi;

			vTabelbody += `
				<tr valign="top" key=`+vKeys+`>
				<td align="right">`+vNumber+`.</td>
				<td align="left">`+vTanggal+`</td>
				<td align="left">
				<small>`+(noref||"-")+`</small>
				</td>
				<td align="left"><small><i>`+(ket||"-")+`</i></small></td>
				<td align="right">`+UFunc.formatAngka(nilai_debet)+`</td>
				<td align="right">`+UFunc.formatAngka(nilai_kredit)+`</td>
				<td align="right"><b>`+UFunc.formatAngka(nilai_saldo)+`</b></td>
				</tr>
			`;
		}).join("");
		
		const vTabelfooter 	= ``;

		vHTMLs 		= vHTMLs.replace(/_TITELSUB_/g,vJudulsub);
		vHTMLs 		= vHTMLs.replace(/_JUDULLAPORAN_/g,vJudullaporan);
		vHTMLs 		= vHTMLs.replace(/_TABELHEADER_/g,vTabelheader);
		vHTMLs 		= vHTMLs.replace(/_TABELBODY_/g,vTabelbody);
		vHTMLs 		= vHTMLs.replace(/_TABELFOOTER_/g,vTabelfooter);

		return vHTMLs;
	}
	const contentExcel = () => { }
	//---END CONTENT--/

	//---API--/
	const apiLoadinit = () => {
		setHTMLError500();
		setDataGLArr([]);
		setDataloadedObj();
		setSortedObj({});
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapbukubesar/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Lapbukubesar-apiLoadinit) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				//console.log("(Lapbukubesar-apiLoadinit) output_string.databiaya : "+(output_string.databiaya));
				setDataGLArr(JSON.parse(output_string.datagl || "[]"));
				setDataidentitasObj(JSON.parse(output_string.dataidentitas || "{}"));
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapbukubesar-apiLoadinit) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Lapbukubesar-apiLoadinit) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadview = () => {
		const vElsimpan	= document.getElementById("btnDialogSimpan");
		setDataloadedObj();
		setDatavieworiArr([]);
		setSortedObj({});
		
		setPageActive(1);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		
		vElsimpan && (vElsimpan.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_parjson: JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapbukubesar/ld_view";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			//console.log("(Lapbukubesar-apiLoadview) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vTmpArr = (JSON.parse(output_string.datalaporan || "[]"));
				setDatavieworiArr([{firstrow:1},...vTmpArr]);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);

				setDataloadedObj(uDlgformObj);
				hdlDlgformToggle();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapbukubesar-apiLoadview) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			console.log("(Lapbukubesar - apiLoadview) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoadinit();

		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			hdlDlgformToggle();
			setDataloadedObj(false);
			setDatavieworiArr([]);
			setDataGLArr([]);
			setSortedObj({});
			setDataidentitasObj({});
			setJmlData(0);setJmlHal(0);
		}
	},[])
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);
	React.useEffect(()=>{
		const vDatadefaultArr = uDatavieworiArr.slice(1);
		setDataviewArr(vDatadefaultArr);
	},[uDatavieworiArr,uDatavieworiArr.length])
	React.useEffect(()=>{
		setJmlData(uDataviewArr.length);

		const vSumnilai = uDataviewArr.reduce((vNilaistored,vItems)=>{
			return (parseFloat(vNilaistored)||0) + (parseFloat(vItems.nilai)||0)
		}, 0);

		uDataloadedObj && (uDataloadedObj.sum_nilai = vSumnilai);
	},[uDataviewArr,uDataviewArr.length])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		if(!uElJmldata) return;
		initJmldata();
	},[uElJmldata,uJmlData]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500) return;

		const vTanggalnowDT	= new Date();
		if(uDlgformObj.bln_id === undefined)
			uDlgformObj.bln_id = uDataloadedObj ? uDataloadedObj.bln_id : vTanggalnowDT.getMonth()+1;
		if(uDlgformObj.tahun === undefined)
			uDlgformObj.tahun = uDataloadedObj ? uDataloadedObj.tahun : vTanggalnowDT.getFullYear();
		if(uDlgformObj.gl_id === undefined)
			uDlgformObj.gl_id = uDataloadedObj ? uDataloadedObj.gl_id : uDataGLArr[0].value;

		uDlgformObj.header = "Pilih Filter Laporan";
		setDlgformshow(true);
	},[uHandelView]);
	React.useEffect(()=>{
		if(!uHandelReset) return;

		uHeaderActionObj.isHeaderReset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHTMLError500 || !uDataloadedObj) apiLoadinit()
		else { setDatavieworiArr([]);setDataviewArr([]); setDataloadedObj(); }
	},[uHandelReset]);
	React.useEffect(()=>{
		const vElfilter	= document.getElementById("btnFilter");
		if(!uISDlgformshow) { 
			vElfilter && (vElfilter.disabled = false)
			setDlgformObj({}); return 
		}

		vElfilter && (vElfilter.disabled = true)
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(isMobile) return;

			const vElfocus = document.getElementById("inpbulanid");
			vElfocus && vElfocus.focus();
		},350);
	},[uISDlgformshow]);
	React.useEffect(()=>{
		if(!uDataloadedObj) { setTitelObj({}); return }

		const vArridx		= UFunc.getObjectIndeks(uDataGLArr,uDataloadedObj.gl_id,"value");
		uTitelObj.periode 	= cBulanPanjang[parseInt(uDataloadedObj.bln_id)-1]+" "+uDataloadedObj.tahun;
		if(vArridx < 0)  uTitelObj.subtitel = "SEMUA POS BIAYA";
		else (uTitelObj.subtitel = uDataGLArr[vArridx].caption);
	},[uDataloadedObj]);
	React.useEffect(()=>{
		if(!uISDlgformshow) return;
		if(uDlgformObj.bln_id===undefined 
			|| uDlgformObj.tahun===undefined) return;

		const vTglfilterDT		= new Date(uDlgformObj.tahun,uDlgformObj.bln_id,0);
		uDlgformObj.datagl_arr	= uDataGLArr.filter(vItems => {
			const vTglinputDT	= UFunc.toDate(vItems.tgl_input);
			const vTglhapusDT	= vItems.tgl_hapus?UFunc.toDate(vItems.tgl_hapus) : null;

			return (vTglinputDT <= vTglfilterDT) && 
				(vTglhapusDT === null || vTglhapusDT > vTglfilterDT);
		}); setEfekview(!uEfekview);
	},[uDlgformObj,uDlgformObj.bln_id,uDlgformObj.tahun]);
	React.useEffect(()=>{
		if(!uISDlgformshow) return;
		if(uDlgformObj.datagl_arr===undefined) return;
		if(uDataloadedObj) return;

		let vArridx = -1// UFunc.getObjectIndeks((uDlgformObj.datagl_arr||[]),uDlgformObj.gl_id,"value");
		let vCountprocess = 0;
		do {
			const vIdcheck = (uDlgformObj.datagl_arr||[])[vArridx+1].value || 0;
			vArridx = UFunc.getObjectIndeks((uDlgformObj.datagl_arr||[]),vIdcheck,"value");
			vCountprocess++;
		} while (vArridx < 0 && vCountprocess < 10)

		uDlgformObj.gl_id = (uDlgformObj.datagl_arr||[])[vArridx].value; 
	},[uDlgformObj,(uDlgformObj.datagl_arr||[]).length]);
	
	//console.log("Lapbukubesar - uActiveroute => "+(uActiveroute));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500}/> )//>

	return (
	<>
		{(!uDataloadedObj) ? (
		<CCard className="classcardbg">
			<CCardHeader className="">
			<small className="fw-bolder">Pilih Filter Laporan</small>
			</CCardHeader>

			<CCardBody className="d-flex justify-content-center align-items-center" style={{minHeight:200}}>
			<div className="classpetunjuk">
			*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
			</div>
			</CCardBody>
		</CCard>
		) : (
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between">
			<div>
			<div className="">Periode <b>{uTitelObj.periode}</b></div>
			<small className="text-primary">{uTitelObj.subtitel}</small>
			</div>

			<div>
				<CLink onClick={()=>hdlKlikCetak()} className="classcursorpointer classikon classikoncetak"/>
				<span className="mx-1">&middot;</span>
				<CLink onClick={()=>hdlKlikExcel()} className="classcursorpointer classikon classikonexcel"/>
				<span className="mx-1">&middot;</span>
				<span className="classfontsmaller">
					<small className="text-muted">
						{UFunc.formatAngka(uTimeElapsed)}ms
					</small>
				</span>
			</div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>

			{((uJmlData > uMaxData)) && (
			<CCardFooter>
				<MyPagination
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i) => setPageActive(i)}/>
			</CCardFooter>
			)}
		</CCard>
		)}

		<MyDialogform
			options={{centered:true}}
			onSimpan={hdlKlikDlgformsimpan}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.header}
			toggle={hdlDlgformToggle}
			renderContent={contentDlgform()}/>
		<iframe id="ifmcontentstoprint" className="d-none"></iframe>
	</>
	)//>
}	

export default Lapbukubesar