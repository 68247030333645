import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { 
	CCard,
	CCardHeader,
	CCardBody,
	CRow,
	CCol,
	CImage,
	CCollapse,
	CDropdown,
	CDropdownMenu,
	CDropdownHeader,
	CDropdownDivider,
	CDropdownToggle,
	CDropdownItem,
	CLink,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { CChartLine } from '@coreui/react-chartjs';
import { getStyle, hexToRgba } from '@coreui/utils';
import { isMobile } from "react-device-detect";
import { UFunc } from "../helpers/functions";
import { 
	MyDialogview,
} from '../components/index';

const pjson     	= require('../../package.json');
const MyCardPasien 	= (props) => {
	const uComponentname 	= MyCardPasien.name;
	const {
		initLoader, dataRefresh, isFamilyview, pasienID, isActionview,
		showToast, prosesExpired, setSessionaktif,
		...others 
	} = props; 

	let uFuncName;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const objToken			= JSON.parse(localStorage.getItem("token"));

	const obBahasa  		= useSelector(state => state.listBahasa);
	const uActiveRoute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	
	const [uHTMLError500,setHTMLError500]		= React.useState(null);
	const [uKomponenLoad,setKomponenLoad]		= React.useState(false);
	const [uVaksinload,setVaksinload]			= React.useState(false);
	const [uBeratload,setBeratload]				= React.useState(false);
	const [uDataPasienObj,setDataPasienObj]		= React.useState({});
	const [uDataFamilyArr,setDataFamilyArr]		= React.useState([]);
	const [uDatavaksinArr,setDatavaksinArr]		= React.useState([]);
	const [uDataberatArr,setDataberatArr]		= React.useState([]);
	const [uVaksinExpand,setVaksinExpand]		= React.useState(false);
	//const [uImgURLProfile,setImgURLProfile] 	= React.useState("");
	
	const uPasienID			= parseInt(pasienID) || 0;
	const uImgDefault		= pjson.homepage+"api/images/icon_profile.png";
	const uImgURLProfile	= uDataPasienObj.url_foto || "";

	//---DLG_VIEW--/
	const [uIsShowDlgview,setShowDlgview]		= React.useState(false);
	const [uDlgviewObj,setDlgviewObj]			= React.useState({});
	//---END DLG_VIEW--/

	//---HANDEL--/
	const hdlKlikPasienEdit=()=>{ uNavigate("/klinik/pasien/forminput/"+uPasienID);}
	const hdlKlikPasienDaftar=()=>{uNavigate("/klinik/pasien/daftarperiksa/"+uPasienID);}
	const hdlKlikPasienRM=()=>{uNavigate("/klinik/pasien/rekammedis/"+uPasienID);}
	const hdlKlikLoadPasien=(_IDPASIEN)=>{
		//let vLoadInit	= "/klinik/pasien/daftarperiksa/";
		//if(initLoader.toUpperCase()==="PASIENREKAMMEDIS")
		let vLoadInit	= "/klinik/pasien/rekammedis/";

		uNavigate(vLoadInit+_IDPASIEN); 
	}
	const hdlKlikShowgrafik = () => {
		uDlgviewObj.headers	= uDataPasienObj.nama_pasien;
		uDlgviewObj.jenis	= "GRAFIK";
		setShowDlgview(true);
	}
	const hdlKlikShowprofile = () => {
		uDlgviewObj.headers	= uDataPasienObj.nama_pasien;
		uDlgviewObj.jenis	= "PROFIL";
		setShowDlgview(true);
	}
	const hdlKlikShowvaksin = () => {
		if(uDatavaksinArr.length <= 0) {
			showToast("Data Histori Vaksin Pasien Belum Ada !"); return;
		}

		uDlgviewObj.headers	= uDataPasienObj.nama_pasien;
		uDlgviewObj.jenis	= "VAKSIN";
		setShowDlgview(true);
	}
	//---END HANDEL--/

	//---INIT---/
	const contentHeader	= () => {
		if (uKomponenLoad || uHTMLError500) return (
		<>
		<div className="mb-2">
			<small className="fw-bolder">{(obBahasa.caption_profilepasien||"Profil Pasien")}</small>
		</div>
		<div align="center" className="d-none d-md-block">
			<CImage src={uImgDefault}
				className={uImgURLProfile===""?"classimgcircle classgrayscale":""}
				height={90}/>
		</div>
		</>
		)

		return (
		<>
		<div className="d-flex justify-content-between">
			<small className="fw-bolder">{(obBahasa.caption_profilepasien||"Profil Pasien")}</small>

			<div>
			{(uDataFamilyArr.length > 0 && (isFamilyview||true)===true) && (
			<>
			<CDropdown autoClose={true}>
			<CDropdownToggle color="transparent" className="p-0" caret={false}>
				<CIcon className="classikonfamily classikoncoreui" height={25}/>
			</CDropdownToggle>
			<CDropdownMenu className="pt-0">
				<CDropdownHeader className="bg-info fw-bolder text-uppercase text-white">
					{obBahasa.caption_petfamily||"caption_petfamily"}
				</CDropdownHeader>
				<CDropdownDivider className="mt-0" />
				<div className="overflow-auto" 
					style={{maxHeight:parseInt(window.innerHeight) - 185,}}>
				{uDataFamilyArr.map((vItems,vKeys)=>{
					const  {id,nama_pasien,signalement} = vItems;
					return (
					<CDropdownItem key={vKeys} 
						className="classcursorpointer"
						onClick={()=>hdlKlikLoadPasien(id)}>
						<strong>{nama_pasien}</strong>
						{(signalement||"")!=="" && (
						<>
						<span className="mx-1">&middot;</span>
						<small className="d-none d-lg-inline">[{signalement}]</small>
						<small className="d-lg-none">[{UFunc.Usercaptionfilter(signalement,3)}]</small>
						</>
						)}
					</CDropdownItem>
					);
				})}
				</div>
			</CDropdownMenu>
			</CDropdown>
			{(isActionview||true)===true && (
				<span>&middot;</span>
			)}
			</>
			)}
			{(isActionview||true)===true && (
			<CDropdown autoClose={true}>
			<CDropdownToggle color="transparent" className="p-0" caret={false}>
				<CIcon icon="cilChevronBottom" className="classikoncoreui" height={25} />
			</CDropdownToggle>
			<CDropdownMenu>
				<CDropdownItem className="classcursorpointer"
					onClick={()=>hdlKlikPasienEdit()}>
				<CIcon className="classikontabel classikonedit me-2" height={20} />
				{obBahasa.caption_editdata||"caption_editdata"}{" "}{obBahasa.word_pasien||"word_pasien"}
				</CDropdownItem>
				
				{((initLoader||"").toUpperCase()!=="PASIENREKAMMEDIS") && (
				<CDropdownItem className="classcursorpointer"
					onClick={()=>hdlKlikPasienRM()}>
				<CIcon className="classikontabel classikonrm" height={20} />&nbsp;{obBahasa.caption_viewrm||"caption_viewrm"}
				</CDropdownItem>
				)}

				<CDropdownDivider className="d-md-none classborderbottom" />

				<CDropdownItem className="classcursorpointer d-md-none"
					onClick={()=>hdlKlikShowgrafik()}>
					{(uBeratload||false)===true ? (
					<>
					<CSpinner color="primary" className="mx-2" size="sm"/>
					{obBahasa.word_loading||"Loading"}..
					</>
					) : (
					<>
					<CIcon icon="cilGraph" className="classikoncoreui me-2" height={20} />
					{obBahasa.caption_grafikberat||"Grafik Berat Badan"}
					</>
					)}
				</CDropdownItem>

				<CDropdownItem className="classcursorpointer d-md-none"
					onClick={()=>hdlKlikShowprofile()}>
					{(uKomponenLoad||false)===true ? (
					<>
					<CSpinner color="primary" className="mx-2" size="sm"/>
					{obBahasa.word_loading||"Loading"}..
					</>
					) : (
					<>
					<CIcon icon="cilDog" className="classikoncoreui me-2" height={20} />
					{obBahasa.caption_profilepasien||"Data Profil Pasien"}
					</>
					)}
				</CDropdownItem>

				<CDropdownItem className="classcursorpointer d-md-none"
					onClick={()=>hdlKlikShowvaksin()}>
					{(uVaksinload||false)===true ? (
					<>
					<CSpinner color="primary" className="mx-2" size="sm"/>
					{obBahasa.word_loading||"Loading"}..
					</>
					) : (
					<>
					<CIcon icon="cilHospital" className="classikoncoreui me-2" height={20} />
					{obBahasa.caption_historivaksin||"Histori Vaksin"}
					</>
					)}
				</CDropdownItem>

				</CDropdownMenu>
			</CDropdown>
			)}
			</div>
		</div>

		<div className="d-none d-md-block">{contentBodysm()}</div>
		</>
		);
		/*			
			<small className="text-success text-uppercase">({uDataPasienObj.jenis_pasien||"---"})</small>

		*/
	}
	const contentBodysm	= () => {
		if (uKomponenLoad || uHTMLError500) return (
		<>
		<div align="center" className="">
			<CImage src={uImgDefault}
				className={uImgURLProfile===""?"classimgcircle classgrayscale":""}
				height={90}/>
		</div>
		</>
		)

		return (
		<>
		<CRow className="mx-0">
		<CCol className="px-1 text-center">
			<CImage src={uImgURLProfile===""?uImgDefault:uImgURLProfile}
				className={uImgURLProfile===""?"classimgcircle classgrayscale":"my-2 border p-1"}
				height={uImgURLProfile===""?"90":"125"}/>
		</CCol>
		</CRow>
	
		<CRow className="mx-0">
		<CCol className="px-1 text-center">
			<span className="fw-bolder" id="idcardnamapasien">{uDataPasienObj.nama_pasien || ""}</span>
			{(uDataPasienObj.umur || "") !== "" && (
			<>
			{" "}&middot; {UFunc.formatAngka(uDataPasienObj.umur || "15")}th
			</>
			)}
			<div>
			{(uDataPasienObj.kode_pasien||"") !== "" && (
			<small><strong>{uDataPasienObj.kode_pasien||""}</strong></small>
			)}
			{(uDataPasienObj.nochip||"") !== "" && (
			<>
			{" "}&middot;{" "}
			<small><strong>{uDataPasienObj.nochip||""}</strong></small>
			</>
			)}
			</div>
		</CCol>
		</CRow>
		</>
		)//>
	}
	const contentMain	= () => {
		if (uKomponenLoad) return (
			<div className="text-center my-5"><CSpinner className="text-black-50"/></div>
		);//>
		if(uHTMLError500) return (
		<span className="fw-bolder text-dark-100 fst-italic">&middot;&middot; UNDEFINED &middot;&middot;</span>
		)//>

		if((uDataPasienObj||{})==={}) return (<></>);//>

		return (
		<>
		{contentBerat()}
		{contentProfil()}
		{contentVaksin()}
		{contentProfiltambahan()}
		</>
		)//>
	}
	const contentProfil = () => {
		if((uDataPasienObj||{})==={}) return (<></>);

		return (
		<>
		<CRow className="m-0 my-2 d-flex align-items-center">
			<CCol xs="12" className="px-2 classpetunjuk">{obBahasa.word_signalement||"word_signalement"}</CCol> 
			<CCol xs="2" className="px-0 text-center">
				<CIcon icon="cilTags" height={20} />
			</CCol> 
			<CCol xs="10" className="px-1">
			<strong className="text-success">{uDataPasienObj.jenis_pasien || ""}</strong>
			{(uDataPasienObj.signalement||"").length > 2 && (
			<>
			<span className="mx-2">&middot;</span>
			<small>[{uDataPasienObj.signalement || ""}]</small>
			</>
			)}
			</CCol> 
		</CRow>
		<CRow className="m-0 my-2 d-flex align-items-center">
			<CCol xs="12" className="px-2 classpetunjuk">{obBahasa.caption_jeniskelamin||"caption_jeniskelamin"}</CCol> 
			<CCol xs="2" className="px-0 text-center">
				<CIcon icon="cilAnimal" height={20} />
			</CCol> 
			<CCol xs="10" className="px-1">
			<strong>{uDataPasienObj.gender || "UNKNOWN"}</strong>
			{(uDataPasienObj.reproduksi||"")!=="" && (
			<>
				<span className="mx-2">&middot;</span>
				<small>{uDataPasienObj.reproduksi || "UNKNOWN"}</small>
			</>
			)}
			</CCol> 
		</CRow>

		<CRow className="m-0 my-2 d-flex align-items-start">
			<CCol xs="12" className="px-2 classpetunjuk">{obBahasa.word_pemilik||"word_pemilik"}</CCol> 
			<CCol xs="2" className="px-0 text-center">
				<CIcon icon="cilUser" className="mt-2" height={20}/>
			</CCol> 
			<CCol xs="10" className="px-1">
			<strong>{uDataPasienObj.customer_caption || "-"}</strong>
			<small className="fst-italic d-block">{UFunc.renderHTML(uDataPasienObj.customer_alamat || "-")}</small>
			</CCol>
		</CRow>

		{(uDataPasienObj.customer_telphp||"")!=="" && (
		<CRow className="m-0 my-2 d-flex align-items-center">
			<CCol xs="12" className="px-2 classpetunjuk">{obBahasa.caption_telphp||"caption_telphp"}</CCol> 
			<CCol xs="2" className="px-0 text-center">
				<CIcon icon="cilPhone" height={20}/>
			</CCol> 
			<CCol xs="10" className="px-1 my-1">
			<strong>{uDataPasienObj.customer_telphp || "-"}</strong>
			</CCol> 
		</CRow>
		)}
		</>
		)//>	
	}
	const contentProfiltambahan = () => {
		if((uDataPasienObj||{})==={}) return (<></>);

		return (
		<>
		<CRow className="m-0 my-2 d-flex align-items-center">
			<CCol xs="12" className="px-2 classpetunjuk">{obBahasa.caption_tgllahir||"caption_tgllahir"}</CCol> 
			<CCol xs="2" className="px-0 text-center">
				<CIcon icon="cilSun" height={20}/>
			</CCol> 
			<CCol xs="10" className="px-1">
			<strong>{(uDataPasienObj.tgl_lahir||"")!=="" ? UFunc.TglAngka(uDataPasienObj.tgl_lahir) : "-"}</strong>
			</CCol> 
		</CRow>

		<CRow className="m-0 my-2 d-flex align-items-center">
			<CCol xs="12" className="px-2 classpetunjuk">{obBahasa.caption_riwayatalergi||"caption_riwayatalergi"}</CCol> 
			<CCol xs="2" className="px-0 text-center">
				<span className={(uDataPasienObj.alergi||"")!==""?"text-danger classblinking":""}><CIcon icon="cilWarning" height={20} /></span>
			</CCol> 
			<CCol xs="10" className="px-1 my-1">
				<span className={(uDataPasienObj.alergi||"")!==""?"text-danger classblinking":""}>{uDataPasienObj.alergi || "-"}</span>
			</CCol> 
		</CRow>
		</>
		)//>
	}
	const contentBerat	= () => {
		if(uBeratload) return (
		<>
		<CRow className="m-0 my-2">
		<CCol className="text-center">
			<CSpinner color="primary"/>
			<div className="classpetunjuk"><small>Load Grafik Berat</small></div>
		</CCol>
		</CRow>
		<CRow className="m-0 my-2"><CCol className="classborderbottom"/></CRow>
		</>
		);

		//console.log("(MyCardPasien - contentBerat) uDataberatArr => "+JSON.stringify(uDataberatArr));
		if(uDataberatArr.length <= 0) return (<></>)

		let vLabelX	= [];
		let vValue	= [];
		uDataberatArr.forEach((vItems,vKeys)=>{
			vLabelX.push(UFunc.TglAngka(vItems.tgl_periksa));
			vValue.push(vItems.berat);
		});
		const vDatasets	= [{
			label: obBahasa.word_berat||"Berat",
			borderColor: getStyle("primary") || "#0000FF",
			backgroundColor: hexToRgba(getStyle('--cui-info'), 10),
			pointHoverBackgroundColor: getStyle('--cui-info'),
        	borderWidth: 2,
        	fill: true,
			data: vValue
		}];

		return (
		<>
		<CRow className="m-0 my-1 mb-2 d-none d-md-block">
		<CCol className="classpetunjuk px-1">
			{obBahasa.caption_grafikberat||"Grafik Berat Badan"}
		</CCol>
		</CRow>
		<CChartLine
			data={{
				labels: vLabelX,
				datasets: vDatasets,
			}}
			options={{
				animation: false,
				maintainAspectRatio: true,
				plugins: {
					legend: { display: false },
					tooltip:{
						callbacks: {
							intersect: false,
							mode: 'index',
							title: (tooltipItem) => {
								return (tooltipItem[0].label);
							},
							label: (tooltipItem) => {									
								//-->tooltipItem.dataset={"label":"Pendapatan","borderColor":"#321fdb","backgroundColor":"rgba(50, 31, 219, 0.05)","pointHoverBackgroundColor":"#321fdb","borderWidth":2,"fill":true,"data":["1250000","3500000","2500000","1500000","4500000","7500000","4500000","2500000","0","6500000","1500000","6500000","3500000"]}
								//console.log("label.tooltipItem = "+JSON.stringify(tooltipItem.dataset));//-*/

									let vlabels = tooltipItem.dataset.label || '';
			                        if (vlabels) vlabels += ': ';
			                        if (tooltipItem.value !== null) {
			                        	vlabels += UFunc.formatAngka(tooltipItem.parsed.y);
			                        }//-*/

								return vlabels;
							},
						},
					},
				},
				elements: {
					line: {
						tension: 0.4,
					},
					point: {
						radius: 0,
						hitRadius: 10,
						hoverRadius: 4,
						hoverBorderWidth: 3
					}
				},
				scales: {
					y: {
						ticks : {
							beginAtZero: true,
							maxTicksLimit: 5,
							callback: (label, index, labels) => {
								return UFunc.formatAngka(label)
							},
						},
						scaleLabel: { display:false, },
					},
					x:{
						ticks : {
							callback: (label, index, labels) => {
								//console.log("index = "+vLabelX[index])
								//return UFunc.TglAngka(vLabelX[index]).substr(0,5)
							},
						},
						scaleLabel: { display:false, },
						grid: { drawOnChartArea: false }
					},
				},
			}}
			id="idgrafikberat"/>
		<CRow className="m-0 my-2 d-none d-md-block"><CCol className="classborderbottom"/></CRow>
		</>
		)//>
	}
	const contentVaksin	= () => {
		if(uVaksinload) return (
		<>
		<CRow className="m-0 my-2"><CCol className="classborderbottom"/></CRow>
		<CRow className="m-0 my-2">
		<CCol className="text-center">
			<CSpinner />
			<div className="classpetunjuk"><small>Load Histori Vaksin</small></div>
		</CCol>
		</CRow>
		<CRow className="m-0 my-2"><CCol className="classborderbottom"/></CRow>
		</>
		);

		if(uDatavaksinArr.length <= 0) return (<></>)

		return (
		<>
		<CRow className="m-0 my-1 d-none d-md-block"><CCol className="classborderbottom"/></CRow>
		<CRow className="m-0 mt-2 d-none d-md-block">
		<CCol className="classpetunjuk px-1 d-none d-md-block">
			{obBahasa.caption_historivaksin||"caption_historivaksin"}
		</CCol>
		</CRow>
		
		{uDatavaksinArr.map((vItems,vKeys)=>{
			const vHide = (vKeys >= 5 && !uVaksinExpand) ? " d-none" : "";
			return (
			<CRow className="m-0" key={vKeys}>
			<CCol xs="1" className={"px-1 pe-0 fw-bold text-end"+vHide}><small>{vKeys+1}.</small></CCol>
			<CCol xs="4" md="5" className={"px-1 pe-0 text-start fw-bold"+vHide}>
				<small className="fw-bold d-lg-none">{UFunc.TglAngka(vItems.tgl_vaksin)}</small>
				<div className="classfontsmaller d-none d-lg-block"><small>{UFunc.TglAngka(vItems.tgl_vaksin)}</small></div>
			</CCol>
			<CCol xs="7" md="6" className={"px-1 text-end fw-bold fst-italic"+vHide}>
				<small className="d-lg-none">{vItems.pemberi}</small>
				<div className="classfontsmaller d-none d-lg-block"><small>{vItems.pemberi}</small></div>
			</CCol>
			<CCol xs="1" className={"px-1"+vHide}/>
			<CCol xs="11" className={"px-1 text-end text-success"+vHide}>{vItems.vaksin_caption}</CCol>
			</CRow>
			)
		})}
		{(uDatavaksinArr.length > 5) && (
			<CRow className="m-0 my-1">
			<CCol xs="12" className={"px-1 mt-2 text-center"}>
				<CLink onClick={()=>setVaksinExpand(!uVaksinExpand)}>
				<CIcon icon={uVaksinExpand?"cilExpandUp":"cilExpandDown"} className="text-warning"
					height={18}/>
				</CLink>
			</CCol>
			</CRow>
		)}
		<CRow className="m-0 my-2 d-none d-md-block"><CCol className="classborderbottom"/></CRow>
		</>
		)//>
	}
	const contentDlgview = () => {
		if(uDlgviewObj.jenis === "GRAFIK") return (
		<CCard className="classcardbg">
		<CCardHeader>Grafik Berat Pasien</CCardHeader>
		<CCardBody>{contentBerat()}</CCardBody>
		</CCard>
		)//>

		if(uDlgviewObj.jenis === "PROFIL") return (
		<CCard className="classcardbg">
		<CCardHeader>Data Profil Pasien</CCardHeader>
		<CCardBody>
		{contentProfil()}
		<div className="classborderbottom my-3"/>
		{contentProfiltambahan()}
		</CCardBody>
		</CCard>
		)//>

		if(uDlgviewObj.jenis === "VAKSIN") return (
		<CCard className="classcardbg">
		<CCardHeader>Histori Vaksin</CCardHeader>
		<CCardBody>{contentVaksin()}</CCardBody>
		</CCard>
		)//>
		
		return (<></>)//>
	}
	//---END INIT---/

	//---API_FUNC--/
	const apiLoaddata = () => {
		uFuncName = apiLoaddata.name;

		setKomponenLoad(true);
		setHTMLError500(null);
		setDataPasienObj({});
		setDataFamilyArr([]);
		setDataberatArr([]);
		setDatavaksinArr([]);

		/*//--TESTING_FRONTEND--/
		console.log("("+uComponentname+"-apiLoaddata) LEWAT ");
		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			setDataPasienObj(vPasienObj);
			setDataFamilyArr(vFamilyArr);
			setKomponenLoad(false);
		},2350); return;
		//--END TESTING_FRONTEND--*/

		/*if((objToken.userinit||"") === "") 
			{ prosesExpired(); return; }//-*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_tokenauth : objToken.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_profile";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ /*setSessionaktif(false);*/ return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setKomponenLoad(false);
			if(output_string.tampil) {
				//console.log("(MyCardPasien - apiLoaddata) output_string.datapasien => "+JSON.stringify(output_string.datapasien));
				setDataPasienObj(JSON.parse(output_string.datapasien || "{}"));
				setDataFamilyArr(JSON.parse(output_string.datafamily || "[]"));
				apiLoadberat();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncName+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				//prosesExpired();
			}
		})
		.catch((error) =>{
			setKomponenLoad(false);
			console.log("("+uComponentname+"-"+uFuncName+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadberat = () => {
		uFuncName = apiLoadberat.name;
		setBeratload(true);
		setVaksinload(true);

		/*//--TEST_FRONTEND--/
		let vTimeout	= setTimeout(()=>{
			clearTimeout(vTimeout);
			setDataberatArr(vTmpArr);
			setBeratload(false);
			apiLoadvaksin()
		},2000); return
		//--END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_tokenauth : objToken.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_berat";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ /*setSessionaktif(false);*/ return response.json(); }})
		//}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			//setSessionaktif(true);
			setBeratload(false);
			if(output_string.tampil) {
				/*console.log("(MyCardPasien - apiLoadberat) output_string.beratpasien : "+
					JSON.stringify(output_string.beratpasien));//-*/
				setDataberatArr(JSON.parse(output_string.beratpasien || "[]"));
				apiLoadvaksin();
			} else if(output_string.info) {
				setVaksinload(false);
				showToast(output_string.info);
			} else if(output_string.errors) {
				setVaksinload(false);
				console.log("("+uComponentname+"-"+uFuncName+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				//prosesExpired();
			}
		})
		.catch((error) =>{
			setVaksinload(false);
			setBeratload(false);
			console.log("("+uComponentname+"-"+uFuncName+") catch-error: "+error);
			//showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadvaksin = () => {
		uFuncName = apiLoadvaksin.name;
		setVaksinload(true);

		/*//--TEST_FRONTEND--/
		let vTimeout	= setTimeout(()=>{
			clearTimeout(vTimeout);
			const vTmpArr	= [];
			setDatavaksinArr(vTmpArr);
			setVaksinload(false);
		},2000);return;
		//--END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_tokenauth : objToken.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_vaksin";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ /*setSessionaktif(false);*/ return response.json(); }})
		//}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			//setSessionaktif(true);
			setVaksinload(false);
			//console.log("("+uComponentname+"-"+uFuncName+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				setDatavaksinArr(JSON.parse(output_string.vaksinpasien || "[]"));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncName+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				//prosesExpired();
			}
		})
		.catch((error) =>{
			setVaksinload(false);
			console.log("("+uComponentname+"-"+uFuncName+") catch-error: "+error);
			//showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	React.useEffect(()=>{
		setShowDlgview(false);
		return ()=>{
			setDataPasienObj({});
			setDataFamilyArr([]);
			setShowDlgview(false);
		}
	},[]); 
	React.useEffect(()=>{ apiLoaddata(); },[dataRefresh]); 
	React.useEffect(()=>{ 
		if(!uIsShowDlgview)
			{ setDlgviewObj({}); return }
	},[uIsShowDlgview]); 

	//console.log("(MyCardPasien) pasienID => "+(pasienID));
	return (
		<>
		<CCard className="my-0 my-md-auto classcardbg d-none d-md-block">
		<CCardHeader className="">{contentHeader()}</CCardHeader>

		<CCardBody className="p-0 px-1 d-none d-md-block">{contentMain()}</CCardBody>
		</CCard>

		<CCard className="my-0 classcardbg d-md-none">
		<CCardHeader className="">{contentHeader()}</CCardHeader>
		<CCardBody className="px-0">{contentBodysm()}</CCardBody>
		</CCard>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uIsShowDlgview} 
			toggle={()=>setShowDlgview(false)}
			dialogHeader={uDlgviewObj.headers}
			renderContent={contentDlgview()}
			className="d-md-none"
			{...props}/>
		</>
	)//>
}

export default MyCardPasien;
