import React from 'react'
import { useNavigate,Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CButton,
	CImage,
	CLink,
	CSpinner,CProgress,CBadge,
	CCard,CCardHeader,CCardBody,CCardFooter,CCardTitle,CCardSubtitle,CCardText,
	CWidgetStatsA,CWidgetStatsB,CWidgetStatsC,CWidgetStatsD,CWidgetStatsE,CWidgetStatsF,
} from '@coreui/react';
import { CChart } from '@coreui/react-chartjs';
import { getStyle, hexToRgba } from '@coreui/utils';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import CIcon from '@coreui/icons-react';
import {
	MyDialogform,
	MyPagination,
	MyPage500,
} from '../components/index'
import { UFunc } from '../helpers/functions'
import { Konfirm } from '../helpers/onConfirm';

const pjson 	= require('../../package.json')
const Dashboard = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	let uFuncname;
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uBahasaObj  			= useSelector(state => state.listBahasa);
	const uActiveroute  		= useSelector(state => state.activeRoute);
	const uEfekapp				= useSelector(state => state.effectApp);
	const uIsScrollBottom		= useSelector(state => state.gIsScrollBottom);
	const uChartlineRef 		= React.useRef(null);
	const [uEfekview,setEfekview]= React.useState(false);
	const [uEfekview2,setEfekview2]= React.useState(false);
	const [uHTMLError500,setHTMLError500]= React.useState();

	const uSettingObj			= useSelector(state => state.gListUserSetting);
	const uIsAllowedhapus		= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"
	const uMaxData				= isMobile ? (uSettingObj.jml_mobile || 20) : (uSettingObj.jml_laporan || 100);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uLayananArr			= JSON.parse(localStorage.getItem("layananlist")) || [];

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 			= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 		= uHeaderActionObj.isHeaderTambah || false;

	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);

	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uDialogHeader,setDialogHeader]	= React.useState("");

	const [ElBtnSimpan,setElBtnSimpan]		= React.useState(document.getElementById("btnDialogSimpan"));
	const uElJmldata						= document.getElementById("idjmldata");
	const uTglnowDT 						= new Date();

	//---HANDLE--/
	//---END HANDLE--/

	//---PROCESS--/
	const initRemoveexpired = () => {
		//--DIATUR_TIAP_TIGA_JAM_DIHAPUS_DATAFILTERNYA--/
		const vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		const vTimestampTS		= vDatadashboardObj.timestampTS;
		if(!vTimestampTS) return;

		const vThreehoursTS		= vTimestampTS + (2.5 * 60 * 60 * 1000);//--DUA_JAM
		//const vThreehoursTS		= vTimestampTS + (60 * 1000);//--SATU_MENIT_TESTING
		const vTimenowTS		= (new Date()).valueOf();

		if(vTimenowTS > vThreehoursTS) {
			const vDataArr	= vDatadashboardObj.data_arr || [];
			const vTglsatuDT= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
			const vTglsatuTS= vTglsatuDT.valueOf();

			const vFilterArr = vDataArr.filter(vItems => {
				const vTglfilterTS 	= UFunc.toDate(vItems.tgl_filter).valueOf();
				return vTglfilterTS !== vTglsatuTS;
			})

			vDatadashboardObj.timestampTS	= undefined;
			vDatadashboardObj.data_arr 		= vFilterArr;
			localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
		}
		//--DITARUH_DIAWAL_APILOADDATA--/
	}
	//---END PROCESS--/

	//---CONTENT--/
	const contentSpinner = () => {
		return (
			<CCard className="h-100 classcardbg">
			<CCardBody className="d-flex justify-content-center align-items-center">
				<CSpinner color="secondary" className="my-3"/>
			</CCardBody>
			</CCard>
		)
	}
	const contentWidgetOngoing = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("widget_ongoing").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		const vTglfilterDT 	= UFunc.toDate(uKeywordObj.filter_dashboard || UFunc.DbDate());
		const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
		const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

 		const vValue 		= parseInt(vDataObj.jml_proses)||0;
 		const vBooking		= parseInt(vDataObj.jml_booking)||0;

 		const vPersenvalue	= vBooking <= 0 ? 0 : Math.round((vValue/vBooking)*100);

 		//console.log("(Dashboard - contentWidgetOngoing) vPersenvalue => "+vPersenvalue);

		return (
			<CWidgetStatsB onClick={()=>uNavigate("/ongoing")}
				className="h-100 classcardbg classcursorpointer"
				progress={{ color: 'success', value: vPersenvalue }}
				text={vISBulanlalu?"Pasien per Bulan":"Jml Pasien Dlm Proses"}
				title={vISBulanlalu?"Jumlah Pasien":"Ongoing Proses"} value={UFunc.formatAngka(vValue)}
				/>
		)
	}
	const contentWidgetOnline = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("widget_online").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		const vTglfilterDT 	= UFunc.toDate(uKeywordObj.filter_dashboard || UFunc.DbDate());
		const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
		const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

		return (
			<CWidgetStatsD onClick={()=>uNavigate("/user")}
				className="h-100 classcardbg classcursorpointer"
				icon={<CImage src={pjson.homepage+"api/images/menus_user.png"} className="my-2" height={52}/>}
				values={[
					{ 
						title: vISBulanlalu?"Online per Bulan":"Jml Online",
						value: vDataObj.value||0
					},
				]}
				/>
		)
	}
	const contentWidgetNote = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("widget_note").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj			= vContentObj.data_obj || {};
		const vTglfilterDT 	= UFunc.toDate(uKeywordObj.filter_dashboard || UFunc.DbDate());
		const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
		const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

		const vTglshowDT	= !vISBulanlalu ? (new Date()) : new Date(vTglfilterDT.getFullYear(),vTglfilterDT.getMonth(),1);

		return (
			<CCard className="h-100 classcardbg">
			<CCardHeader className="bg-warning classcursorpointer text-white d-flex justify-content-between"
				onClick={()=>uNavigate("/notelayanan")}>
				<b>Note Layanan</b>
				<CImage src={pjson.homepage+"api/images/menus_notelayanan.png"} height={40}/>
			</CCardHeader>
			
			<CCardBody className="py-2">
				{(vDataObj.value||"")==="" ? (
					<center><CImage src={pjson.homepage+"images/logo_empty.png"} className="my-2" height={40}/></center>
				) : (
					<small className="fst-italic">{UFunc.Usercaptionfilter((vDataObj.value || "Ini Layanan Hari Ini"),13)}...</small>
				)}
			</CCardBody>

			<CCardFooter className="bg-transparent border-top-0 classfontsmaller">
				<small className="fw-bolder">{UFunc.TglAngka(vDataObj.tgl_note || vTglshowDT)}</small>
			</CCardFooter>
			</CCard>
		)//>
	}
	const contentPielayanan = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("pie_layanan").toUpperCase())||[])[0] || {};

 		//console.log("(Dashboard - contentPielayanan) vContentObj => "+JSON.stringify(vContentObj));
		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataArr		= vContentObj.data_arr || [];
		const vTglfilterDT 	= UFunc.toDate(uKeywordObj.filter_dashboard || UFunc.DbDate());
		const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
		const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();
 		
		let vLabelXArr		= [];
		let vBgColorArr		= [];
		let vValueArr		= uLayananArr.map(vItems=>{
			const vValueidx = UFunc.getObjectIndeks(vDataArr,vItems.id, "layanan_id");

			return parseInt((vDataArr[vValueidx]||{}).jml_layanan) || 0;
		});

		const vAllZeroBl 	= vValueArr.every(value => value === 0);
		if(!vAllZeroBl) {
			vLabelXArr 	= uLayananArr.map(vItems=>vItems.caption_layanan);
			vBgColorArr	= uLayananArr.map(vItems=>"#"+(vItems.color_layanan||"DCDCDC"));
		} else {
			vLabelXArr.push("No Data");
			vBgColorArr.push("#DDDDDD");
			vValueArr.push(1);
		}

 		//console.log("(Dashboard - contentPielayanan) vValueArr => "+JSON.stringify(vValueArr));
		return (
			<CCard className="h-100 classcardbg">
			<CCardBody className="py-1 px-2">
			<div className="my-1 fw-bolder">
				<small>Jml Layanan ({vISBulanlalu?"per BLN":"Hari Ini"})</small>
			</div>
			<CChart type="doughnut" className=""
				data={{
					labels: vLabelXArr,
					datasets: [
					{
						backgroundColor: vBgColorArr,
						data: vValueArr,
					},
					],
				}}
				options={{
					plugins: {
						legend: {
							display: true,
							position: "right",
							align: "start",
							labels: {
								boxWidth: 13,
								color: getStyle('--cui-body-color'),
							}
						},
					},
					aspectRatio: 1.5,
					layout: {
						padding: {
							left:1,top: 0,right: 1,bottom: 0,
						}
					},
				}}
			/>
			</CCardBody>
			</CCard>
		)
	}
	const contentGrafikomzet = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("grafik_omzet").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj 	= vContentObj.data_obj;
		if(!vDataObj) return "";

		/*vLabelXArr	= ["2023-02-01","2023-02-02","2023-02-03","2023-02-04","2023-02-05",
			"2023-02-06","2023-02-07","2023-02-08","2023-02-09","2023-02-01"];
		vValueArr	= [3000000,1000000,5000000,6000000,2000000,7000000,4000000,10000000,7000000,9000000];
		vRataArr 	= [5200000,5200000,5200000,5200000,5200000,5200000,5200000,5200000,5200000,5200000]
		//-*/

		//console.log("(Dashboard - contentGrafikomzet) vDataObj.label_arr = "+JSON.stringify(vDataObj.label_arr||[]))
		return(
			<CChart type="line" ref={uChartlineRef}
				data={{
					labels: vDataObj.label_arr || [],
					datasets: [
					{
						label: uBahasaObj.word_pendapatan||"Omzet",
						borderColor: getStyle('--cui-primary'),
						backgroundColor: hexToRgba(getStyle('--cui-primary'), 5),
						pointHoverBackgroundColor: getStyle('--cui-primary'),
			        	borderWidth: 2,
			        	fill: true,
						data: vDataObj.value_arr || [],						
					},
					{
						label: uBahasaObj.caption_rata || "Rata2",// obBahasa.word_barang||"Barang",
						borderColor: getStyle("--cui-danger"),
						pointHoverBackgroundColor: getStyle("--cui-danger"),
						backgroundColor: getStyle("--cui-danger"),
			        	borderWidth: 1,
			        	fill: false,
						borderDash: [8, 5],
						data: vDataObj.rata_arr || [],
					}],
				}}
				options={{
					animation : false,
					maintainAspectRatio: true,
					plugins: {
						legend: { display: false },
						tooltip:{
							callbacks: {
								intersect: false,
								mode: 'index',
								title: (tooltipItem) => {
									return UFunc.HariAngka(UFunc.DbDate(new Date(parseInt(tooltipItem[0].label))));
									//return UFunc.HariAngka(tooltipItem[0].label);
								},
								//-*/
								label: (tooltipItem) => {									
									//-->tooltipItem.dataset={"label":"Pendapatan","borderColor":"#321fdb","backgroundColor":"rgba(50, 31, 219, 0.05)","pointHoverBackgroundColor":"#321fdb","borderWidth":2,"fill":true,"data":["1250000","3500000","2500000","1500000","4500000","7500000","4500000","2500000","0","6500000","1500000","6500000","3500000"]}
									//console.log("label.tooltipItem = "+JSON.stringify(tooltipItem.dataset));//-*/

									let vlabels = tooltipItem.dataset.label || '';
			                        if (vlabels) vlabels += ': ';
			                        if (tooltipItem.value !== null) {
			                        	vlabels += "Rp"+UFunc.formatAngka(tooltipItem.parsed.y);
			                        }//-*/

									return vlabels;
								},
							},
						},
					},
					elements: {
						line: {
							tension: 0.4,
						},
						point: {
							radius: 0,
							hitRadius: 10,
							hoverRadius: 4,
							hoverBorderWidth: 3
						}
					},
					scales: {
						y: {
							ticks : {
								beginAtZero: true,
								maxTicksLimit: 5,
								stepSize: Math.ceil(250 / 5),
								max: 250,
								callback: (label, index, labels) => {
									return UFunc.formatAngka(label/1000000)+'M'
								},
							},
							scaleLabel: {
								display:true,
							},
						},
						x:{
							ticks : {
								callback: (label, index, labels) => {
									//console.log("vDataObj.label_arr || [] = "+JSON.stringify(vDataObj.label_arr || []))
									//const vTmptglDT = new Date(value);
									//return UFunc.TglAngka(UFunc.DbDate(vTmptglDT));
									return UFunc.TglAngka( UFunc.DbDate( new Date(parseInt((vDataObj.label_arr || [])[index]))) ).substr(0,5);

									//return UFunc.TglAngka((vDataObj.label_arr||[])[index]).substr(0,5)
								},
							},//-*/
							grid: { drawOnChartArea: false }
						},
					},
					layout: {
						padding: {
							bottom: 1
						}
					},
				}}
			id="idgrafikomzet"/>
		)//>
	}
	const contentWidgetOmzetbulan = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("grafik_omzet").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return "";

		const vDataObj	= vContentObj.data_obj || {};
		const vTotals	= vDataObj.totals || 0;// vDataArr.reduce((vSUMs,vItems)=>vSUMs+(parseFloat(vItems.nilai_omzet)||0),0)

		return (
			<CWidgetStatsB
				className="my-3 mt-md-0"
				color="primary"
				inverse
				progress={{ value: 75 }}
				text="" title="Omzet per Bulan" value={UFunc.formatAngka(vTotals)}
				/>
		)
	}
	const contentPieOmzetAkuntansi = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("pie_omzet").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj 	= vContentObj.data_obj || {};

		return (
		<CCard className="mb-3 classcardbg">
		<CCardBody className="py-1 px-2">
			<div className="fw-bolder my-2 px-2">
				<Link className="text-decoration-none text-black" to="/sublapkeuangan/laplabarugi">
				<small>Top Omzet (Akuntansi)</small>
				</Link>
			</div>
			<CChart type="doughnut" className=""
				data={{
					labels: vDataObj.labels || [],
					datasets: [
					{
						backgroundColor: vDataObj.bgColor || [],
						data: vDataObj.value || [],
					},
					],
				}}
				options={{
					plugins: {
						legend: {
							display: true,
							position: "right",
							align: "start",
							labels: {
								boxWidth: 13,
								color: getStyle('--cui-body-color'),
							}
						},
					},
					aspectRatio: 1.5,
					layout: {
						padding: {
							left:1,top:0,right:1,bottom: 0,
						}
					},
				}}
			/>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentCardHutang = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("card_hutang").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataArr		= vContentObj.data_arr || [1];

		return (
			<CCard className="h-100 classcardbg">
				<CCardHeader className="d-flex justify-content-between classfontsmaller">
					<small className="fw-bolder text-primary">Top 5 Hutang Supplier</small>
					<Link className="text-decoration-none text-muted" 
						to="/sublapoperasi/laphutang">
						<small>Lihat Detil<CIcon icon="cilArrowRight" className="align-bottom"/></small>
					</Link>
				</CCardHeader>
				
				<CCardBody>
				{vDataArr.length <= 0 ? (
					<div className="d-flex align-items-center justify-content-center h-100">
					<div align="center">
						<CImage src={pjson.homepage+"images/logo_empty.png"} 
							className="border p-2 rounded-3 bg-light" height={70}/>
						<div className="mt-2 text-muted fst-italic classfontsmaller">
						&middot;&middot; Data Empty &middot;&middot;
						</div>
					</div>
					</div>
				) : (
				<>
				{vDataArr.map((vItems,vKeys)=>{
					return (
					<div className="progress-group" key={vKeys}>
					<div className="progress-group-header">
						<CImage src={pjson.homepage+"api/images/menus_supplier.png"} className="me-1" height={20}/>
						<span className="fst-italic">{UFunc.Usercaptionfilter((vItems.nama_supplier||""),4)}</span>
						<span className="ms-auto fw-semibold classfontsmaller">
							<span className="text-primary">{UFunc.formatAngka(Math.round((parseFloat(vItems.sisa_hutang)||0)/1000))}K</span>
							<span className="ms-1 text-medium-emphasis"><small>({Math.round(vItems.persen_hutang)}%)</small></span>
						</span>
					</div>

					<div className="progress-group-bars">
						<CProgress thin color="primary" variant="striped" value={(parseFloat(vItems.persen_hutang)||0)}/>
					</div>
					</div>
					);
				})}
				</>
				)}
				</CCardBody>
			</CCard>
		)//>
	}
	const contentCardPiutang = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("card_piutang").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataArr		= vContentObj.data_arr || [1];

		return (
			<CCard className="h-100 classcardbg">
				<CCardHeader className="d-flex justify-content-between classfontsmaller">
					<small className="fw-bolder text-success">Top 5 Piutang Customer</small>
					<Link className="text-decoration-none text-muted" 
						to="/sublapoperasi/lappiutang">
						<small>Lihat Detil<CIcon icon="cilArrowRight" className="align-bottom"/></small>
					</Link>
				</CCardHeader>
				
				<CCardBody>
				{vDataArr.length <= 0 ? (
					<div className="d-flex align-items-center justify-content-center h-100">
					<div align="center">
						<CImage src={pjson.homepage+"images/logo_empty.png"} 
							className="border p-2 rounded-3 bg-light" height={70}/>
						<div className="mt-2 text-muted fst-italic classfontsmaller">
						&middot;&middot; Data Empty &middot;&middot;
						</div>
					</div>
					</div>
				) : (
				<>
				{vDataArr.map((vItems,vKeys)=>{
					return (
					<div className="progress-group" key={vKeys}>
					<div className="progress-group-header">
						<CImage src={pjson.homepage+"api/images/menus_customer.png"} className="me-1" height={20}/>
						<span className="fst-italic">{UFunc.Usercaptionfilter((vItems.nama_customer||"Undf"),4)}</span>
						<span className="ms-auto fw-semibold classfontsmaller">
							<span className="text-success">{UFunc.formatAngka(Math.round(parseFloat(vItems.sisa_piutang)/1000))}K</span>
							<span className="ms-1 text-medium-emphasis"><small>({Math.round(parseFloat(vItems.persen_piutang))}%)</small></span>
						</span>
					</div>

					<div className="progress-group-bars">
						<CProgress thin color="success" variant="striped" value={parseFloat(vItems.persen_piutang)||0}/>
					</div>
					</div>
					)
				})}
				</>
				)}
				</CCardBody>
			</CCard>
		)//>
	}
	const contentCardBiaya = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("card_biaya").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataArr		= vContentObj.data_arr || [1];

		return (
		<CCard className="h-100 classcardbg">
			<CCardHeader className="d-flex justify-content-between classfontsmaller">
				<small className="fw-bolder text-danger">TOP 5 POS BIAYA</small>
				<Link className="text-decoration-none text-muted" to="/sublapoperasi/lapbiaya">
					<small>Lihat Detil<CIcon icon="cilArrowRight" className="align-bottom"/></small>
				</Link>
			</CCardHeader>
			
			<CCardBody>
				{vDataArr.length <= 0 ? (
					<div className="d-flex align-items-center justify-content-center h-100">
					<div align="center">
						<CImage src={pjson.homepage+"images/logo_empty.png"} 
							className="border p-2 rounded-3 bg-light" height={70}/>
						<div className="mt-2 text-muted fst-italic classfontsmaller">
						&middot;&middot; Data Empty &middot;&middot;
						</div>
					</div>
					</div>
				) : (
				<>
				{vDataArr.map((vItems,vKeys)=>{
					return (
					<div className="progress-group" key={vKeys}>
					<div className="progress-group-header">
						<CImage src={pjson.homepage+"api/images/menus_biaya.png"} className="me-1" height={20}/>
						<span className="fst-italic">{UFunc.Usercaptionfilter((vItems.nama_biaya||"Undf"),4)}</span>
						<span className="ms-auto fw-semibold classfontsmaller">
							<span className="text-danger">{UFunc.formatAngka(Math.round((parseFloat(vItems.nilai_biaya)||0)/1000))}K</span>
							<span className="ms-1 text-medium-emphasis"><small>({Math.round(parseFloat(vItems.persen_biaya)||0)}%)</small></span>
						</span>
					</div>

					<div className="progress-group-bars">
						<CProgress thin color="danger" variant="striped" value={(parseFloat(vItems.persen_biaya)||0)}/>
					</div>
					</div>
					)
				})}
				</>
				)}
			</CCardBody>
		</CCard>
		)//>
	}
	const contentCardKas = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("card_kas").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataArr		= vContentObj.data_arr || [];

		return (
			<CCard className="h-100 classcardbg">
				<CCardHeader className="d-flex justify-content-between classfontsmaller">
					<small className="fw-bolder">Saldo KAS Akuntansi</small>
					<Link className="text-decoration-none text-muted"
						to="/sublapkeuangan/lapbukubesar">
						<small>Lihat Detil<CIcon icon="cilArrowRight" className="align-bottom"/></small>
					</Link>
				</CCardHeader>
				
				<CCardBody>
				{vDataArr.map((vItems,vKeys)=>{
					return (
					<div key={vKeys} className="p-2 px-0 classborderbottom d-flex justify-content-between">
						<small>{vItems.caption_kas||"Undf"}</small>
						<CBadge style={{width:110}} className="text-end" color={(parseFloat(vItems.nilai_kas)||0) < 0 ? "danger" : "info"}>
						{(parseFloat(vItems.nilai_kas)||0) < 0 ? "- " : ""}
						Rp{UFunc.formatAngka(Math.abs(parseFloat(vItems.nilai_kas)||0))}
						</CBadge>
					</div>
					)
				})}
				</CCardBody>
			</CCard>
		)//>
	}
	const contentPieBarang = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("pie_barang").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj 	= vContentObj.data_obj || {};

		return (
		<CCard className="h-100 classcardbg">	
			<CCardBody className="py-0">
			<div className="fw-bolder my-2"><small>TOP JML Sales Barang</small></div>
			<CChart type="doughnut" className=""
				data={{
					labels: vDataObj.labels || [],
					datasets: [
					{
						backgroundColor: vDataObj.bgColor || [],
						data: vDataObj.value || [],
					},
					],
				}}
				options={{
					plugins: {
						legend: {
							display: true,
							position: "right",
							align: "start",
							labels: {
								boxWidth: 13,
								color: getStyle('--cui-body-color'),
							}
						},
					},
					aspectRatio: 1.5,
					layout: {
						padding: {
							left:1,top: 1,right: 1,bottom: 1,
						}
					},
				}}
			/>
			</CCardBody>
		</CCard>
		)//>
	}
	const contentPieItems = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("pie_items").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj 	= vContentObj.data_obj || {};

		return (
		<CCard className="h-100 classcardbg">
			<CCardBody className="py-0">
			<div className="fw-bolder my-2"><small>TOP Revenue Sales Item</small></div>
			<CChart type="doughnut" className=""
				data={{
					labels: vDataObj.labels || [],
					datasets: [
					{
						backgroundColor: vDataObj.bgColor || [],
						data: vDataObj.value || [],
					},
					],
				}}
				options={{
					plugins: {
						legend: {
							display: true,
							position: "right",
							align: "start",
							labels: {
								boxWidth: 13,
								color: getStyle('--cui-body-color'),
							}
						},
					},
					aspectRatio: 1.5,
					layout: {
						padding: {
							left:1,top: 1,right: 1,bottom: 1,
						}
					},
				}}
			/>
			</CCardBody>
		</CCard>
		)//>
	}

	const contentWidgetReminder = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("widget_reminder").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		const vTglfilterDT 	= UFunc.toDate(uKeywordObj.filter_dashboard || UFunc.DbDate());
		const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
		const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

		return (
		<CCard className="h-100 classcardbg">
			<CCardHeader onClick={()=>uNavigate("/reminder")} className="bg-info text-white d-flex justify-content-between align-items-center classcursorpointer">
				<div>
				<big className="fw-bolder fs-1">{UFunc.formatAngka(vDataObj.jml_total)}</big>
				<span className="classfontsmaller">
					<small>Reminders</small>
				</span>
				</div>

				<CImage src={pjson.homepage+"api/images/menus_reminder.png"} height={40}/>
			</CCardHeader>
			<CCardBody className="px-2">
				{(!vISBulanlalu && (vDataObj.nama_pasien||"")!=="") && (
				<>
				<div className="fw-bolder text-info">{vDataObj.nama_pasien||"Undf"}</div>
				<div className="fst-italic classfontsmaller">
					<small>{UFunc.renderHTML(vDataObj.note_reminder||"Undf")}</small>
				</div>
				</>
				)}
			</CCardBody>

			<CCardFooter className="bg-transparent border-top-0 classfontsmaller text-end">
				<Link className="text-decoration-none text-muted fw-bolder" to="/reminder">
				<small>+More...</small>
				</Link>
			</CCardFooter>
		</CCard>
		)//>
	}
	const contentPieKasir = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("pie_kasir").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj 		= vContentObj.data_obj || {};
		const vTglfilterDT 	= UFunc.toDate(uKeywordObj.filter_dashboard || UFunc.DbDate());
		const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
		const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

		return (
		<CCard className="h-100 classcardbg">
			<CCardBody className="py-0">
			<div className="fw-bolder my-2">
				<Link to="/copynota" className="text-decoration-none text-black">
				<small>Penerimaan Kasir {vISBulanlalu ? "(per BLN)":"(Hari Ini)"}</small>
				</Link>
			</div>
			
			<CChart type="doughnut" className=""
				data={{
					labels: vDataObj.labels || [],
					datasets: [
					{
						backgroundColor: vDataObj.bgColor || [],
						data: vDataObj.value || [],
					},
					],
				}}
				options={{
					plugins: {
						legend: {
							display: true,
							position: "right",
							align: "start",
							labels: {
								boxWidth: 13,
								color: getStyle('--cui-body-color'),
							}
						},
					},
					aspectRatio: 1.5,
					layout: {
						padding: {
							left:10,top: 1,right: 10,bottom: 1,
						}
					},
				}}
			/>
			</CCardBody>
		</CCard>
		)//>
	}
	const contentGrafikpasien = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("grafik_pasien").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj 	= vContentObj.data_obj;
		if(!vDataObj) return "";

		/*vLabelXArr	= ["2023-02-01","2023-02-02","2023-02-03","2023-02-04","2023-02-05",
			"2023-02-06","2023-02-07","2023-02-08","2023-02-09","2023-02-01"];
		vValueArr	= [20,14,37,28,20,7,23,15,44,24];
		vRataArr 	= [25,25,25,25,25,25,25,25,25,25]
		//-*/

		return(
			<CChart type="line" className="h-100"
				data={{
					labels: vDataObj.label_arr || [],
					datasets: [
					{
						label: uBahasaObj.word_jumlah||"Jumlah",
						borderColor: getStyle('--cui-primary'),
						backgroundColor: hexToRgba(getStyle('--cui-primary'), 5),
						pointHoverBackgroundColor: getStyle('--cui-primary'),
			        	borderWidth: 2,
			        	fill: true,
						data: vDataObj.value_arr || [],						
					},
					{
						label: uBahasaObj.caption_rata || "Rata2",// obBahasa.word_barang||"Barang",
						borderColor: getStyle("--cui-danger"),
						pointHoverBackgroundColor: getStyle("--cui-danger"),
						backgroundColor: getStyle("--cui-danger"),
			        	borderWidth: 1,
			        	fill: false,
						borderDash: [8, 5],
						data: vDataObj.rata_arr || [],
					}],
				}}
				options={{
					animation : false,
					maintainAspectRatio: true,
					plugins: {
						legend: { display: false },
						tooltip:{
							callbacks: {
								intersect: false,
								mode: 'index',
								title: (tooltipItem) => {
									return UFunc.HariAngka(tooltipItem[0].label);
								},
								label: (tooltipItem) => {									
									//-->tooltipItem.dataset={"label":"Pendapatan","borderColor":"#321fdb","backgroundColor":"rgba(50, 31, 219, 0.05)","pointHoverBackgroundColor":"#321fdb","borderWidth":2,"fill":true,"data":["1250000","3500000","2500000","1500000","4500000","7500000","4500000","2500000","0","6500000","1500000","6500000","3500000"]}
									//console.log("label.tooltipItem = "+JSON.stringify(tooltipItem.dataset));//-*/

									let vlabels = tooltipItem.dataset.label || '';
			                        if (vlabels) vlabels += ': ';
			                        if (tooltipItem.value !== null) {
			                        	vlabels += UFunc.formatAngka(tooltipItem.parsed.y);
			                        }//-*/

									return vlabels;
								},
							},
						},
					},
					elements: {
						line: {
							tension: 0.4,
						},
						point: {
							radius: 0,
							hitRadius: 10,
							hoverRadius: 4,
							hoverBorderWidth: 3
						}
					},
					scales: {
						y: {
							ticks : {
								beginAtZero: true,
								maxTicksLimit: 5,
								stepSize: Math.ceil(70 / 5),
								max: 70,
								/*callback: (label, index, labels) => {
									return label//UFunc.formatAngka(label/1000000)+'M'
								},//-*/
							},
							scaleLabel: {
								display:true,
							},
						},
						x:{
							ticks : {
								callback: (label, index, labels) => {
									//console.log("labels = "+JSON.stringify(labels))
									//console.log("labels = "+labels)
									//return JSON.stringify(labels);//[index];
									return UFunc.TglAngka((vDataObj.label_arr||[])[index]).substr(0,5)
								},
							},
							grid: { drawOnChartArea: false }
						},
					},
					layout: {
						padding: {
							bottom: 1,top:1,
						}
					},
				}}
			id="idgrafikomzet"/>
		)//>
	}
	const contentWidgetpasienharian = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("grafik_pasien").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return "";

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classcardbg">
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		const vLabelXArr	= vDataObj.label_arr || [];
		const vDataArr		= vDataObj.value_arr || [];
		const vTglfilterDT 	= UFunc.toDate(uKeywordObj.filter_dashboard || UFunc.DbDate());
		const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
		const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();
		
		/*console.log("vDataObj => "+JSON.stringify(vDataObj));
		console.log("vDataArr => "+JSON.stringify(vDataArr));//-*/

		let vTitle;
		let vValue;
		if(vISBulanlalu) {
			const vTotals 	= parseInt(vDataObj.totals) || 0;
			vValue 	= vDataArr.length > 0 ? Math.round(vTotals / vDataArr.length) : 0;
			vTitle 	= "Rata2 Pasien Harian";
		} else {
			const vTmpIdx		= vLabelXArr.indexOf(UFunc.DbDate());
			vTitle 	= "Pasien Hari Ini";
			vValue 	= vDataArr[vTmpIdx] || 0;
		}

		return (
			<CWidgetStatsD
				className="classcardbg my-3 mt-md-0"
				icon={<CImage src={pjson.homepage+"api/images/menus_pasien.png"} height={52}/>}
				values={[
					{ 
						title: vTitle,
						value: vValue
					},
				]}
				/>
		)
	}
	const contentWidgetpasienmost = () => {
		if(uHTMLError500) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("grafik_pasien").toUpperCase())||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner();

		const vDataObj	= vContentObj.data_obj || {};
		const vLabelXArr= vDataObj.label_arr || [];
		const vDataArr	= vDataObj.value_arr || [];

		let vStoredJml	= 0;
		let vStoredTgl	= UFunc.DbDate();
		vDataArr.map((vItems,vKeys)=>{
			if((parseInt(vItems)||0) > vStoredJml) {
				vStoredJml = parseInt(vItems)||0;
				vStoredTgl = vLabelXArr[vKeys];
			}
		})
		const vTotals		= vDataObj.totals ||0;
		const vPersenvalue 	= vTotals > 0 ? (vStoredJml / vTotals)*100 : 0;

		return (
			<CWidgetStatsB
				className="my-3 mt-md-0"
				color="primary"
				inverse
				progress={{ value: vPersenvalue }}
				text={"Hari: "+UFunc.HariAngka(vStoredTgl)} 
				title="Pasien Terbanyak" value={UFunc.formatAngka(vStoredJml)}
				/>
		)
	}
	//---END CONTENT--/

	//---API--/
	const apiLoadongoing = (_HEADEROBJ) => {
		const vNamavars	= "widget_ongoing";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");

		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length -1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vTglfilter= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadongoing) JamMenitDetik : "+UFunc.JamMenitDetik());
			//console.log("(Dashboard - apiLoadonline) _OUTPUTOBJ 2 : "+JSON.stringify(_OUTPUTOBJ));
			if(_OUTPUTOBJ.tampil) { 
				uDatamainArr[vArridx].data_obj		= JSON.parse(_OUTPUTOBJ.dataobjek_str || "{}");

				const vTglfilterDT 	= UFunc.toDate(vTglfilter);
				const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
				const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

				if(vISBulanlalu) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadongoing) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2);
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				onResolve(
					vFunctionNext(vDataArr[vDataidx].output_string || {})
				)
				return;
			}

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			//console.log("(Dashboard - apiLoadonline) uDatamainArr : "+JSON.stringify(uDatamainArr));
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_ongoing";
			const vHeaderObj= _HEADEROBJ || {};
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) return response.json(); }
				).then((output_string) => {
					//console.log("(Dashboard - apiLoadongoing) output_string : "+JSON.stringify(output_string));
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})/*.catch((error1)=>{
					uDatamainArr[vArridx].is_loading = false;
					if(error1) console.log("(Dashboard - apiLoadongoing) catch-error: "+error1);
					onReject(error1);
				})//-*/
			)
		})//-*/
	}
	const apiLoadonline = (_HEADEROBJ) => {
		const vNamavars	= "widget_online";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length -1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vTglfilter= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadonline) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				uDatamainArr[vArridx].data_obj		= JSON.parse(_OUTPUTOBJ.dataobjek_str || "{}");
				setEfekview2(!uEfekview2)

				const vTglfilterDT 	= UFunc.toDate(vTglfilter);
				const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
				const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

				if(vISBulanlalu) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadonline) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				onResolve(
					vFunctionNext(vDataArr[vDataidx].output_string || {})
				)
				return;
			}

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			setEfekview(!uEfekview);
			//console.log("(Dashboard - apiLoadonline) uDatamainArr : "+JSON.stringify(uDatamainArr));
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_online";
			const vHeaderObj= _HEADEROBJ || {};
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) return response.json(); }
				).then((output_string) => {
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
		})//-*/
	}
	const apiLoadnote = (_HEADEROBJ) => {
		const vNamavars	= "widget_note";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length -1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vTglfilter= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/
		const vTglfilterDT 	= UFunc.toDate(vTglfilter);
		const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
		const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadnote) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				uDatamainArr[vArridx].data_obj		= JSON.parse(_OUTPUTOBJ.dataobjek_str || "{}");
				setEfekview2(!uEfekview2)

				if(!vISBulanlalu) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadnote) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0 || vISBulanlalu) {
				onResolve(
					vFunctionNext((vDataArr[vDataidx]||{}).output_string || {})
				)
				return;
			}

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			setEfekview(!uEfekview);
			//console.log("(Dashboard - apiLoadnote) uDatamainArr : "+JSON.stringify(uDatamainArr));
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_notelayanan";
			const vHeaderObj= _HEADEROBJ || {};
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) return response.json(); }
				).then((output_string) => {
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
		})//-*/
	}
	const apiLoadlayanan = (_HEADEROBJ) => {
		const vNamavars	= "pie_layanan";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_arr = undefined;
		//console.log("(Dashboard - apiLoadlayanan) uDatamainArr[vArridx] : "+JSON.stringify(uDatamainArr[vArridx]||{}));

		//--CEK_DATA_LOCALSTORAGE--/
		const vTglfilter= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadlayanan) JamMenitDetik : "+UFunc.JamMenitDetik());
			//console.log("(Dashboard - apiLoadlayanan) _OUTPUTOBJ 2 : "+JSON.stringify(_OUTPUTOBJ));
			if(_OUTPUTOBJ.tampil) { 
				const vTmpArr	= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				uDatamainArr[vArridx].data_arr 	= vTmpArr;

				const vTglfilterDT 	= UFunc.toDate(vTglfilter);
				const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
				const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

				if(vTmpArr.length > 0 && vISBulanlalu) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}//-*/
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadonline) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2)
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				onResolve(
					vFunctionNext(vDataArr[vDataidx].output_string || {})
				)
				return;
			}

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_layanan";
			const vHeaderObj= _HEADEROBJ || {};
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) return response.json(); }
				).then((output_string) => {
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
		})
	}
	const apiLoadGrafikomzet = (_HEADEROBJ) => {
		const vNamavars	= "grafik_omzet";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vTglfilter 		= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			//console.log("(Dashboard - apiLoadGrafikomzet) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadGrafikomzet) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vAllZeroBl 	= vTmpArr.every(vItems => (parseFloat(vItems.nilai_omzet)||0) === 0);

				const vTmpTotals 	= vTmpArr.reduce((vSUM,vItems)=>vSUM + (parseFloat(vItems.nilai_omzet)||0),0);
				const vTmpRata2 	= Math.round(vTmpTotals / (parseInt(vTmpArr.length)||1));
				
				const vLabelXArr	= vTmpArr.map(vItems=> UFunc.toDate(vItems.tanggal).valueOf() );
				const vTmpValueArr	= vTmpArr.map(vItems=> (parseFloat(vItems.nilai_omzet)||0));
				const vTmpRataArr	= vTmpArr.map(vItems=> vTmpRata2);

				const vDatatmpObj	= {
					label_arr: vLabelXArr || [],
					value_arr: vTmpValueArr || [],
					rata_arr: vTmpRataArr || [],
					totals: vTmpTotals,
				}
				const vTimeout	= setTimeout(()=>{
					clearTimeout(vTimeout);

					uDatamainArr[vArridx].data_obj = vDatatmpObj;
					setEfekview2(!uEfekview2);
				},100);

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}//-*/
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadGrafikomzet) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				onResolve(
					vFunctionNext(vDataArr[vDataidx].output_string || {})
				)
				return;
			}
			//console.log("(Dashboard - apiLoadGrafikomzet) vArridx : "+(vArridx));

			uDatamainArr[vArridx].data_obj 		= undefined;
			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_omzet";
			const vHeaderObj= _HEADEROBJ || {};
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) return response.json(); }
				).then((output_string) => {
					//console.log("(Dashboard - apiLoadGrafikomzet) output_string : "+JSON.stringify(output_string));
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
		})//-*/
	}
	const apiLoadPieomzet = (_HEADEROBJ) => {
		const vNamavars	= "pie_omzet";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_arr = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vTglfilter= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadPieomzet) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadPieomzet) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vAllZeroBl 	= vTmpArr.every(vItems => (parseFloat(vItems.nilai_omzet)||0) === 0);
				//uDatamainArr[vArridx].data_arr = vTmpArr;

				if(!vAllZeroBl) {
					const vLabelXArr	= vTmpArr.map(vItems=>vItems.caption_omzet);
					const vBgColorArr	= vTmpArr.map(vItems=>UFunc.getRandomColor());
					const vValueArr		= vTmpArr.map(vItems=>(parseFloat(vItems.nilai_omzet)||0));
					uDatamainArr[vArridx].data_obj = {
						labels: vLabelXArr,
						bgColor: vBgColorArr,
						value: vValueArr,
					}

					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				} else {
					uDatamainArr[vArridx].data_obj = {
						labels: ["No Data"],
						bgColor: ["#DDDDDD"],
						value: [1],
					}
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadPieomzet) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2)
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				onResolve(
					vFunctionNext(vDataArr[vDataidx].output_string || {})
				)
				return;
			}
			//console.log("(Dashboard - apiLoadGrafikomzet) vArridx : "+(vArridx));

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_omzetpie";
			const vHeaderObj= _HEADEROBJ || {};
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) return response.json(); }
				).then((output_string) => {
					//console.log("(Dashboard - apiLoadGrafikomzet) output_string : "+JSON.stringify(output_string));
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
		})//-*/
	}
	const apiLoadreminder = (_HEADEROBJ) => {
		const vNamavars	= "widget_reminder";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length -1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vTglfilter= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/
		const vTglfilterDT 	= UFunc.toDate(vTglfilter);
		const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
		const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadreminder) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				uDatamainArr[vArridx].data_obj		= JSON.parse(_OUTPUTOBJ.dataobjek_str || "{}");

				if(vDataidx < 0) {
					vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
					vDataArr			= vDatadashboardObj.data_arr || [];
					const vTmpObj 	= {
						tgl_filter: vTglfilter,
						nama_vars: vNamavars,
						output_string: (_OUTPUTOBJ),
					}
					vDataArr.push(vTmpObj);
				} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

				vDatadashboardObj.data_arr = vDataArr;
				localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadreminder) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2)
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				onResolve(
					vFunctionNext((vDataArr[vDataidx]||{}).output_string || {})
				)
				return;
			}

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			setEfekview(!uEfekview);
			//console.log("(Dashboard - apiLoadreminder) uDatamainArr : "+JSON.stringify(uDatamainArr));
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_reminder";
			const vHeaderObj= _HEADEROBJ || {};
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) return response.json(); }
				).then((output_string) => {
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
		})//-*/
	}
	const apiLoadhutang = (_HEADEROBJ) => {
		const vNamavars	= "card_hutang";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_arr = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vTglfilter= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadhutang) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadhutang) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vAllZeroBl 	= vTmpArr.every(vItems => (parseFloat(vItems.sisa_hutang)||0) === 0);
				
				uDatamainArr[vArridx].data_arr = vTmpArr;

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}//-*/
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadhutang) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2)
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				onResolve(
					vFunctionNext(vDataArr[vDataidx].output_string || {})
				)
				return;
			}
			//console.log("(Dashboard - apiLoadhutang) vArridx : "+(vArridx));

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_hutang";
			const vHeaderObj= _HEADEROBJ || {};
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) return response.json(); }
				).then((output_string) => {
					//console.log("(Dashboard - apiLoadhutang) output_string : "+JSON.stringify(output_string));
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
		})//-*/
	}
	const apiLoadpiutang = (_HEADEROBJ) => {
		const vNamavars	= "card_piutang";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_arr = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vTglfilter= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadpiutang) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadpiutang) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vAllZeroBl 	= vTmpArr.every(vItems => (parseFloat(vItems.sisa_piutang)||0) === 0);
				
				uDatamainArr[vArridx].data_arr = vTmpArr;

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}//-*/
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadpiutang) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2)
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				onResolve(
					vFunctionNext(vDataArr[vDataidx].output_string || {})
				)
				return;
			}
			//console.log("(Dashboard - apiLoadpiutang) vArridx : "+(vArridx));

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_piutang";
			const vHeaderObj= _HEADEROBJ || {};
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) return response.json(); }
				).then((output_string) => {
					//console.log("(Dashboard - apiLoadpiutang) output_string : "+JSON.stringify(output_string));
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
		})//-*/
	}
	const apiLoadbiaya = (_HEADEROBJ) => {
		const vNamavars	= "card_biaya";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_arr = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vTglfilter= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadbiaya) JamMenitDetik : "+UFunc.JamMenitDetik());
			console.log("(Dashboard - apiLoadbiaya) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadbiaya) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vAllZeroBl 	= vTmpArr.every(vItems => (parseFloat(vItems.nilai_biaya)||0) === 0);
				
				uDatamainArr[vArridx].data_arr = vTmpArr;

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}//-*/
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadbiaya) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2)
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				onResolve(
					vFunctionNext(vDataArr[vDataidx].output_string || {})
				)
				return;
			}
			//console.log("(Dashboard - apiLoadbiaya) vArridx : "+(vArridx));

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_biaya";
			const vHeaderObj= _HEADEROBJ || {};
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) return response.json(); }
				).then((output_string) => {
					//console.log("(Dashboard - apiLoadbiaya) output_string : "+JSON.stringify(output_string));
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
		})//-*/
	}
	const apiLoadkas = (_HEADEROBJ) => {
		const vNamavars	= "card_kas";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_arr = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vTglfilter= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadkas) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadkas) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vAllZeroBl 	= vTmpArr.every(vItems => (parseFloat(vItems.nilai_kas)||0) === 0);
				
				uDatamainArr[vArridx].data_arr = vTmpArr;

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}//-*/
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadkas) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2)
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				onResolve(
					vFunctionNext(vDataArr[vDataidx].output_string || {})
				)
				return;
			}
			//console.log("(Dashboard - apiLoadkas) vArridx : "+(vArridx));

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_kas";
			const vHeaderObj= _HEADEROBJ || {};
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) return response.json(); }
				).then((output_string) => {
					//console.log("(Dashboard - apiLoadkas) output_string : "+JSON.stringify(output_string));
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
		})//-*/
	}
	const apiLoadpiebarang = (_HEADEROBJ) => {
		const vNamavars	= "pie_barang";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_arr = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vTglfilter= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadpiebarang) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadpiebarang) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vAllZeroBl 	= vTmpArr.every(vItems => (parseInt(vItems.jml_barang)||0) === 0);

				if(!vAllZeroBl) {
					const vLabelXArr	= vTmpArr.map(vItems=>vItems.caption_barang);
					const vBgColorArr	= vTmpArr.map(vItems=>UFunc.getRandomColor());
					const vValueArr		= vTmpArr.map(vItems=>(parseInt(vItems.jml_barang)||0));
					uDatamainArr[vArridx].data_obj = {
						labels: vLabelXArr,
						bgColor: vBgColorArr,
						value: vValueArr,
					}

					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				} else {
					uDatamainArr[vArridx].data_obj = {
						labels: ["No Data"],
						bgColor: ["#DDDDDD"],
						value: [1],
					}
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadpiebarang) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2)
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				onResolve(
					vFunctionNext(vDataArr[vDataidx].output_string || {})
				)
				return;
			}
			//console.log("(Dashboard - apiLoadpiebarang) vArridx : "+(vArridx));

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_barangpie";
			const vHeaderObj= _HEADEROBJ || {};
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) return response.json(); }
				).then((output_string) => {
					//console.log("(Dashboard - apiLoadpiebarang) output_string : "+JSON.stringify(output_string));
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
		})//-*/
	}
	const apiLoadpieitems = (_HEADEROBJ) => {
		const vNamavars	= "pie_items";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_arr = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vTglfilter= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadpieitems) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadpieitems) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vAllZeroBl 	= vTmpArr.every(vItems => (parseInt(vItems.nilai_items)||0) === 0);

				if(!vAllZeroBl) {
					const vLabelXArr	= vTmpArr.map(vItems=>vItems.caption_items);
					const vBgColorArr	= vTmpArr.map(vItems=>UFunc.getRandomColor());
					const vValueArr		= vTmpArr.map(vItems=>(parseInt(vItems.nilai_items)||0));
					uDatamainArr[vArridx].data_obj = {
						labels: vLabelXArr,
						bgColor: vBgColorArr,
						value: vValueArr,
					}

					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
					//-*/
				} else {
					uDatamainArr[vArridx].data_obj = {
						labels: ["No Data"],
						bgColor: ["#DDDDDD"],
						value: [1],
					}
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadpieitems) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2)
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				onResolve(
					vFunctionNext(vDataArr[vDataidx].output_string || {})
				)
				return;
			}
			//console.log("(Dashboard - apiLoadpieitems) vArridx : "+(vArridx));

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_itemspie";
			const vHeaderObj= _HEADEROBJ || {};
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) return response.json(); }
				).then((output_string) => {
					//console.log("(Dashboard - apiLoadpieitems) output_string : "+JSON.stringify(output_string));
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
		})//-*/
	}
	const apiLoadpiekasir = (_HEADEROBJ) => {
		const vNamavars	= "pie_kasir";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_arr = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vTglfilter= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadpiekasir) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadpiekasir) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vAllZeroBl 	= vTmpArr.every(vItems => (parseInt(vItems.nilai_kasir)||0) === 0);

				if(!vAllZeroBl) {
					const vLabelXArr	= vTmpArr.map(vItems=>vItems.nama_kasir);
					const vBgColorArr	= vTmpArr.map(vItems=>UFunc.getRandomColor());
					const vValueArr		= vTmpArr.map(vItems=>(parseInt(vItems.nilai_kasir)||0));
					uDatamainArr[vArridx].data_obj = {
						labels: vLabelXArr,
						bgColor: vBgColorArr,
						value: vValueArr,
					}

					const vTglfilterDT 	= UFunc.toDate(vTglfilter);
					const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
					const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				} else {
					uDatamainArr[vArridx].data_obj = {
						labels: ["No Data"],
						bgColor: ["#DDDDDD"],
						value: [1],
					}
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadpiekasir) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2)
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				onResolve(
					vFunctionNext(vDataArr[vDataidx].output_string || {})
				)
				return;
			}
			//console.log("(Dashboard - apiLoadpiekasir) vArridx : "+(vArridx));

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_kasirpie";
			const vHeaderObj= _HEADEROBJ || {};
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) return response.json(); }
				).then((output_string) => {
					//console.log("(Dashboard - apiLoadpiekasir) output_string : "+JSON.stringify(output_string));
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
		})//-*/
	}
	const apiLoadGrafikpasien = (_HEADEROBJ) => {
		const vNamavars	= "grafik_pasien";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vTglfilter 		= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			//console.log("(Dashboard - apiLoadGrafikpasien) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadGrafikpasien) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vAllZeroBl 	= vTmpArr.every(vItems => (parseInt(vItems.jml_pasien)||0) === 0);
				
				const vTmpTotals 	= vTmpArr.reduce((vSUM,vItems)=>vSUM + (parseInt(vItems.jml_pasien)||0),0);
				const vTmpRata2 	= Math.round(vTmpTotals / (parseInt(vTmpArr.length)||1));
				
				const vLabelXArr	= vTmpArr.map(vItems=> vItems.tanggal );
				const vTmpValueArr	= vTmpArr.map(vItems=> (parseInt(vItems.jml_pasien)||0));
				const vTmpRataArr	= vTmpArr.map(vItems=> vTmpRata2);

				const vDatatmpObj	= {
					label_arr: vLabelXArr || [],
					value_arr: vTmpValueArr || [],
					rata_arr: vTmpRataArr || [],
					totals: vTmpTotals,
				}
				const vTimeout	= setTimeout(()=>{
					clearTimeout(vTimeout);

					uDatamainArr[vArridx].data_obj = vDatatmpObj;
					setEfekview2(!uEfekview2);
				},200);
				
				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}//-*/
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadGrafikpasien) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				onResolve(
					vFunctionNext(vDataArr[vDataidx].output_string || {})
				)
				return;
			}
			//console.log("(Dashboard - apiLoadGrafikpasien) vArridx : "+(vArridx));

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_pasien";
			const vHeaderObj= _HEADEROBJ || {};
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) return response.json(); }
				).then((output_string) => {
					//console.log("(Dashboard - apiLoadGrafikpasien) output_string : "+JSON.stringify(output_string));
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
		})//-*/
	}

	const apiLoaddata = () => {
		setHTMLError500();//return;
	    initRemoveexpired();

		const vTglfilter 	= (uKeywordObj.filter_dashboard || UFunc.DbDate());
		const vDATAS    	= JSON.stringify({
			send_tglfilter: vTglfilter,
			send_tokenauth : uTokenObj.userinit
		});
		const vHeaderObj	= {
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}

		const vElperiode = document.getElementById("inptglfilter");
		vElperiode && (vElperiode.disabled = true)
		setSessionaktif(false);
		setLoading(true);
		console.log("(Dashboard - apiLoaddata) => Start LEWAT 1");
		const vTimeBegin = new Date();

		const vFuncProcess 	= [];
		vFuncProcess.push((() => { return apiLoadongoing(vHeaderObj); })());

		if(uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") {
			vFuncProcess.push((() => { return apiLoadonline(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadnote(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadlayanan(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadGrafikomzet(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadPieomzet(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadhutang(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadpiutang(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadbiaya(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadkas(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadpiebarang(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadpieitems(vHeaderObj); })());
		} else if(uTokenObj.userhak==="PARAMEDIS" || uTokenObj.userhak==="DOKTER") {
			vFuncProcess.push((() => { return apiLoadnote(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadlayanan(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadreminder(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadGrafikpasien(vHeaderObj); })());
		} else {
			vFuncProcess.push((() => { return apiLoadnote(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadreminder(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadpiekasir(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadGrafikpasien(vHeaderObj); })());
		}

		Promise.all(vFuncProcess).then(() => {
			const vTimeEnd = new Date();
			const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			if(vTimeDiff <= 1000) {
				let vTimeout = setTimeout(()=>{
					clearTimeout(vTimeout);
					setSessionaktif(true);
					console.log("(Dashboard - apiLoaddata) setSessionaktif : "+UFunc.JamMenitDetik());
				},1000);
			} else setSessionaktif(true);

			setLoading(false);
			vElperiode && (vElperiode.disabled = false)

		    //--GET_VALUE_TIMESTAMP--/
			const vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
			const vTimestampTS		= vDatadashboardObj.timestampTS || (new Date()).valueOf();
		    //--END GET_VALUE_TIMESTAMP--/

			vDatadashboardObj.timestampTS 	= vTimestampTS;
			localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));

			console.log("(Dashboard - apiLoaddata) => then ["+vTimeDiff+"ms] LEWAT 2")
		}).catch((vErrorlogs)=>{
			uDatamainArr.map(vItems => vItems.is_loading = undefined);
			setLoading(false);

			console.log("(Dashboard - apiLoaddata) => catch LEWAT 3a => "+vErrorlogs)
			vElperiode && (vElperiode.disabled = false)
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	//---END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		const vTanggalNowDT = new Date();
		const vTgl1NowDT 	= new Date(vTanggalNowDT.getFullYear(),vTanggalNowDT.getMonth(),1);
		uKeywordObj.filter_dashboard = (UFunc.DbDate(vTgl1NowDT)); 
	    localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));

		apiLoaddata();
		
		return () => {
			setDatamainArr([]);

			//---RESET_KEYWORD--/
			uKeywordObj.filter_dashboard = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
			//---END RESET_KEYWORD--/
		}
	},[])
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{
		const vElperiode	= document.getElementById("inptglfilter");

		if(uHTMLError500) vElperiode && (vElperiode.disabled=true);
		else vElperiode && (vElperiode.disabled=false); 
	},[uHTMLError500]);
	
	//console.log("Dashboard - uActiveroute => "+(uActiveroute));

	//if((uTokenObj.userinit||"") === "") {
	if(!uTokenObj) { setLoading(true); prosesExpired(); return ""; }
	if(uHTMLError500) return ( <MyPage500 content={uHTMLError500} isWithrefresh={true} hdlReset={()=>apiLoaddata()}/> )//>

	if(uTokenObj.userhak === "FREEROLE" || uTokenObj.userhak === "ADMIN") return (
		<>
		<CRow className="align-items-stretch">
			<CCol lg="3" md="6" xs="6" className="mb-3">{contentWidgetOngoing()}</CCol>

			<CCol lg="3" md="6" xs="6" className="mb-3">{contentWidgetOnline()}</CCol>
			
			<CCol lg="3" md="6" className="mb-3">{contentWidgetNote()}</CCol>
			
			<CCol lg="3" md="6" className="mb-3">{contentPielayanan()}</CCol>
		</CRow>

		<CCard className="classcardbg mb-3">
			<CCardHeader className="d-flex justify-content-between">
			<small className="fw-bolder">Grafik Omzet</small>
			<Link className="classcursorpointer text-decoration-none text-muted classfontsmaller"
				to="/sublapoperasi/lapomzet">
				<small>Lihat Detil<CIcon icon="cilArrowRight" className="align-bottom"/></small>
			</Link>
			</CCardHeader>
		
			<CCardBody>
			<CRow className="align-items-center">
			<CCol md="7" lg="8">{contentGrafikomzet()}</CCol>
			<CCol md="5" lg="4">{contentWidgetOmzetbulan()} {contentPieOmzetAkuntansi()}</CCol>
			</CRow>
			</CCardBody>
		</CCard>

		<CRow className="align-items-stretch">
			<CCol lg="4" md="6" className="mb-3">{contentCardHutang()}</CCol>

			<CCol lg="4" md="6" className="mb-3">{contentCardPiutang()}</CCol>

			<CCol lg="4" md="6" className="mb-3">{contentCardBiaya()}</CCol>

			<CCol lg="4" md="6" className="mb-3">{contentCardKas()}</CCol>

			<CCol lg="4" md="6" className="mb-3">{contentPieBarang()}</CCol>

			<CCol lg="4" md="6" className="mb-3">{contentPieItems()}</CCol>
		</CRow>
		</>
	)//->

	if(uTokenObj.userhak === "DOKTER" || uTokenObj.userhak === "PARAMEDIS") return (
		<>
		<CRow className="align-items-stretch">
			<CCol lg="3" md="6" xs="6" className="mb-3">{contentWidgetOngoing()}</CCol>

			<CCol lg="3" md="6" xs="6" className="mb-3">{contentWidgetReminder()}</CCol>
			
			<CCol lg="3" md="6" className="mb-3">{contentWidgetNote()}</CCol>
			
			<CCol lg="3" md="6" className="mb-3">{contentPielayanan()}</CCol>
		</CRow>

		<CCard className="classcardbg mb-3">
			<CCardHeader className="d-flex justify-content-between">
			<small className="fw-bolder">Grafik Jml Pasien</small>
			<Link className="classcursorpointer text-decoration-none text-muted classfontsmaller"
				to="#">
				<small>Lihat Detil<CIcon icon="cilArrowRight" className="align-bottom"/></small>
			</Link>
			</CCardHeader>
		
			<CCardBody>
			<CRow>
			<CCol md="7" lg="8">{contentGrafikpasien()}</CCol>
			<CCol md="5" lg="4">{contentWidgetpasienharian()} {contentWidgetpasienmost()}</CCol>
			</CRow>
			</CCardBody>
		</CCard>
		</>
	)//>

	return (
		<>
		<CRow className="align-items-stretch">
			<CCol lg="3" md="6" xs="6" className="mb-3">{contentWidgetOngoing()}</CCol>

			<CCol lg="3" md="6" xs="6" className="mb-3">{contentWidgetReminder()}</CCol>
			
			<CCol lg="3" md="6" className="mb-3">{contentWidgetNote()}</CCol>
			
			<CCol lg="3" md="6" className="mb-3">{contentPieKasir()}</CCol>
		</CRow>

		<CCard className="classcardbg mb-3">
			<CCardHeader className="d-flex justify-content-between">
			<small className="fw-bolder">Grafik Jml Pasien</small>
			<Link className="classcursorpointer text-decoration-none text-muted classfontsmaller"
				to="/copynota">
				<small>Lihat Detil<CIcon icon="cilArrowRight" className="align-bottom"/></small>
			</Link>
			</CCardHeader>
		
			<CCardBody>
			<CRow>
			<CCol md="7" lg="8">{contentGrafikpasien()}</CCol>
			<CCol md="5" lg="4">{contentWidgetpasienharian()} {contentWidgetpasienmost()}</CCol>
			</CRow>
			</CCardBody>
		</CCard>
		</>
	)//>
}	

export default Dashboard